import React from "react";
import { ReactComponent as ProductsIcon } from "./assets/icons/products.svg";
import { ReactComponent as DashboardIcon } from "./assets/icons/dashboard.svg";
import { ReactComponent as CampaignManagerIcon } from "./assets/icons/campaign-manager.svg";
import { ReactComponent as HelpIcon } from "./assets/icons/help.svg";
import { ReactComponent as MineralsIcon } from "./assets/icons/minerals.svg";
import { ReactComponent as SupplierIcon } from "./assets/icons/supplier.svg";
import { ReactComponent as SettingsIcon } from "./assets/icons/settings.svg";
import { ReactComponent as RecycleBinIcon } from "./assets/icons/recycle-bin.svg";
import { ReactComponent as RegulationIcon } from "./assets/icons/regulation.svg";
import { ReactComponent as ComponentsIcon } from "./assets/icons/components.svg";
import { ReactComponent as ImportDataIcon } from "./assets/icons/import-data.svg";
import { ReactComponent as LogoutIcon } from "./assets/icons/logout-icon.svg";
import { ReactComponent as ProfileIcon } from "./assets/icons/profile-icon.svg";
import { ReactComponent as PlusCircleIcon } from "./assets/icons/plus-circle.svg";
import { ReactComponent as ProductLevelIcon } from "./assets/icons/product-level-icon.svg";
import { ReactComponent as CloseCircledIcon } from "./assets/icons/close-circle-outlined.svg";
import { ReactComponent as RightChevronIcon } from "./assets/icons/right-chevron.svg";
import { ReactComponent as BackArrowIcon } from "./assets/icons/back-arrow.svg";
import { ReactComponent as ScipIcon } from "./assets/icons/scip.svg";

interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: string;
  className?: string;
}

const icons: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  products: ProductsIcon,
  dashboard: DashboardIcon,
  "campaign-manager": CampaignManagerIcon,
  help: HelpIcon,
  minerals: MineralsIcon,
  supplier: SupplierIcon,
  settings: SettingsIcon,
  "recycle-bin": RecycleBinIcon,
  regulation: RegulationIcon,
  components: ComponentsIcon,
  "import-data": ImportDataIcon,
  "logout-icon": LogoutIcon,
  "profile-icon": ProfileIcon,
  "plus-circle": PlusCircleIcon,
  "product-level-icon": ProductLevelIcon,
  "close-circle-outlined": CloseCircledIcon,
  "right-chevron": RightChevronIcon,
  "back-arrow": BackArrowIcon,
  scip: ScipIcon,
};

const Icon: React.FC<IconProps> = ({ name, className = "", ...props }) => {
  const SvgIcon = icons[name];

  if (!SvgIcon) return null; // If the icon is not found, return null

  return <SvgIcon className={`icon icon-${name} ${className}`} {...props} />;
};

export default Icon;
