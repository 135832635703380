import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Descriptions,
  Tabs,
  Flex,
  Card,
  Avatar,
  Dropdown,
  Button,
  Select,
} from "antd";
import type { DescriptionsProps, TabsProps } from "antd";

import TenantOverview from "./Overview";
import TenantSubscription from "./Subscription";
import TenantRegulation from "./Regulation";
import TenantConfiguration from "./Configuration";
import TenantUsers from "./Users";

import { Tenant } from "../../../../utils/types/tenant";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  listTenantRegulations,
  readTenant,
  updateTenant,
} from "../../../../redux/actions/tenantsAction";
import TenantForm from "../../../../components/forms/TenantForm";
import ErrorBoundary from "../../../../utils/errorBoundary";
import { listRegulations } from "../../../../redux/actions/regulationsAction";
import { Regulation } from "../../../../utils/types/regulation";

const { Option } = Select;

const { Meta } = Card;

const TenantDetails: React.FC = () => {
  const { tenantId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [tenant, setTenant] = useState<Tenant>();
  const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);
  const [descriptionItems, setDescriptionItems] = useState<
    DescriptionsProps["items"]
  >([]);
  const [loading, setLoading] = useState(false);
  const [regulationOptions, setRegulationOptions] = useState<any[]>([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [tenantRegulations, setTenantRegulations] = useState<Regulation[]>([]);
  const [tenantRegulationLoading, setTenantRegulationLoading] = useState(false);
  const [regulationLoading, setRegulationLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const handleSelectRegulations = (value: any, option: any) => {
    setSelectedOptions(option);
  };

  const fetchData = (tenantId?: string, payload?: any) => {
    setLoading(true);
    dispatch(readTenant(tenantId ? tenantId : tenant ? tenant._id : ""))
      .then((response: Tenant) => {
        setTenant(response);
        setDescriptionItems([
          {
            key: "1",
            label: "Company Name",
            children: response.companyName,
          },
          {
            key: "2",
            label: "POC Name",
            children: response.firstName + " " + response.lastName,
          },
          {
            key: "3",
            label: "Designation",
            children: response.designation,
          },
          {
            key: "4",
            label: "Email Address",
            children: response.emailAddress,
          },
          {
            key: "5",
            label: "Phone Number",
            children: response.phoneNumber,
          },
          {
            key: "6",
            label: "Subscription",
            children: response.subscription.name,
          },
          {
            key: "7",
            label: "Regulations",
            children: response.regulations.map((e) => e.name).join(", "),
          },
          {
            key: "8",
            label: "Address",
            children: (
              <>
                {response.addressLine1} <br />
                {response.addressLine2} <br />
                {response.country} {response.state} {response.pincode}
              </>
            ),
          },
        ]);
        setTabItems([
          {
            key: "",
            label: "Overview",
            children: (
              <TenantOverview id={response._id} number={response.number} />
            ),
          },
          {
            key: "subscription",
            label: "Subscription",
            children: (
              <TenantSubscription
                id={response._id}
                tenantNumber={response.number}
                subscription={response.subscription}
                companyLogo={response.companyLogo}
                primaryColor={response.primaryColor}
                secondaryColor={response.secondaryColor}
                letterOfAuthorisation={response.letterOfAuthorisation}
                authorisedSender={response.authorisedSender}
                actAiConfigurations={response.actAiConfigurations}
                scipConfigurations={response.scipConfigurations}
                subscriptionStartDate={response.subscriptionStartDate}
                renewalPeriod={response.renewalPeriod}
                subscriptionEndDate={response.subscriptionEndDate}
              />
            ),
          },
          {
            key: "regulations",
            label: "Regulations",
            disabled: response.subscription.modules.find(
              (e) => e.number === "regulations"
            )
              ? false
              : true,
            children: (
              <TenantRegulation
                id={response._id}
                tenantNumber={response.number}
              />
            ),
          },
          {
            key: "configuration",
            label: "Configuration",
            disabled: response.subscription.modules.find(
              (e) => e.number === "products" || e.number === "components"
            )
              ? false
              : true,
            children: (
              <TenantConfiguration
                id={response._id}
                tenantNumber={response.number}
                productSpecifications={response.productSpecifications}
                specifications={response.specifications}
                gridConfiguration={response.gridConfiguration}
              />
            ),
          },
          {
            key: "users",
            label: "Users",
            children: (
              <TenantUsers id={response._id} tenantNumber={response.number} />
            ),
          },
        ]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchRegulations = (value: string) => {
    setRegulationLoading(true);
    const values = value.split(", ");
    let filters = {
      name: { $regex: value, $options: "i" },
    };
    if (values.length > 1) {
      filters = {
        name: { $in: values } as {
          $in: string[];
          $regex: string;
          $options: string;
        },
      };
    }
    dispatch(
      listRegulations({
        pageSize: 0,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify(filters),
      })
    ).then((response: any) => {
      const regulations = response.regulations?.filter(
        (e: any) =>
          !tenantRegulations
            ?.map((e: any) => e.regulationNumber)
            .includes(e.regulationNumber)
      );
      setRegulationOptions(
        regulations.map((regulation: Regulation) => ({
          label: regulation.name,
          value: regulation.regulationNumber,
          default: regulation,
        }))
      );
    });
    setRegulationLoading(false);
  };

  const fetchTenantRegulations = (payload?: any) => {
    setTenantRegulationLoading(true);
    dispatch(
      listTenantRegulations(tenantId as string, {
        pageSize: 0,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: true,
        filters: payload ? payload : null,
      })
    )
      .then((response) => {
        setTenantRegulations(response.regulations);
        setTenantRegulationLoading(false);
      })
      .catch(() => {
        setTenantRegulationLoading(false);
      });
  };

  const handleTabChange = (key: string) => {
    navigate(key);
  };

  useEffect(() => {
    if (tenantId) {
      fetchData(tenantId);
    }
    if (location.pathname.split("/").pop() === "regulations") {
      fetchTenantRegulations("");
    }
  }, [tenantId, location]);

  useEffect(() => {
    if (
      location.pathname.split("/").pop() === "regulations" &&
      tenantRegulations
    ) {
      fetchRegulations("");
    }
  }, [tenantRegulations]);

  const handleRegulationSubmit = () => {
    setLoading(true);
    dispatch(
      updateTenant(
        {
          currentLocationOrigin: window.location.origin,
          updates: {
            regulations: [
              ...selectedOptions.map((e: any) => e.default),
              ...tenantRegulations,
            ],
          },
        },
        tenantId as string
      )
    ).then(() => {
      window.location.reload();
      setIsDropdownVisible(false);
      setSelectedOptions([]);
      setLoading(false);
    });
  };

  const handleButtonClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <>
      <ErrorBoundary>
        <Card style={{ marginTop: "2rem" }} loading={loading}>
          <Meta
            avatar={
              <Avatar
                shape="square"
                size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
              >
                {tenant?.companyName
                  ? tenant?.companyName.slice(0, 2).toUpperCase()
                  : "NA"}
              </Avatar>
            }
            title={
              <>
                {tenant?.number.toUpperCase()}{" "}
                <TenantForm
                  type="update"
                  fetchData={fetchData}
                  formData={tenant}
                />
              </>
            }
            description={<Descriptions size="small" items={descriptionItems} />}
          />
        </Card>

        <Tabs
          activeKey={
            location.pathname.split("/").pop() === tenantId
              ? ""
              : location.pathname.split("/").pop()
          }
          items={tabItems}
          onChange={handleTabChange}
          tabBarExtraContent={
            location.pathname.split("/").pop() === "regulations" && (
              <Dropdown
                dropdownRender={() => (
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "white",
                      borderRadius: "4px",
                      width: "250px",
                    }}
                  >
                    <Select
                      options={regulationOptions}
                      onSearch={(text) => fetchRegulations(text)}
                      style={{ width: "100%", marginBottom: "10px" }}
                      placeholder="Please select a regulation"
                      mode="multiple"
                      allowClear
                      onChange={handleSelectRegulations}
                    />
                    <Button
                      type="primary"
                      block
                      onClick={handleRegulationSubmit}
                    >
                      Save
                    </Button>
                  </div>
                )}
              >
                <Button type="primary" onClick={handleButtonClick}>
                  Add Regulations
                </Button>
              </Dropdown>
            )
          }
        />
      </ErrorBoundary>
    </>
  );
};

export default TenantDetails;
