import React, { useState } from "react";
import { Button, Modal, Form, Row, Col } from "antd";
import FileUploader from "../../layouts/FileUploader";
import { getLocalStorage } from "../../../utils/localStore";
import { PaperClipOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../../redux/hooks";
import { createScipArticle } from "../../../redux/actions/scipAction";

const ScipSupportingDocument: React.FC<{
  fetchData: any;
  formData: any;
}> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { tenantNumber } = getLocalStorage("user");
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    dispatch(
      createScipArticle({
        componentId: props.formData?.componentId
          ? props.formData?.componentId
          : props.formData?._id,
        type: "components",
        Status: props.formData?.Status,
        ...values,
      })
    ).then(() => {
      props.fetchData();
      form.resetFields();
      setLoading(false);
      setIsModalOpen(false);
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="text"
        onClick={showModal}
        icon={<PaperClipOutlined />}
        title="SCIP Document"
      />
      <Modal
        title="Attach Supporting document (if any)"
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        maskClosable={false}
        footer={[
          <Button onClick={handleCancel} key="cancel">
            Cancel
          </Button>,
          <Button
            onClick={form.submit}
            type="primary"
            loading={loading}
            key="add"
            disabled={isUploading}
          >
            Add
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="scipDocument"
                label="SCIP Document"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "Please choose a file",
                  },
                ]}
              >
                <FileUploader
                  postUrl="scip/document/upload"
                  tenantNumber={tenantNumber}
                  setFormField={(value: string) =>
                    form.setFieldValue("scipDocument", value)
                  }
                  onUploadStatusChange={setIsUploading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ScipSupportingDocument;
