import React, { useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import type { MenuProps } from "antd";
import { DownloadOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import {
  readDownloadExport,
  readDownloadStatus,
  readDownloadResult,
  readDownloadPdf,
} from "../../../redux/actions/scipAction";

const { Text, Title } = Typography;
import { useAppDispatch } from "../../../redux/hooks";

const PreviewScip: React.FC<{
  type: string;
  formData: any;
}> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pdfRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleEdit = (productId: string) => {
    navigate(`${productId}`);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items: MenuProps["items"] = [
    // {
    //   label: "Create PDF",
    //   key: "pdf",
    // },
    {
      label: "Export to i6z",
      key: "i6z",
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "pdf") {
      setLoading(true);
      dispatch(readDownloadPdf({ uuid: props.formData?.DossierUuid })).then(
        (response) => {
          const a = document.createElement("a");
          document.body.appendChild(a);
          const blob = new Blob([response], {
              type: "application/pdf",
            }),
            url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = props.formData.uuid;
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
          setLoading(false);
          setIsModalOpen(false);
        }
      );
    } else {
      setLoading(true);
      dispatch(readDownloadExport({ uuid: props.formData?.DossierUuid })).then(
        (response) => {
          const downloadTimer = setInterval(() => {
            dispatch(
              readDownloadStatus({
                uri: response.uri,
              })
            ).then((response) => {
              if (response.status === "SUCCEEDED") {
                dispatch(
                  readDownloadResult({
                    uri: response.id,
                  })
                ).then((response) => {
                  const a = document.createElement("a");
                  document.body.appendChild(a);
                  const blob = new Blob([response], {
                      type: "application/octet-stream",
                    }),
                    url = window.URL.createObjectURL(blob);
                  a.href = url;
                  a.download = props.formData.DossierUuid + ".i6z";
                  a.click();
                  document.body.removeChild(a);
                  window.URL.revokeObjectURL(url);
                  setLoading(false);
                  setIsModalOpen(false);
                });
                clearInterval(downloadTimer);
              }
            });
          }, 2000);
        }
      );
    }
  };

  return (
    <>
      <Button type="text" onClick={showModal} icon={<EyeOutlined />}></Button>
      <Modal
        title="Declaration"
        open={isModalOpen}
        style={{ top: 20 }}
        width={720}
        maskClosable={false}
        onCancel={handleCancel}
        footer={[
          <Button
            key="Edit"
            onClick={() => handleEdit(props.formData?.productId)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>,
          <Dropdown
            key="download"
            menu={{ items, onClick }}
            placement="topRight"
            arrow
          >
            <a onClick={(e) => e.preventDefault()}>
              <Button
                type="primary"
                loading={loading}
                icon={<DownloadOutlined />}
                style={{ marginLeft: "1rem" }}
              >
                Download
              </Button>
            </a>
          </Dropdown>,
        ]}
      >
        <Card
          bordered={false}
          style={{ overflow: "auto", height: "80vh" }}
          styles={{
            body: {
              padding: 0,
            },
          }}
        >
          <Row key={props.type}>
            <Col ref={pdfRef}>
              <Title level={5} style={{ color: "#045B7C" }}>
                Article Name: {props?.formData?.ArticleName}
                <br></br>
                {props?.formData?.ScipUuid &&
                  "SCIP UUID : " + props?.formData?.ScipUuid}
              </Title>
              <Divider />
              {/* <Card title={`Article Name ${props?.formData?.ArticleName}`}> */}
              <Text>UUID: {props?.formData?.DossierUuid || "NA"}</Text>
              <br></br>
              <Text>Author: Acquis Compliance</Text>
              <br></br>
              <Text>
                Date:
                {new Date(props?.formData?.createdAt).toLocaleString(
                  undefined,
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  }
                )}
              </Text>
              <Divider />
              <Title level={5}>Identifiers</Title>
              <Text> Article Name : {props?.formData?.ArticleName}</Text>
              <br></br>
              <Text>
                Primary ArticleIdentifier (Type ):{" "}
                {props?.formData?.PrimaryArticleIdentifierType?.text}
              </Text>
              <br></br>
              <Text>
                Primary ArticleIdentifier (Value) :{" "}
                {props?.formData?.PrimaryArticleIdentifierValue}
              </Text>
              <br></br>
              <Divider />
              {props?.formData?.PrimaryArticleIdentifierType.code !==
                "66297" && (
                <div>
                  <Title level={5}> Categorisation </Title>
                  <Text>
                    Article category :{" "}
                    {props?.formData?.ArticleCategory?.map(
                      (e: any) => e?.text
                    )?.toString()}
                  </Text>
                  <br></br>
                  <Text>
                    Production in European Union :{" "}
                    {props?.formData?.EUProductionFlag?.text}
                  </Text>
                  <br></br>
                  <Divider />
                  <Title level={5}> Safe Use Instructions </Title>
                  <Text>
                    The identification of the Candidate List substance is
                    sufficient to allow safe use of the article throughout the
                    whole life cycle including service life, disassembly and
                    waste/recycling stage :
                    {props?.formData?.NoSafeUseInstructions?.toString()}
                  </Text>
                  {props?.formData?.SafeUseInstructions?.length > 0 && (
                    <Text>{props?.formData?.SafeUseInstructions}</Text>
                  )}
                  <Divider />
                  {props.formData?.Characteristics !== undefined &&
                    Object.keys(props.formData?.Characteristics)?.length >
                      0 && (
                      <>
                        <Title level={5}> Characteristics</Title>
                        {Object.entries(props.formData?.Characteristics).map(
                          ([key, value]) => (
                            <div key={key}>
                              <Text>
                                {key}:
                                {typeof value === "object" && value !== null
                                  ? "text" in value
                                    ? (value as { text: string })?.text
                                    : JSON.stringify(value)
                                  : value?.toString()}
                              </Text>
                            </div>
                          )
                        )}
                        <Divider />
                      </>
                    )}
                  {props.type === "components" && (
                    <Title level={5}> Concern Elements </Title>
                  )}
                  {props.type === "components" &&
                    props?.formData?.ConcernElements?.length &&
                    props?.formData?.ConcernElements?.map((e: any, i: any) => (
                      <>
                        <Col span={24} key={i + 1}>
                          <Text>
                            Candidate list Substance :
                            {e.CandidateListSubstanceLink?.name}
                          </Text>
                          <Text>
                            Concentration Range :{e.ConcentrationRange?.text}
                          </Text>
                          <Text>
                            Material categories :
                            {e.MaterialCategories?.map(
                              (e: any) => e?.text
                            )?.toString()}
                          </Text>
                          <Text>
                            Mixture Category :
                            {e.MixtureCategoryEUPCS?.map(
                              (e: any) => e?.description
                            )?.toString()}
                          </Text>
                          <Divider />
                        </Col>
                      </>
                    ))}
                  {props.type === "products" && (
                    <Title level={5}>Complex object component(s)</Title>
                  )}
                  {props.type === "products" &&
                    props?.formData?.ComplexObjectComponents?.length &&
                    props?.formData?.ComplexObjectComponents?.map(
                      (e: any, i: any) => (
                        <>
                          <Col span={24} key={i + 1}>
                            <Text>
                              Article Name : {e.ArticleLink?.ArticleName}
                            </Text>
                            <br></br>
                            <Text>
                              Number of units :{" "}
                              {e.NumberOfUnits.lowerBoundQualifiers}
                              {e.NumberOfUnits.lowerBoundValue}
                              {e.NumberOfUnits.upperBoundQualifiers}
                              {e.NumberOfUnits.upperBoundValue}
                            </Text>
                            <Divider />
                          </Col>
                        </>
                      )
                    )}
                </div>
              )}
              {/* </Card> */}
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );
};

export default PreviewScip;
