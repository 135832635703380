import React, { useState } from "react";
import { LinkOutlined } from "@ant-design/icons";
import { Button, Row, Col, Drawer, Select, Space } from "antd";
import FileUploader from "../layouts/FileUploader";
import { getLocalStorage } from "../../utils/localStore";

const { Option } = Select;

const SupportingDocumentsForm: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { tenantNumber } = getLocalStorage("user");
  const [folder, setFolder] = useState<string>();
  const [isUploading, setIsUploading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="default"
        title="Supporting Documents"
        onClick={showDrawer}
        icon={<LinkOutlined />}
      >
        Upload Files
      </Button>
      <Drawer
        title="Upload Supporting Documents"
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: { paddingBottom: 80 },
        }}
        extra={
          <Space>
            <Button onClick={onClose} disabled={isUploading}>Close</Button>
          </Space>
        }
      >
        <Row gutter={[16, 16]} align="middle">
          <Col span={24}>
            <label style={{ display: "block", marginBottom: "0.5rem" }}>
              <span className="text__red">*</span> Select Module
            </label>
            <Select
              allowClear
              style={{ width: "100%" }}
              placeholder="Please select a module"
              onChange={(value) => {
                setFolder(value);
              }}
            >
              <Option value="compliances">Compliances Documents</Option>
              <Option value="materials">
                Full Material Disclosure Documents
              </Option>
            </Select>
          </Col>
          <Col span={24}>
            <FileUploader
              postUrl={folder + "/document/upload"}
              tenantNumber={tenantNumber}
              disabled={!folder}
              maxCount={100}
              onUploadStatusChange={setIsUploading}
            />
          </Col>
        </Row>
      </Drawer>
    </>
  );
};

export default SupportingDocumentsForm;
