import React, { useState, useRef } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Select,
  Space,
  Button,
  Drawer,
  InputNumber,
  message,
  TourProps,
  Tour
} from "antd";

import { Component } from "../../utils/types/component";
import { useAppDispatch } from "../../redux/hooks";
import { updateSupplierCompliances } from "../../redux/actions/supplierPortalAction";
import FileUploader from "../layouts/FileUploader";
import { getLocalStorage } from "../../utils/localStore";
import Compliance1 from "../../assets/supplier portal/material/fmd1.png";
import Compliance2 from "../../assets/supplier portal/material/fmd2.png";
import Compliance3 from "../../assets/supplier portal/material/fmd3.png";
import Compliance4 from "../../assets/supplier portal/material/fmd4.png";
import Compliance5 from "../../assets/supplier portal/material/fmd5.png";

const MaterialForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Component;
  campaignId?: any;
  show?: any;
  setShow?: any;
  showTourGuide?: boolean;
  inSupplierPortal?: boolean;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { tenantNumber } = getLocalStorage("user");
  const [isUploading, setIsUploading] = useState(false);

  const editButtonRef = useRef(null);
  const partWeightRef = useRef(null);
  const uploadRef = useRef(null);
  const submitRef = useRef(null);
  const steps: TourProps["steps"] = [
    {
      title: "Step 1: Select a Part",
      description: (
        <p>
          Choose a component to submit Material Disclosure (FMD) data for by
          clicking the{" "}
          <b>
            <i>Edit</i>
          </b>{" "}
          icon next to it.
        </p>
      ),
      cover: <img alt="" src={Compliance1} />,
      target: () => editButtonRef.current,
      placement: "right",
      nextButtonProps: { children: "Next", onClick: () => setOpen(true) },
    },
    {
      title: "Step 2: Enter Part Weight Details",
      description: (
        <>
          <p>In the <b>Edit Component</b> sidebar:</p>
          <ul>
            <li>
              Enter the <b>Part Weight</b> and select the <b>Part Weight Unit</b> from the dropdown
            </li>
          </ul>
        </>
      ),
      cover: <img alt="" src={Compliance2} />,
      placement: "center",
      prevButtonProps: { children: "Previous", onClick: () => setOpen(false) },
    },
    {
      title: "Step 3: Upload FMD Document",
      description: (
        <p>
          Click <b><i>‘Click to Upload’</i></b> button to attach the Full Material Disclosure (FMD) document. Preferably in IPC 1752A Class D format.
        </p>
      ),
      cover: <img alt="" src={Compliance3} />,
      target: () => uploadRef.current,
      placement: "left",
    },
    {
      title: "Step 4: Submit FMD",
      description: (
        <p>
          Click <b><i>‘Submit’</i></b> at the top right of the sidebar to save and submit the FMD data for the selected component.
        </p>
      ),
      cover: <img alt="" src={Compliance4} />,
      target: () => submitRef.current,
      placement: "bottomRight",
      nextButtonProps: { children: "Next", onClick: () => setOpen(false) },
    },
    {
      title: "Step 5: Important: Get Help If Needed",
      description: (
        <>
          <p>
            Double-check your upload for completeness. If you need assistance or
            have questions:
          </p>
          <ul>
            <li>
              <b>Compliance/Part Queries:</b> Click Contact Support
            </li>
            <li>
              <b>Technical Issues/Feedback:</b> Click Technical Support
            </li>
          </ul>
          <p>Our support team is here to help you via phone or email.</p>
        </>
      ),
      cover: <img alt="" src={Compliance5} />,
      placement: "center",
    },
  ];

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFormSubmit = (values: Component) => {
    setLoading(true);
    const isFormEmpty = Object.values(values).every(
      (field) => field === undefined || field === ""
    );
    if (isFormEmpty) {
      message.error("Please fill at least one field!");
      setLoading(false);
      return;
    }
    if (props.type === "create") {
      //
    } else if (props.formData) {
      dispatch(
        updateSupplierCompliances({
          updates: {
            ...values,
          },
          componentId: [props.formData._id],
          campaignId: props.campaignId,
          dates: getLocalStorage("expireAt"),
          tenantNumber: tenantNumber,
          updateType: "material disclosure",
        })
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
          onClose();
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <div>
      {props.showTourGuide && <Tour
        open={props.show}
        onClose={() => props.setShow(false)}
        steps={steps}
        disabledInteraction={true}
      />}
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
        ref={editButtonRef}
      >
        {props.type === "create" ? "Add Component" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Component" : "Edit Component"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              ref={submitRef}
              disabled={isUploading}
            >
              Submit
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.formData }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={16}>
            <Col span={12} sm={12} md={12} ref={partWeightRef}>
              <Form.Item
                name="partWeight"
                label="Part Weight"
                rules={[
                  {
                    required: props.inSupplierPortal ? false : true,
                    message: "Please input part weight!",
                  },
                  {
                    pattern: /^([0-9.])+$/,
                    message: "Please enter only numeric characters.",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Part Weight"
                  style={{ width: 324 }}
                  min={0}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="partWeightUnit"
                label="Part Weight Unit"
                rules={[
                  {
                    required: props.inSupplierPortal ? false : true,
                    whitespace: true,
                    message: "Please input part weight unit!",
                  },
                ]}
              >
                <Select
                  options={[
                    { label: "kg", value: "kg" },
                    { label: "g", value: "g" },
                    { label: "mg", value: "mg" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="fmdDocument" label="FMD Document" rules={[]}>
                <span ref={uploadRef}>
                <FileUploader
                  postUrl="public/suppliers/document/upload"
                  tenantNumber={tenantNumber}
                  additionalData={{
                    campaignId: props.campaignId,
                    foldername: "campaign",
                    subfolder: "materials",
                  }}
                  setFormField={(value: string) =>
                    form.setFieldValue("fmdDocument", value)
                  }
                  formField={props.formData?.fmdDocument}
                  onUploadStatusChange={setIsUploading}
                />
                </span>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </div>
  );
};

export default MaterialForm;
