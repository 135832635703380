import React from "react";
import { Button, Col, Image, Row, Typography } from "antd";
import vector from "../../assets/vector.png";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Outlet, useNavigate } from "react-router-dom";
import UploadXML from "./xmlUpload";

const { Title, Paragraph } = Typography;

const IPC1752EditorHome: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        height: "100vh ",
        alignItems: "center",
        paddingTop: "70px",
      }}
    >
      <Title
        level={2}
        style={{
          color: "#F7863E",
          margin: 0,
          justifyContent: "center",
          display: "flex",
        }}
      >
        ACQUIS COMPLIANCE
      </Title>
      <Title
        level={2}
        style={{
          color: "#00516E",
          margin: 0,
          justifyContent: "center",
          display: "flex",
        }}
      >
        Material Declaration Tool
      </Title>

      <Row style={{ marginTop: "3rem" }}>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              paddingLeft: "100px",
            }}
          >
            <Image src={vector} alt="vector" width={600} preview={false} />
          </div>
        </Col>
        <Col
          span={10}
          style={{
            marginTop: "5rem",
          }}
        >
          <Paragraph
            strong
            style={{
              color: "#00516E",
              textAlign: "justify",
            }}
          >
            The Acquis Compliance Material Declaration Tool enables you to
            create and edit material declarations in the standardized IPC-1752A
            format. This ensures that your material data is communicated
            accurately and consistently across your supply chain, helping you
            comply with international regulations and meet your customers’
            demands. The IPC-1752A Material Declaration Tool is your
            comprehensive solution for managing and communicating material
            composition data within your supply chain. Designed to help
            manufacturers, suppliers, and compliance teams, this tool ensures
            adherence to global environmental regulations like RoHS (Restriction
            of Hazardous Substances) and REACH (Registration, Evaluation,
            Authorisation, and Restriction of Chemicals).
          </Paragraph>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5rem",
              marginTop: "3rem",
            }}
          >
            <Button
              style={{
                backgroundColor: "#F7863E",
                color: "white",
                borderRadius: 0,
              }}
              size="large"
              onClick={() => {
                navigate("declaration");
              }}
            >
              <PlusOutlined style={{ color: "white" }} />
              New XML Declaration
            </Button>
            <UploadXML />
          </div>
        </Col>
      </Row>
      {/* <Outlet /> */}
    </div>
  );
};
export default IPC1752EditorHome;
