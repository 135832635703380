import React from "react";
import { useParams } from "react-router-dom";

import MaterialDisclosure from "./MaterialDisclosure";
import Regulations from "./Regulations";
import ConflictMinerals from "./ConflictMinerals";
import ExtendedMinerals from "./ExtendedMinerals";
import Scip from "./Scip";

const Campaigns: React.FC = () => {
  const { regulationNumber } = useParams();

  return (
    <>
      {regulationNumber === "material disclosure" ? (
        <MaterialDisclosure />
      ) : regulationNumber === "conflict minerals" ? (
        <ConflictMinerals />
      ) : regulationNumber === "extended minerals" ? (
        <ExtendedMinerals />
      ) : regulationNumber === "scip" ? (
        <Scip />
      ) : (
        <Regulations />
      )}
    </>
  );
};

export default Campaigns;
