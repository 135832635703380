import React, { useEffect, useState } from "react";
import { Button, Card, Popconfirm, Row, Col, Timeline } from "antd";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  readTenantUserActivity,
  updateTenant,
} from "../../../../redux/actions/tenantsAction";
import TenantAnalyticsDashboard from "./Dashboard";

// Load FusionCharts modules
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

const TenantOverview: React.FC<{ id: string; number: string }> = (props) => {
  const dispatch = useAppDispatch();

  const deleteData = (id: string, payload?: any) => {
    dispatch(
      updateTenant(
        {
          currentLocationOrigin: window.location.origin,
          updates: payload,
        },
        props.id
      )
    );
  };

  const [userActivity, setuserActivity] = useState<any[]>([]);
  const [userTokens, setuserTokens] = useState<any[]>([]);

  const readTenantUserActivityFromApi = async () => {
    try {
      const response = await readTenantUserActivity(
        {
          pageSize: 10,
          pageNum: 1,
          filters: JSON.stringify({
            softDelete: false,
          }),
          sortBy: ["timeStamp"],
          sortDesc: [false],
        },
        props.number
      );
      if (response) {
        setuserActivity(response?.data);
        setuserTokens(response?.tokens);
      } else {
        console.error("Invalid or missing data in the API response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    readTenantUserActivityFromApi();
  }, []);

  // Prepare data for the chart
  const chartData = userTokens?.map((tokenData: any) => ({
    label: tokenData._id, // Date
    value: tokenData.totalTokens, // Total Tokens used
  }));

  // Chart configuration
  const chartConfigs = {
    type: "column2d", // Chart type
    width: "100%", // Width of the chart
    height: "400", // Height of the chart
    dataFormat: "json", // Data type
    dataSource: {
      chart: {
        caption: "ACT AI Token Usage", // Chart Title
        xAxisName: "Date",
        yAxisName: "Tokens Used",
        theme: "fusion", // Chart theme
      },
      data: chartData, // Chart Data
    },
  };

  return (
    <Row gutter={[24, 24]}>
      <Col span={12}>
        <Card title="Usage Data">
          <TenantAnalyticsDashboard tenant={props.number} />
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Latest User Activities">
          <Timeline>
            {userActivity.map((item, index) => (
              <Timeline.Item key={index}>
                {`User ${item.firstName} ${
                  item.requestMethod === "POST" && item.requestUrl !== "/login"
                    ? "created"
                    : item.requestUrl !== "/login" &&
                      item.requestMethod === "PUT"
                    ? "updated"
                    : item.requestMethod === "DELETE"
                    ? "deleted"
                    : "logged in"
                } ${item.requestUrl
                  .split("/")
                  .slice(1)
                  .join(" ")} where name /id : ${
                  item.name ? item.name : item.id || "NA"
                } on ${
                  item.requestMethod === "POST" && !item.updatedAt
                    ? new Date(item.createdAt).toLocaleString("en-GB", {
                        hour12: false,
                      })
                    : new Date(item.updatedAt).toLocaleString("en-GB", {
                        hour12: false,
                      })
                }.`}
              </Timeline.Item>
            ))}
          </Timeline>
        </Card>
      </Col>
      <Col span={12}>
        <Card title="Act AI Tokens Used">
          <ReactFC {...chartConfigs} />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title="Delete"
          extra={
            <Popconfirm
              title={"Clean Tenant Data"}
              description={"Are you sure you want to delete tenant data?"}
              onConfirm={() => deleteData(props.id, { clearDb: true })}
              okText="Yes"
              cancelText="No"
            >
              <Button danger type="primary">
                Clear
              </Button>
            </Popconfirm>
          }
        >
          Want to clear tenant records or data. This action will delete all
          products, components, suppliers, responsible minerals, and campaigns.
          Only tenant configuration and user data will not be touched. <br />
          <br />
          <mark>Note: Tenant users may get blocked after this action.</mark>
        </Card>
      </Col>
    </Row>
  );
};

export default TenantOverview;
