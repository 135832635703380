import axios from "axios";
import { message } from "antd";
import { getLocalStorage } from "./localStore";

export const baseUrl: string = process.env.REACT_APP_BASE_URL!;

/**
 * Public APIs
 * **************************************************************
 * Makes a public POST and GET request to the API with the provided path and payload.
 * @param {string} path - The API endpoint to make the request to.
 * @param {object} payload - The data to send in the request body.
 * @returns {Promise} A Promise that resolves with the response data if the request is successful,
 * or rejects with an error if it fails.
 */
export const publicGetApi = (
  path: string,
  payload: Record<string, string>
): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(baseUrl + path + "?" + new URLSearchParams(payload).toString())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response?.data?.message || error.message, 6);
        reject(error);
      });
  });
};

export const publicPostApi = (path: string, payload: object): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(baseUrl + path, { ...payload })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response?.data?.message || error.message, 6);
        reject(error);
      });
  });
};

/**
 * Private APIs
 * **************************************************************
 * Makes a private POST, GET, PUT and DELETE request to the API with the provided path and payload.
 * Checks if the user's access token is still valid before making the request.
 * If the token is invalid, displays an error message and returns.
 * @param {string} path - The API endpoint to make the request to.
 * @param {object} payload - The data to send in the request body.
 * @returns {Promise} A Promise that resolves with the response data if the request is successful,
 * or rejects with an error if it fails.
 */
export const privatePostApi = (
  path: string,
  payload: any,
  service?: any
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (
      !getLocalStorage("accessToken") ||
      !(parseInt(getLocalStorage("expireAt")) - new Date().getTime() >= 0)
    ) {
      message.error("Session expired, please login again", 6);
      reject({ message: "Session expired, please login again" });
    }
    const formData = payload instanceof FormData;
    const accessToken = getLocalStorage("accessToken");

    axios
      .post(baseUrl + path, formData ? payload : { ...payload }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-service-call": service ? service : path.split("/")[1],
          ...(formData && { "Content-Type": "multipart/form-data" }),
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response?.data?.message || error.message, 6);
        reject(error);
      });
  });
};

export const privateGetApi = (
  path: string,
  payload: Record<string, string>
): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (
      !getLocalStorage("accessToken") ||
      !(parseInt(getLocalStorage("expireAt")) - new Date().getTime() >= 0)
    ) {
      message.error("Session expired, please login again", 6);
      reject({ message: "Session expired, please login again" });
    }
    const accessToken = getLocalStorage("accessToken");
    axios
      .get(baseUrl + path + "?" + new URLSearchParams(payload).toString(), {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-service-call": path.includes("suppliers")
            ? "suppliers"
            : path.includes("products")
            ? "products"
            : path.includes("regulations")
            ? "regulations"
            : path.includes("scip")
            ? "scip"
            : path.split("/").length === 3
            ? path.split("/").pop()
            : path.split("/")[1],
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response?.data?.message || error.message, 6);
        reject(error);
      });
  });
};

export const privatePutApi = (path: string, payload: object): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (
      !getLocalStorage("accessToken") ||
      !(parseInt(getLocalStorage("expireAt")) - new Date().getTime() >= 0)
    ) {
      message.error("Session expired, please login again", 6);
      reject({ message: "Session expired, please login again" });
    }
    const accessToken = getLocalStorage("accessToken");
    axios
      .put(
        baseUrl + path,
        { ...payload },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "x-service-call": path.includes("components")
              ? "components"
              : path.includes("compliances")
              ? "compliances"
              : path.split("/").slice(-2, -1)[0],
          },
        }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response?.data?.message || error.message, 6);
        reject(error);
      });
  });
};

export const privateDeleteApi = (path: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (
      !getLocalStorage("accessToken") ||
      !(parseInt(getLocalStorage("expireAt")) - new Date().getTime() >= 0)
    ) {
      message.error("Session expired, please login again", 6);
      reject({ message: "Session expired, please login again" });
    }
    const accessToken = getLocalStorage("accessToken");
    axios
      .delete(baseUrl + path, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "x-service-call": path.split("/").slice(-2, -1)[0],
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(error.response?.data?.message || error.message, 6);
        reject(error);
      });
  });
};
