import { Button, Dropdown, MenuProps, message, notification } from "antd";
import React, { useState } from "react";
import { FormInstance } from "antd/lib/form";
import { useAppDispatch } from "../../redux/hooks";
import { generateDeclaration } from "../../redux/actions/ipcEditorAction";

const XmlDownloadComponent: React.FC<{
  onSubmit: (values: any) => void;
  form: FormInstance;
  fieldLock: any;
}> = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const generateXmlDeclaration = (type: string) => {
    const values = {
      ...props.form.getFieldsValue(),
      fieldLock: props.fieldLock,
    };
    values.supplierInformation.responseDate =
      values?.supplierInformation?.responseDate?.format("YYYY-MM-DD");
    if (values.formType === "Request/Reply") {
      values.requesterInformation.responseDate =
        values?.requesterInformation?.responseDate?.format("YYYY-MM-DD");
      values.requesterInformation.respondByDate =
        values?.requesterInformation?.respondByDate.format("YYYY-MM-DD");
      values.requesterInformation.mfrItemVersionManufacturingSite = values
        ?.requesterInformation?.mfrItemVersionManufacturingSite
        ? values?.requesterInformation?.mfrItemVersionManufacturingSite
        : false;
    }
    values.supplierInformation.effectiveDate =
      values?.supplierInformation?.effectiveDate?.format("YYYY-MM-DD");
    setLoading(true);

    calculateWeights(values);

    dispatch(generateDeclaration({ declaration: values, fileType: type }))
      .then((response) => {
        downloadXml(response.data, response.filename, type);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const downloadXml = (xmlContent: any, fileName: string, type: string) => {
    if (type === "pdf") {
      const binary = atob(xmlContent);
      xmlContent = new Uint8Array(binary.length);
      for (let i = 0; i < binary.length; i++) {
        xmlContent[i] = binary.charCodeAt(i);
      }
    } else if (type === "excel") {
      xmlContent = new Uint8Array(
        atob(xmlContent)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
    }
    const blob = new Blob([xmlContent], {
      type:
        type === "pdf"
          ? "application/pdf"
          : type === "excel"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : "application/xml",
    });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const openNotificationWithErrors = (errors: string[]) => {
    {
      errors &&
        notification.error({
          message: "Validation Errors",
          description: (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ),
          duration: 0,
        });
    }
  };
  const handleButtonClick = async (type: string) => {
    try {
      await props.form.validateFields();
      generateXmlDeclaration(type);
    } catch (errorInfo: any) {
      const errorList = errorInfo?.errorFields?.map(
        (fieldError: any) => `${fieldError.name[0]}: ${fieldError.errors[0]}`
      );
      openNotificationWithErrors(errorList);
    }
  };

  const calculateWeights = (data: any) => {
    const errors: string[] = [];
    if (data.items && data.items?.length > 0) {
      let totalItemWeight = 0;

      for (const item of data.items) {
        let totalMaterialWeight = 0;

        for (const material of item.materials) {
          let totalSubstanceWeight = 0;

          for (const substance of material.substances) {
            totalSubstanceWeight += convertToMg(
              substance.weight,
              substance.uom
            );
          }

          const materialWeight = convertToMg(material.weight, material.uom);
          if (totalSubstanceWeight.toFixed(9) !== materialWeight.toFixed(9)) {
            setLoading(false);
            // message.error(
            //   `Substance weight does not match Material weight for ${material.name} its ${totalSubstanceWeight} for ${item.name}`
            // );
            // throw new Error(
            //   `Substance weight does not match Material weight for ${material.name} its ${totalSubstanceWeight}`
            // );
            errors.push(
              `Substance weight does not match Material weight for ${material.name}.`
            );
          }
          totalMaterialWeight += materialWeight;
        }
        if (data.productDeclarationType === "Multiple") {
          const itemWeight = convertToMg(item.weight, item.uom);
          if (totalMaterialWeight.toFixed(9) !== itemWeight.toFixed(9)) {
            setLoading(false);
            // message.error(
            //   `Material weight does not match Item weight for ${item.name} its ${totalMaterialWeight}`
            // );
            // throw new Error(
            //   `Material weight does not match Item weight for ${item.name} its ${totalMaterialWeight}`
            // );
            errors.push(
              `Material weight does not match Item weight for ${item.name}.`
            );
          }

          totalItemWeight += convertToMg(item.weight, item.uom);
        } else {
          totalItemWeight = totalMaterialWeight;
        }
      }

      const totalProductWeight = convertToMg(
        data.supplierInformation.weight,
        data.supplierInformation.uom
      );

      if (totalItemWeight.toFixed(9) !== totalProductWeight.toFixed(9)) {
        setLoading(false);
        // message.error(
        //   `Total SubProduct weight does not match Product weight. Current total is ${totalItemWeight}`
        // );
        // throw new Error(
        //   `Total SubProduct weight does not match Product weight. Current total is ${totalItemWeight}`
        // );
        errors.push(
          `Total SubProduct weight does not match Product weight. Current total is ${totalItemWeight} mg.`
        );
      }
      if (errors.length > 0) {
        setLoading(false);
        notification.error({
          message: "Validation Errors",
          description: (
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          ),
          duration: 0,
        });

        throw new Error("Validation failed, see notification for details.");
      }
    }
    return null;
  };

  const convertToMg = (weight: number, unit: string): number => {
    const parsedWeight = weight;
    let weightInMg: number;
    switch (unit) {
      case "kg":
        weightInMg = parsedWeight * 1_000_000;
        break;
      case "g":
        weightInMg = parsedWeight * 1_000;
        break;
      case "mg":
        weightInMg = parsedWeight;
        break;
      default:
        message.error(`provide unit of measure: ${unit}.`);
        setLoading(false);
        throw new Error(`provide unit of measure: ${unit}`);
    }

    return parseFloat(weightInMg.toFixed(9));
  };

  const items: MenuProps["items"] = [
    {
      key: "xml",
      label: "XML",
    },
    {
      key: "pdf",
      label: "PDF",
    },
    {
      key: "excel",
      label: "Excel",
    },
  ];

  const onClick: MenuProps["onClick"] = ({ key }) => {
    handleButtonClick(key);
  };
  return (
    <>
      <Dropdown menu={{ items, onClick }} placement="bottomRight">
        <Button
          style={{
            background: "#00516E",
            color: "white",
            marginRight: 8,
            borderRadius: 0,
          }}
          loading={loading}
          onClick={(e) => e.preventDefault()}
        >
          Generate Declaration
        </Button>
      </Dropdown>
    </>
  );
};

export default XmlDownloadComponent;
