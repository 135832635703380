import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  privateDeleteApi,
  publicGetApi,
} from "../../utils/apiHelper";
import { storeRegulationsInLocalStorage } from "../../utils/localStore";
import {
  RegulationsState,
  RegulationSubstancesState,
  RegulationExemptionsState,
} from "../../utils/types/regulation";

/**
 * Bulk Operations
 */
export const listRegulations = (payload: any) => () => {
  return new Promise<RegulationsState>((resolve, reject) => {
    privateGetApi("/regulations", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
          if (payload.pageSize === 0) {
            storeRegulationsInLocalStorage(response?.data?.data?.regulations);
          }
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const listRegulationSubtances = (payload: any) => () => {
  return new Promise<RegulationSubstancesState>((resolve, reject) => {
    publicGetApi("/public/regulations/substances", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const listRegulationExemptions = (payload: any) => () => {
  return new Promise<RegulationExemptionsState>((resolve, reject) => {
    publicGetApi("/public/regulations/exemptions", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const regulationExemptions = (payload: any) => () => {
  return new Promise<RegulationExemptionsState>((resolve, reject) => {
    privateGetApi("/regulations/exemptions", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

/**
 * CRUD Operations
 */
export const createRegulation = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/regulations", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createSubstance = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/regulations/substances", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createExemption = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/regulations/exemptions", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readRegulation = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/regulations/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateRegulation = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/regulations/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateSubstance = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/regulations/substances/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateExemption = (payload: any, id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePutApi("/regulations/exemptions/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteRegulation = (id: string) => () => {
  return new Promise<any>((resolve, reject) => {
    privateDeleteApi("/regulations/" + id)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
