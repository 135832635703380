import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Select,
  DatePicker,
} from "antd";

import { Tenant } from "../../utils/types/tenant";
import { Subscription } from "../../utils/types/subscription";
import { Regulation } from "../../utils/types/regulation";
import { useAppDispatch } from "../../redux/hooks";
import { createTenant, updateTenant } from "../../redux/actions/tenantsAction";
import { listSubscriptions } from "../../redux/actions/subscriptionsAction";
import { listRegulations } from "../../redux/actions/regulationsAction";
import { getLocalStorage } from "../../utils/localStore";
import dayjs from "dayjs";

const TenantForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Tenant;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [subscriptionOptions, setSubscriptionOptions] = useState<any[]>([]);
  const [regulationOptions, setRegulationOptions] = useState<any[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const fetchSubscriptions = (value: string) => {
    dispatch(
      listSubscriptions({
        pageSize: 0,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          name: { $regex: value, $options: "i" },
        }),
      })
    ).then((response: any) => {
      setSubscriptionOptions(
        response.subscriptions.map((subscription: Subscription) => ({
          label: subscription.name,
          value: subscription.name,
          default: subscription,
        }))
      );
    });
  };

  const fetchRegulations = (value: string) => {
    const values = value.split(", ");
    let filters = {
      name: { $regex: value, $options: "i" },
    };
    if (values.length > 1) {
      filters = {
        name: { $in: values } as {
          $in: string[];
          $regex: string;
          $options: string;
        },
      };
    }
    dispatch(
      listRegulations({
        pageSize: 0,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify(filters),
      })
    ).then((response: any) => {
      setRegulationOptions(
        response.regulations.map((regulation: Regulation) => ({
          label: regulation.name,
          value: regulation.regulationNumber,
          default: regulation,
        }))
      );
    });
  };

  const onFormSubmit = (values: Tenant) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(
        createTenant({
          ...values,
          subscription: subscriptionOptions.find(
            (e) => e.label === values.subscription
          )?.default,
          regulations: values.regulations?.length
            ? values.regulations.map(
                (value) =>
                  regulationOptions.find((e) => e.value === value)?.default
              )
            : [],
        })
      )
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(
        updateTenant(
          {
            currentLocationOrigin: window.location.origin,
            updates: {
              ...values,
              subscription: subscriptionOptions.find(
                (e) => e.label === values.subscription
              )?.default,
              regulations: values.regulations?.length
                ? values.regulations.map(
                    (value) =>
                      regulationOptions.find((e) => e.value === value)?.default
                  )
                : [],
            },
          },
          props.formData._id
        )
      )
        .then(() => {
          props.fetchData();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  const disablePastDates = (current: dayjs.Dayjs) => {
    return current && current < dayjs().startOf("day");
  };

  const disableEndDate = (current: dayjs.Dayjs) => {
    const startDate = form.getFieldValue("subscriptionStartDate");
    return (
      current &&
      (startDate
        ? current < startDate.startOf("day") + 1
        : current < dayjs().startOf("day"))
    );
  };

  useEffect(() => {
    if (open) {
      fetchSubscriptions("");
      fetchRegulations("");
      form.resetFields();
    }
  }, [open]);

  return (
    <>
      {permissions[props.type === "create" ? "create" : "update"]?.includes(
        "tenants"
      ) && (
        <>
          <Button
            type={props.type === "create" ? "primary" : "text"}
            title={props.type === "create" ? "New Tenant" : "Edit Tenant"}
            onClick={showDrawer}
            icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
          >
            {props.type === "create" ? "New Tenant" : null}
          </Button>
          <Drawer
            title={
              props.type === "create" ? "Create a New Tenant" : "Edit Tenant"
            }
            width={720}
            onClose={onClose}
            open={open}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={form.submit} type="primary" loading={loading}>
                  {props.type === "create" ? "Submit" : "Save"}
                </Button>
              </Space>
            }
          >
            {open ? (
              <Form
                form={form}
                layout="vertical"
                initialValues={
                  props.type === "create"
                    ? {}
                    : {
                        ...props.formData,
                        subscription: props.formData?.subscription.name,
                        regulations: props.formData?.regulations.map(
                          (e) => e.regulationNumber
                        ),
                      }
                }
                onFinish={onFormSubmit}
              >
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="number"
                      label="Workspace"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter workspace name",
                        },
                        {
                          pattern: new RegExp(/^[A-Za-z0-9]{3,25}$/),
                          message:
                            "Please input alpha numeric with minimum 3 characters",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Please enter workspace name"
                        disabled={props.type === "create" ? false : true}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="companyName"
                      label="Company Name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter company name",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter company name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter first name",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter first name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter last name",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter last name" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="designation" label="Designation">
                      <Input placeholder="Please enter designation" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="emailAddress"
                      label="Email Address"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter email address",
                        },
                        {
                          type: "email",
                          message: "Please input a valid email address",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter email address" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="phoneNumber"
                      label="Phone Number"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter phone number",
                        },
                        {
                          pattern: new RegExp(/^[0-9]{10}$/),
                          message: "Please input a valid phone number",
                        },
                      ]}
                    >
                      <Input placeholder="Please enter phone number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="addressLine1" label="Address Line 1">
                      <Input placeholder="Please enter address" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="addressLine2" label="Address Line 2">
                      <Input placeholder="Please enter address" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item name="country" label="Country">
                      <Input placeholder="Please enter country" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="state" label="State">
                      <Input placeholder="Please enter state" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pincode" label="ZIP Code">
                      <Input placeholder="Please enter zip code" />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                {props.type !== "update" && (
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="subscription"
                        label="Subscription"
                        rules={[
                          {
                            required: true,
                            message: "Please select an subscription",
                          },
                        ]}
                      >
                        <Select
                          options={subscriptionOptions}
                          onSearch={(text) => fetchSubscriptions(text)}
                          placeholder="Please select a subscription"
                          showSearch
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="regulations"
                        label="Regulations"
                        rules={[]}
                      >
                        <Select
                          options={regulationOptions}
                          onSearch={(text) => fetchRegulations(text)}
                          placeholder="Please select a regulations"
                          mode="multiple"
                          allowClear
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="subscriptionStartDate"
                        label="Subscription Start Date"
                        rules={[
                          {
                            required: true,
                            message: "Please select Subscription start date",
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={(current) =>
                            disablePastDates(dayjs(current))
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="renewalPeriod"
                        label="Renewal Period"
                        rules={[]}
                      >
                        <Select placeholder="Renewal Period">
                          <Select.Option value="1 month">1 month</Select.Option>
                          <Select.Option value="3 months">
                            3 months
                          </Select.Option>
                          <Select.Option value="6 months">
                            6 months
                          </Select.Option>
                          <Select.Option value="12 months">
                            12 months
                          </Select.Option>
                          <Select.Option value="No renewal">
                            No renewal
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="subscriptionEndDate"
                        label="Subscription End Date"
                        rules={[
                          {
                            required: true,
                            message: "Please select subscription end date",
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={(current) =>
                            disableEndDate(dayjs(current))
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="remarks" label="Remarks">
                      <Input.TextArea
                        rows={4}
                        placeholder="Please enter remarks"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            ) : null}
          </Drawer>
        </>
      )}
    </>
  );
};

export default TenantForm;
