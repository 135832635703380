import React, { useEffect } from "react";
import {
  GoldOutlined,
  IdcardOutlined,
  TeamOutlined,
  TagsOutlined,
  MenuFoldOutlined,
  DownloadOutlined,
  CloudOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Space, Button } from "antd";
import type { MenuProps } from "antd";

import styles from "./sider.module.css";
import Icon from "./../../../Icon";
import logo from "../../../assets/logo-text.png";
import logoIcon from "../../../assets/logo-icon.png";
import { useNavigate, useLocation } from "react-router-dom";
import { baseUrl } from "../../../utils/apiHelper";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStore";
import axios from "axios";

const { Sider } = Layout;

const SiderLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuItems, setMenuItems] = React.useState<MenuProps["items"]>([]);
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const [tenantLogo, setTenantLogo] = React.useState<string>();

  const adminMenuItems: MenuProps["items"] = [
    {
      key: "/app",
      label: "Dashboard",
      icon: <Icon name="dashboard" style={{ fontSize: 13 }} />,
    },
    { type: "divider" },
    {
      key: "/app/regulations",
      label: "Regulations",
      icon: <Icon name="regulation" style={{ fontSize: 20 }} />,
      disabled: !permissions?.read?.includes("regulations"),
    },
    {
      key: "/app/smelters",
      label: "Smelters",
      icon: <GoldOutlined style={{ fontSize: "20px" }} />,
      disabled: !permissions?.read?.includes("smelters"),
    },
    {
      key: "/app/subscriptions",
      label: "Subscriptions",
      icon: <TagsOutlined style={{ fontSize: "20px" }} />,
      disabled: !permissions?.read?.includes("subscriptions"),
    },
    {
      key: "/app/tenants",
      label: "Tenants",
      icon: <IdcardOutlined style={{ fontSize: "20px" }} />,
      disabled: !permissions?.read?.includes("tenants"),
    },
    { type: "divider" },
    {
      key: "/app/users",
      label: "User Management",
      icon: <TeamOutlined style={{ fontSize: "20px" }} />,
      children: [
        {
          key: "users/profiles",
          label: "User Profiles",
          disabled: !permissions?.read?.includes("users"),
        },
        {
          key: "users/permissions",
          label: "Roles & Permissions",
          disabled: !permissions?.read?.includes("roles"),
        },
      ],
    },
    {
      key: "/app/imports",
      label: "Imports",
      icon: <Icon name="import-data" style={{ fontSize: 18 }} />,
    },
  ];

  const tenantMenuItems: MenuProps["items"] = [
    {
      key: "/app",
      label: "Dashboard",
      icon: <Icon name="dashboard" style={{ fontSize: 13 }} />,
      disabled: false,
    },
    { type: "divider" },
    {
      key: "/app/products",
      label: "Products",
      icon: <Icon name="products" style={{ fontSize: 20 }} />,
      disabled: !permissions?.read?.includes("products"),
    },
    {
      key: "/app/components",
      label: "Components",
      icon: <Icon name="components" style={{ fontSize: 13 }} />,
      disabled: !permissions?.read?.includes("components"),
    },
    {
      key: "/app/responsibleminerals",
      label: "Responsible Minerals",
      icon: <Icon name="minerals" style={{ fontSize: 18 }} />,
      disabled: false,
      children: [
        {
          key: "/app/responsibleminerals/conflictminerals",
          label: "Conflict Minerals",
          disabled: !permissions?.read?.includes("conflictminerals"),
        },
        {
          key: "/app/responsibleminerals/extendedminerals",
          label: "Extended Minerals",
          disabled: !permissions?.read?.includes("extendedminerals"),
        },
      ],
    },
    { type: "divider" },
    {
      key: "/app/suppliers",
      label: "Suppliers",
      icon: <Icon name="supplier" style={{ fontSize: 20 }} />,
      disabled: !permissions?.read?.includes("suppliers"),
    },
    {
      key: "/app/campaignmanager/campaign",
      label: "Campaign Manager",
      icon: <Icon name="campaign-manager" style={{ fontSize: 22 }} />,
      disabled: !permissions?.read?.includes("campaignmanager"),
    },
    {
      key: "/app/scip",
      label: "SCIP (WFD)",
      icon: <Icon name="scip" style={{ fontSize: 22 }} />,
      disabled: !permissions?.read?.includes("scip"),
    },
    // { type: "divider" },
    // { key: "8", label: "Recycle Bin", icon: <DeleteOutlined /> },
    { type: "divider" },
    {
      key: "/app/users",
      label: "User Management",
      icon: <TeamOutlined style={{ fontSize: "20px" }} />,
      disabled: false,
      children: [
        {
          key: "/app/users/profiles",
          label: "User Profiles",
          disabled: !permissions?.read?.includes("users"),
        },
        {
          key: "/app/users/permissions",
          label: "Roles & Permissions",
          disabled: !permissions?.read?.includes("roles"),
        },
      ],
    },
    {
      key: "/app/regulations",
      label: "Regulations",
      icon: <Icon name="regulation" style={{ fontSize: 20 }} />,
      disabled: !permissions?.read?.includes("regulations"),
    },
    {
      key: "/app/imports",
      label: "Imports",
      icon: <Icon name="import-data" style={{ fontSize: 18 }} />,
      disabled: false,
    },
    {
      key: "/app/reports",
      label: "Reports",
      icon: <DownloadOutlined style={{ fontSize: "20px" }} />,
      disabled: false,
    },
    {
      key: "/app/account-settings",
      label: "Settings",
      icon: <Icon name="settings" style={{ fontSize: 17 }} />,
      disabled: !permissions?.read?.includes("roles"),
    },
    // { key: "12", label: "Help Support", icon: <CustomerServiceOutlined /> },
  ];

  const onMenuClick = (e: any) => {
    const { key } = e;
    navigate(key?.toString() || "/app");
  };

  const onCollapse = () => {
    setCollapsed((prev) => !prev);
    setLocalStorage("collapsed", !collapsed);
  };

  useEffect(() => {
    const user = getLocalStorage("user");
    const collapsed = getLocalStorage("collapsed");
    setCollapsed(collapsed ? collapsed : false);
    if (user) {
      const { workspace } = user;
      if (workspace === "acquiscompliance") {
        setMenuItems(adminMenuItems);
      } else {
        setMenuItems(tenantMenuItems);
        if (user.companyLogo) {
          axios
            .post(
              baseUrl + "/tenants/media/download",
              {
                tenantNumber: user.tenantNumber,
                filename: user.companyLogo,
              },
              {
                responseType: "arraybuffer",
                headers: {
                  Authorization: `Bearer ${getLocalStorage("accessToken")}`,
                },
              }
            )
            .then((response: any) => {
              const imageBlob = new Blob([response.data], {
                type: "image/png",
              });
              const URL = window.URL || window.webkitURL;
              setTenantLogo(URL.createObjectURL(imageBlob));
            });
        }
      }
    }
  }, []);

  return (
    <Sider width={265} className={styles.sider} collapsed={collapsed}>
      <div className={styles.logo}>
        {collapsed ? (
          <img src={logoIcon} alt="Logo" height={30} />
        ) : (
          <img src={tenantLogo ? tenantLogo : logo} alt="Logo" height={30} />
        )}
      </div>
      <Space
        direction="vertical"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "calc(91.40vh)",
        }}
      >
        <Menu
          mode="inline"
          className={styles.menu}
          selectedKeys={[location.pathname]}
          items={
            menuItems?.filter(
              (item) => item && (!("disabled" in item) || !item.disabled)
            ) ?? []
          }
          theme="dark"
          onClick={(e) => onMenuClick(e)}
        ></Menu>
        <Button
          type="primary"
          block
          icon={<MenuFoldOutlined />}
          className={styles.siderButton}
          onClick={onCollapse}
        ></Button>
      </Space>
    </Sider>
  );
};

export default SiderLayout;
