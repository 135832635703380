import React, { useEffect, useState } from "react";
import {
  SettingOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  LoginOutlined,
  EyeOutlined,
  RightCircleOutlined,
} from "@ant-design/icons";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import Overview from "./Overview";
import Materials from "./MaterialDisclosure";
import AuditLogs from "./AuditLogs";
import componentIcon from "../../../../assets/component-profile-icon.png";
import "./substanceListing.css";
import {
  Space,
  Typography,
  Button,
  Input,
  Card,
  Col,
  Row,
  Divider,
  Form,
  Tabs,
  Select,
  DatePicker,
  Avatar,
  Modal,
  Drawer,
  Tag,
  Tooltip,
} from "antd";
import type { TabsProps, SelectProps, DatePickerProps } from "antd";

import { Component } from "../../../../utils/types/component";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateComponent } from "../../../../redux/actions/componentsAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";
import { readComponent } from "../../../../redux/actions/componentsAction";
import FileUploader from "../../../../components/layouts/FileUploader";
import dayjs from "dayjs";
import { listUsers } from "../../../../redux/actions/usersAction";
import { User } from "../../../../utils/types/user";
import ErrorBoundary from "../../../../utils/errorBoundary";
const { Option } = Select;
const { Title, Text } = Typography;
const tagColors = ["#48BC19", "#FF4446", "#F9A314"];

function getLinearGradient(hexColor: string) {
  let hex = hexColor?.replace(/^#/, "");
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }
  const num = parseInt(hex, 16);
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;

  const fromColor = r + "," + g + "," + b;
  const color2 = r + 20 + "," + (g + 20) + "," + (b + 20);
  const toColor = r + 50 + "," + (g + 50) + "," + (b + 50);
  return `linear-gradient(90deg, rgba(${fromColor},1) 0%, rgba(${color2}, 1) 40%, rgba(${toColor}, 1) 100%)`;
}

const ComponentProfile: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const [form] = Form.useForm();
  const { Meta } = Card;

  const user = getLocalStorage("user");
  const parseUser = user ? user : null;
  const { tenantNumber } = getLocalStorage("user");
  const [usersOptions, setUsersOptions] = useState<any>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [componentDetail, setComponentDetail] = useState<any | null>(null);
  const [componentCampaigns, setComponentCampaigns] = useState<null>(null);
  const [alternates, setAlternates] = useState<Component | null>(null);
  const [isHideDetails, setDetailsVisible] = useState(true);
  const [showEditBtn, setShowEditBtn] = useState(false);
  const [isHideAllDetails, setHideAllDetails] = useState(true);
  const [products, setProducts] = useState<any>([]);
  const [componentHistory, setComponentHistory] = useState<any>([]);
  const [complianceHistory, setComplianceHistory] = useState<any>([]);
  const [supplier, setSupplier] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [alternateCompliances, setalternateCompliances] =
    React.useState<any[]>();
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const items: TabsProps["items"] = [
    {
      key: "",
      label: "Overview",
      children: (
        <Overview
          componentCampaign={componentCampaigns}
          component={componentDetail}
          alternates={alternates}
        />
      ),
    },
    permissions?.read.includes("materials") && {
      key: "materials",
      label: "Full Materials Disclosure",
      children: (
        <>
          {!isHideAllDetails ? (
            <Modal
              open={isFullScreen}
              onCancel={() => {
                setIsFullScreen(false);
                setHideAllDetails(true);
                setDetailsVisible(true);
              }}
              footer={false}
              width="100vw"
              style={{ top: 0, padding: 0, height: "100vh" }}
              centered={false}
              className="full-screen-modal"
            >
              <Materials component={componentDetail} type={"fullScreen"} />
            </Modal>
          ) : (
            <Materials component={componentDetail} />
          )}
        </>
      ),
    },
    {
      key: "auditlogs",
      label: "Audit Logs",
      children: (
        <AuditLogs
          componentHistory={componentHistory}
          complianceHistory={complianceHistory}
        />
      ),
    },
  ].filter(Boolean);

  interface componentProfileParams {
    id: string;
  }

  const viewAlternateComponent = (record: any) => {
    window.scrollTo(0, 0);
    setOpenDrawer(false);
    const params: componentProfileParams = {
      id: record?._id,
    };
    const route = `/${params.id}`;
    const currentRoute = window.location.pathname;
    const basePath = currentRoute.split("/").slice(0, 3).join("/");
    const finalPath = basePath + route;
    navigate(finalPath);
  };

  const fetchData = (id: string) => {
    setLoading(true);
    dispatch(readComponent(id))
      .then((response) => {
        setComponentDetail(response?.component);
        setComponentCampaigns(response.documentCampaigns);
        setAlternates(response?.documentAlternates);
        setProducts(response?.documentProducts);
        setComponentHistory(response?.documentHistory);
        setComplianceHistory(
          response?.documentComplianceHistory.map((e: any) => {
            return {
              ...e,
              document: {
                ...e.document,
                manufacturer: response?.component?.manufacturer,
              },
            };
          })
        );
        setSupplier(response?.documentSupplier);
        const specifications = Object.fromEntries(
          parseUser?.specifications?.map((specification: any) => {
            const fieldValue =
              specification.dataType === "Dateinput"
                ? dayjs(response?.component?.specification[specification.value])
                : response?.component?.specification[specification.value];

            return [specification.value, fieldValue];
          })
        );
        form.setFieldsValue({
          ...response?.component,
          specification: specifications,
        });
        form.setFieldValue(
          "products",
          response?.documentProducts?.map((product: any) => product.name) ||
            "N/A"
        );
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  };

  const fetchAlternateData = () => {
    const compliances: any[] = [];
    regulations?.forEach((regulation: any) => {
      const compliance = alternates?.map((e: any) =>
        e.compliances.find(
          (item: any) => regulation.regulationNumber === item.regulationNumber
        )
      );
      if (compliance) {
        compliances.push({
          regulationName: regulation.name,
          regulationNumber: regulation.regulationNumber,
        });
      }
    });
    setalternateCompliances(compliances);
  };

  useEffect(() => {
    if (alternates && alternates.length > 0) {
      fetchAlternateData();
    }
  }, [alternates]);

  const handleHideDetails = () => {
    setDetailsVisible(!isHideDetails);
  };

  const handleTabChange = (key: string) => {
    navigate(key);
  };

  const regulations = getRegulationsFromLocalStorage() ?? [];
  const options: SelectProps["options"] = [];

  regulations.forEach((regulation: any) => {
    options.push({
      label: regulation.name,
      value: regulation.regulationNumber,
    });
  });

  const fetchUsersData = (search?: any) => {
    setLoading(true);
    dispatch(
      listUsers({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          firstName: { $regex: search, $options: "i" },
        }),
      })
    )
      .then((response) => {
        setUsersOptions(
          response.users.map((e: User) => ({
            label: e.firstName,
            value: e.emailAddress,
            ...e,
          }))
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onChangeDate: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  useEffect(() => {
    fetchData(id);
    fetchUsersData("");
    setIsModalVisible(true);
  }, [id]);

  const onFormSubmit = (updates: Component) => {
    dispatch(updateComponent({ updates: updates }, id))
      .then(() => {
        fetchData(id);
        form.resetFields();
        setShowEditBtn(false);
      })
      .catch((e: any) => console.log(e));
  };

  const onClickSupplier = () => {
    const params = {
      id: supplier?._id,
    };
    const route = `/suppliers/${params.id}`;
    const currentRoute = window.location.pathname;
    const basePath = currentRoute.split("/").slice(0, 2).join("/");
    const finalPath = basePath + route;
    navigate(finalPath);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setHideAllDetails(false);
    setIsFullScreen(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const enterFullScreen = () => {
    setHideAllDetails(false);
    setIsFullScreen(true);
  };
  return (
    <>
      <ErrorBoundary>
        {location.pathname.split("/").pop() === "materials" && (
          <Modal
            open={isModalVisible}
            onCancel={handleCancel}
            centered
            width={330}
            footer={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button onClick={handleCancel} style={{ marginRight: "auto" }}>
                  No
                </Button>
                <Button type="primary" onClick={handleOk}>
                  Yes
                </Button>
              </div>
            }
          >
            <div
              style={{
                textAlign: "center",
                marginBottom: "16px",
                marginTop: "10px",
              }}
            >
              <ExclamationCircleFilled
                style={{ fontSize: "20px", color: "red" }}
              />

              <b>
                <p>Do you want to enter full screen?</p>
              </b>
              <p>Full screen will give you better view and experience</p>
            </div>
          </Modal>
        )}
        <Row>
          <Col span={24}>
            <Card
              style={{
                background: getLinearGradient(user?.primaryColor || "#045B7C"),
                margin: "-2em -30px 0",
              }}
              bordered={false}
              loading={loading}
            >
              <Row justify="space-between" align="bottom">
                <Col>
                  <Row align="middle" gutter={25}>
                    <Col>
                      <Avatar
                        size={85}
                        src={componentIcon}
                        style={{ backgroundColor: "#fff" }}
                      />
                    </Col>
                    <Col>
                      <div>
                        <Title
                          level={5}
                          style={{ color: "white", marginTop: 0 }}
                        >
                          {componentDetail &&
                            componentDetail.internalItemNumber}
                        </Title>
                        <Title level={4} style={{ color: "white", margin: 0 }}>
                          {componentDetail &&
                            componentDetail?.manufacturer &&
                            componentDetail?.manufacturer?.itemNumber}
                        </Title>
                        <Text>
                          <a
                            onClick={onClickSupplier}
                            className="link-secondary-color"
                          >
                            {" "}
                            {componentDetail &&
                              componentDetail?.manufacturer &&
                              componentDetail?.manufacturer?.name}
                          </a>
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Space>
                    <Button
                      type="primary"
                      onClick={handleHideDetails}
                      className="btn-light"
                    >
                      {" "}
                      {isHideDetails ? "Hide Details" : "Show Details"}
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
            {isHideDetails && (
              <Card style={{ marginTop: "1rem" }}>
                <Space
                  align="end"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "1rem",
                  }}
                >
                  <Meta
                    title="About the Component :"
                    description={
                      componentDetail?.createdAt
                        ? `Created on : ${new Date(
                            componentDetail?.createdAt
                          ).toLocaleString("en-US", {
                            day: "numeric",
                            month: "short",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                          })}`
                        : "N/A"
                    }
                  />
                  <Space>
                    {!showEditBtn ? (
                      <Button
                        type="primary"
                        ghost
                        onClick={() => setShowEditBtn(!showEditBtn)}
                        icon={<SettingOutlined />}
                      >
                        Edit
                      </Button>
                    ) : (
                      <Space>
                        <Button onClick={() => setShowEditBtn(!showEditBtn)}>
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          icon={<EditOutlined />}
                          onClick={form.submit}
                          disabled={isUploading}
                        >
                          Save
                        </Button>
                      </Space>
                    )}
                  </Space>
                </Space>
                <Divider />
                <Form
                  wrapperCol={{ span: 24 }}
                  layout="vertical"
                  initialValues={{ ...componentDetail, specification: {} }}
                  onFinish={onFormSubmit}
                  form={form}
                >
                  <Row gutter={24}>
                    {parseUser.specifications &&
                      parseUser.specifications?.length > 0 &&
                      parseUser.specifications?.map((specification: any) => (
                        <Col key={specification.value} span={6} sm={6} md={6}>
                          {showEditBtn ? (
                            <Form.Item
                              name={["specification", specification.value]}
                              label={
                                specification.name
                                  ? specification.name.charAt(0).toUpperCase() +
                                    specification.name.slice(1)
                                  : ""
                              }
                              initialValue={
                                componentDetail?.specification !== undefined &&
                                specification?.dataType === "Dateinput" &&
                                componentDetail?.specification[
                                  specification?.value
                                ]
                                  ? dayjs(
                                      componentDetail?.specification[
                                        specification?.value
                                      ]
                                    )
                                  : componentDetail?.specification &&
                                    componentDetail?.specification[
                                      specification?.value
                                    ]
                              }
                            >
                              {specification.dataType === "Textfield" && (
                                <Input />
                              )}
                              {specification.dataType === "Dropdown" && (
                                <Select
                                  allowClear
                                  placeholder={`Select ${specification.name}`}
                                  options={specification.dataValue
                                    .split(",")
                                    .map((value: string) => ({
                                      label: value,
                                      value,
                                    }))}
                                />
                              )}
                              {specification.dataType === "Fileinput" && (
                                <FileUploader
                                  postUrl="products/components/document/upload"
                                  tenantNumber={tenantNumber}
                                  setFormField={(value: string) =>
                                    form.setFieldValue(
                                      ["specification", specification.value],
                                      value
                                    )
                                  }
                                  onUploadStatusChange={setIsUploading}
                                />
                              )}
                              {specification.dataType === "Dateinput" && (
                                <DatePicker onChange={onChangeDate} />
                              )}
                            </Form.Item>
                          ) : (
                            <div className="form-values">
                              <Text>
                                {specification.name
                                  ? specification.name.charAt(0).toUpperCase() +
                                    specification.name.slice(1)
                                  : ""}
                              </Text>
                              <br />
                              <Text type="secondary">
                                {componentDetail?.specification !== undefined &&
                                specification?.dataType === "Dateinput" &&
                                componentDetail?.specification[
                                  specification?.value
                                ]
                                  ? new Date(
                                      componentDetail?.specification[
                                        specification?.value
                                      ]
                                    ).toLocaleDateString()
                                  : (componentDetail?.specification &&
                                      componentDetail?.specification[
                                        specification?.value
                                      ]) ||
                                    "N/A"}
                              </Text>
                            </div>
                          )}
                        </Col>
                      ))}
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name="countryOfOrigin"
                          label="Country of Origin"
                        >
                          <Input />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Country of Origin</Text>
                          <br />
                          <Text type="secondary">
                            {componentDetail?.countryOfOrigin || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="type" label="Commodiity Type">
                          <Input />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Commodiity Type</Text>
                          <br />
                          <Text type="secondary">
                            {componentDetail?.type || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name="assignedTo"
                          label="Assigned to"
                          initialValue={
                            (componentDetail && componentDetail?.assignedTo) ||
                            "N/A"
                          }
                        >
                          <Select
                            options={usersOptions}
                            onSearch={(text) => fetchUsersData(text)}
                            placeholder="Please select a users"
                            allowClear
                          />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Assigned to</Text>
                          <br />
                          <Text type="secondary">
                            {componentDetail?.assignedTo || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name="status"
                          label="Component Status"
                          initialValue={
                            (componentDetail && componentDetail?.status) ||
                            "Active"
                          }
                        >
                          <Select>
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                          </Select>
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Component Status</Text>
                          <br />
                          <Text type="secondary">
                            {componentDetail?.status || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name="risk"
                          label="Risk Level"
                          initialValue={
                            (componentDetail && componentDetail?.risk) || "Low"
                          }
                        >
                          <Select>
                            <Option value="High">High</Option>
                            <Option value="Medium">Medium</Option>
                            <Option value="Low">Low</Option>
                          </Select>
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Risk Level</Text>
                          <br />
                          <Text type="secondary">
                            {componentDetail?.risk || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item name="outOfScope" label="Out of scope for">
                          <Select
                            mode="multiple"
                            allowClear
                            placeholder="Select Regulations"
                            options={options}
                          />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Out of scope for</Text>
                          <br />
                          <Text type="secondary">
                            {componentDetail?.outOfScope || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name="products"
                          label="Where used"
                          initialValue={
                            (products &&
                              products.map((product: any) => product.name)) ||
                            "N/A"
                          }
                        >
                          <Input disabled />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Where used</Text>
                          <br />
                          <Text type="secondary">
                            {componentDetail?.products || "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card>
            )}
            <Tabs
              activeKey={
                location.pathname.split("/").pop() === id
                  ? ""
                  : location.pathname.split("/").pop()
              }
              items={items}
              onChange={handleTabChange}
              tabBarExtraContent={
                <Space>
                  {isHideAllDetails &&
                    location.pathname.split("/").pop() === "materials" && (
                      <Button type="primary" onClick={enterFullScreen}>
                        <LoginOutlined />
                        Enter Full Screen
                      </Button>
                    )}
                  {alternates?.length > 0 && (
                    <Button
                      className="secondary-btn"
                      onClick={() => setOpenDrawer(true)}
                    >
                      <EyeOutlined />
                      Alternate Parts ( {alternates?.length} )
                    </Button>
                  )}
                </Space>
              }
            />
            <Drawer
              title={`${alternates?.length} Alternate Parts Available`}
              width={500}
              onClose={() => setOpenDrawer(false)}
              open={openDrawer}
            >
              <Row>
                {alternates?.map((item: any) => (
                  <Col key={item._id}>
                    <Card
                      style={{
                        background: "#F5F5F5",
                        border: "2px solid #D9D9D9",
                        marginBottom: "20px",
                      }}
                    >
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "5px",
                          marginBottom: "20px",
                          flexWrap: "wrap",
                        }}
                      >
                        {alternateCompliances &&
                          alternateCompliances
                            .slice(0, 3)
                            .map((compliance, id) => (
                              <Tag
                                key={compliance.regulationName}
                                color={tagColors[id]}
                              >
                                {compliance.regulationName}
                              </Tag>
                            ))}
                        {alternateCompliances &&
                          alternateCompliances.length > 3 && (
                            <Tooltip
                              title={alternateCompliances
                                .slice(3)
                                .map((tag) => tag.regulationName)
                                .join(", ")}
                            >
                              <span style={{ color: "#1890FF" }}>
                                +{alternateCompliances.length - 3} more
                              </span>
                            </Tooltip>
                          )}
                      </Space>
                      <Space
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "end",
                        }}
                      >
                        <Title level={5}>
                          Internal # :{" "}
                          <span
                            style={{
                              color: "rgba(0,0,0,0.7)",
                              fontWeight: 400,
                            }}
                          >
                            {item?.internalItemNumber}
                          </span>
                          <br />
                          Manufacturer Item Number :{" "}
                          <span
                            style={{
                              color: "rgba(0,0,0,0.7)",
                              fontWeight: 400,
                            }}
                          >
                            {item?.manufacturer?.itemNumber}
                          </span>
                          <br />
                          Manufacturer Name :{" "}
                          <span
                            style={{
                              color: "rgba(0,0,0,0.7)",
                              fontWeight: 400,
                            }}
                          >
                            {item?.manufacturer?.name}
                          </span>
                          <br />
                        </Title>
                        <Tooltip title="View Alternate Part">
                          <Button
                            type="text"
                            icon={
                              <RightCircleOutlined
                                style={{ fontSize: "25px" }}
                              />
                            }
                            onClick={() => viewAlternateComponent(item)}
                          />
                        </Tooltip>
                      </Space>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Drawer>
          </Col>
        </Row>
      </ErrorBoundary>
    </>
  );
};

export default ComponentProfile;
