import { createBrowserRouter } from "react-router-dom";

/**
 * The root element of the public route.
 */
import AuthLayout from "../views/auth";
import Workspace from "../views/auth/Workspace";
import Login from "../views/auth/Login";
import Authentication from "../views/auth/Authentication";

/**
 * The root element of the private route.
 */
import AppLayout from "../views/app";
import NotFound from "../views/app/NotFound";
import Dashboard from "../views/app/Dashboard";
import ProfileSettings from "../views/app/ProfileSettings";

import Tenants from "../views/app/Tenants";
import TenantList from "../views/app/Tenants/TenantList";
import TenantDetails from "../views/app/Tenants/TenantDetails";
import TenantOverview from "../views/app/Tenants/TenantDetails/Overview";
import TenantSubscription from "../views/app/Tenants/TenantDetails/Subscription";
import TenantRegulation from "../views/app/Tenants/TenantDetails/Regulation";
import TenantConfiguration from "../views/app/Tenants/TenantDetails/Configuration";
import TenantUsers from "../views/app/Tenants/TenantDetails/Users";

import Regulations from "../views/app/Regulations";
import RegulationList from "../views/app/Regulations/RegulationList";
import RegulationDetails from "../views/app/Regulations/RegulationDetails";
import RegulationOverview from "../views/app/Regulations/RegulationDetails/Overview";
import RegulationSubstances from "../views/app/Regulations/RegulationDetails/Substances";
import RegulationExemptions from "../views/app/Regulations/RegulationDetails/Exemptions";

import ImportData from "../views/app/ImportData";
import ExportData from "../views/app/ExportData";
import GenerateReport from "../views/app/ExportData/GenerateReport";
import Subscriptions from "../views/app/Subscriptions";
import Smelters from "../views/app/Smelters";
import UserManagement from "../views/app/UserManagement";
import UserProfiles from "../views/app/UserManagement/UserProfiles";
import RolesAndPermissions from "../views/app/UserManagement/RolesAndPermissions";
import Conflict from "../views/app/ResponsibleMinerals/ConflictMinerals";
import ConflictSmelterList from "../views/app/ResponsibleMinerals/ConflictMinerals/SmelterList";
import ConflictResponseTracker from "../views/app/ResponsibleMinerals/ConflictMinerals/ResponseTracker";
import ConflictSummary from "../views/app/ResponsibleMinerals/ConflictMinerals/Summary";
import ExcelTabs from "../views/app/ResponsibleMinerals/ConflictMinerals/ConflictGenerator";

import Products from "../views/app/Products";
import ProductList from "../views/app/Products/ProductList";

import Components from "../views/app/Components";
import ComponentList from "../views/app/Components/ComponentList";
import ComponentProfile from "../views/app/Components/ComponentProfile";
import ComponentOverview from "../views/app/Components/ComponentProfile/Overview";
import FullMaterialDisclosure from "../views/app/Components/ComponentProfile/MaterialDisclosure";
import ComponentAuditLogs from "../views/app/Components/ComponentProfile/AuditLogs";

import Suppliers from "../views/app/Suppliers";
import SuppliersList from "../views/app/Suppliers/SupplierList";
import SupplierProfile from "../views/app/Suppliers/SupplierProfile";
import SupplierOverview from "../views/app/Suppliers/SupplierProfile/Overview";
import SupplierContacts from "../views/app/Suppliers/SupplierProfile/Contacts";
import SupplierComponents from "../views/app/Suppliers/SupplierProfile/Components";
import SupplierSmelters from "../views/app/Suppliers/SupplierProfile/Smelters";
import SupplierAuditLogs from "../views/app/Suppliers/SupplierProfile/AuditLogs";

import CampaignManager from "../views/app/CampaignManager";
import Campaign from "../views/app/CampaignManager/Campaign";
import CampaignList from "../views/app/CampaignManager/Campaign/campaignList";
import CampaignCreate from "../views/app/CampaignManager/Campaign/campaignCreate";
import CampaignDetails from "../views/app/CampaignManager/Campaign/CampaignDetails";
import CampaignOverview from "../views/app/CampaignManager/Campaign/CampaignDetails/overview";
import CampaignSuppliers from "../views/app/CampaignManager/Campaign/CampaignDetails/suppliers";
import CampaignResponse from "../views/app/CampaignManager/Campaign/CampaignDetails/response";
import CampaignEdit from "../views/app/CampaignManager/Campaign/campaignEdit";
import Survey from "../views/app/CampaignManager/Survey";
import SurveyList from "../views/app/CampaignManager/Survey/SurveyList";
import SurveyDetails from "../views/app/CampaignManager/Survey/SurveyDetails";
import SurveySuppliers from "../views/app/CampaignManager/Survey/SurveyDetails/suppliers";
import SurveyComponents from "../views/app/CampaignManager/Survey/SurveyDetails/components";
import Inbox from "../views/app/CampaignManager/inbox";
import PendingApproval from "../views/app/CampaignManager/pendingApproval";

import ExtendedMinerals from "../views/app/ResponsibleMinerals/ExtendedMinerals";
import ExtendedSummary from "../views/app/ResponsibleMinerals/ExtendedMinerals/Summary";
import ExtendedResponseTracker from "../views/app/ResponsibleMinerals/ExtendedMinerals/ResponseTracker";
import ExtendedSmelterList from "../views/app/ResponsibleMinerals/ExtendedMinerals/SmelterList";
import ExtendedGenerator from "../views/app/ResponsibleMinerals/ExtendedMinerals/Generator";
import ResetPassword from "../views/auth/ResetPassword";
import AccountSettings from "../views/app/AccountSettings";

import SupplierPortalLayout from "../views/supplierPortal";
import SupplierPortalIntroduction from "../views/supplierPortal/introduction";
import SupplierPortalCampaigns from "../views/supplierPortal/Campaigns";
import SupplierPortalSupport from "../views/supplierPortal/Support";
import SupplierPortalLetterOfAuthorisation from "../views/supplierPortal/LetterOfAuthorisation";
import IPC1752EditorLayout from "../views/ipcEditor";
import IPC1752EditorHome from "../views/ipcEditor/ipcEditorMain";
import IPC1752Declaration from "../views/ipcEditor/xmlDeclaration";

import Scip from "../views/app/Scip";
import ScipSummary from "../views/app/Scip/Summary";
import ScipComponents from "../views/app/Scip/Components";
import ScipProducts from "../views/app/Scip/Products";
import ScipProductsList from "../views/app/Scip/Products/ProductList";
import ScipCreate from "../views/app/Scip/Products/ScipCreate";

// import Surveys from "../views/app/CampaignManager/Surveys";

/**
 * An array of private routes that require authentication to access.
 * @type {Array}
 */
const privateRoutes: Array<any> = [
  {
    /**
     * The root element of the private route.
     * @type {React.Component}
     */
    Component: AppLayout,
    path: "/app",
    children: [
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Dashboard,
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "profile-settings",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: ProfileSettings,
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "account-settings",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: AccountSettings,
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "tenants",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Tenants,
        children: [
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: TenantList,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: ":tenantId",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: TenantDetails,
            children: [
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: TenantOverview,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "subscription",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: TenantSubscription,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "regulations",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: TenantRegulation,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "configuration",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: TenantConfiguration,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "users",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: TenantUsers,
              },
            ],
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "imports",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: ImportData,
      },
      {
        path: "reports",
        Component: ExportData,
      },
      {
        path: "reports/generator/:regulationNumber",
        Component: GenerateReport,
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "regulations",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Regulations,
        children: [
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: RegulationList,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: ":regulationId",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: RegulationDetails,
            children: [
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: RegulationOverview,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "substances",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: RegulationSubstances,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "exemptions",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: RegulationExemptions,
              },
            ],
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "subscriptions",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Subscriptions,
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "smelters",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Smelters,
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "users",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: UserManagement,
        children: [
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "profiles",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: UserProfiles,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "permissions",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: RolesAndPermissions,
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "responsibleminerals/conflictminerals",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Conflict,

        children: [
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ConflictSummary,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "responsetracker",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ConflictResponseTracker,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "smelterlist",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ConflictSmelterList,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "generator",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ExcelTabs,
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "responsibleminerals/extendedminerals",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: ExtendedMinerals,

        children: [
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ExtendedSummary,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "responsetracker",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ExtendedResponseTracker,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "smelterlist",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ExtendedSmelterList,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "generator",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ExtendedGenerator,
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "products",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Products,
        children: [
          {
            path: "",
            Component: ProductList,
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "components",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Components,
        children: [
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ComponentList,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: ":id",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: ComponentProfile,
            children: [
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: ComponentOverview,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "materials",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: FullMaterialDisclosure,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "auditlogs",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: ComponentAuditLogs,
              },
            ],
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "suppliers",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: Suppliers,
        children: [
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: SuppliersList,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: ":id",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: SupplierProfile,
            children: [
              // {
              //   /**
              //    * The path of the private route.
              //    * @type {string}
              //    */
              //   path: "",
              //   /**
              //    * The child element of the private route.
              //    * @type {React.Component}
              //    */
              //   Component: SupplierOverview,
              // },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: SupplierContacts,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "components",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: SupplierComponents,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "auditlogs",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: SupplierAuditLogs,
              },
              {
                /**
                 * The path of the private route.
                 * @type {string}
                 */
                path: "smelters",
                /**
                 * The child element of the private route.
                 * @type {React.Component}
                 */
                Component: SupplierSmelters,
              },
            ],
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "campaignmanager",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: CampaignManager,
        children: [
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "campaign",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: Campaign,
            children: [
              { path: "", Component: CampaignList },
              { path: "create", Component: CampaignCreate },
              {
                path: ":id",
                children: [
                  {
                    path: "",
                    Component: CampaignDetails,
                    children: [
                      { path: "", Component: CampaignOverview },
                      { path: "suppliers", Component: CampaignSuppliers },
                      { path: "response", Component: CampaignResponse },
                    ],
                  },
                  { path: "edit", Component: CampaignEdit },
                ],
              },
            ],
          },
          {
            path: "survey",
            Component: Survey,
            children: [
              { path: "", Component: SurveyList },
              {
                path: ":id",
                children: [
                  {
                    path: "",
                    Component: SurveyDetails,
                    children: [
                      { path: "", Component: SurveySuppliers },
                      { path: "components", Component: SurveyComponents },
                    ],
                  },
                ],
              },
            ],
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "inbox",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: Inbox,
          },
          {
            /**
             * The path of the private route.
             * @type {string}
             */
            path: "response",
            /**
             * The child element of the private route.
             * @type {React.Component}
             */
            Component: PendingApproval,
          },
        ],
      },
      {
        path: "scip",
        Component: Scip,
        children: [
          {
            path: "",
            Component: ScipSummary,
          },
          {
            path: "components",
            Component: ScipComponents,
          },
          {
            path: "products",
            Component: ScipProducts,
            children: [
              { path: "", Component: ScipProductsList },
              {
                path: ":productId",
                children: [
                  {
                    path: "",
                    Component: ScipCreate,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "*",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: NotFound,
      },
    ],
  },
];

/**
 * An array of public routes that is easy to access.
 * @type {Array}
 */
const publicRoutes: Array<any> = [
  {
    /**
     * The root element of the public route.
     * @type {React.Component}
     */
    Component: AuthLayout,
    children: [
      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "/",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: Workspace,
      },
      {
        path: "/login/:workspace",
        Component: Login,
      },
      {
        path: "/login/authentication/:workspace",
        Component: Authentication,
      },
      {
        path: "/setpassword",
        Component: ResetPassword,
      },
      {
        path: "/forgotpassword",
        Component: ResetPassword,
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "*",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: NotFound,
      },
    ],
  },
];

/**
 * An array of public supplier routes that require authentication to access.
 * @type {Array}
 */
const supplierPortalPublicRoutes: Array<any> = [
  {
    /**
     * The root element of the public route.
     * @type {React.Component}
     */
    path: "/supplier/:uniqueName/:linkTenantNumber/:linkCampaignId",
    Component: SupplierPortalLayout,
    children: [
      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "introduction",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: SupplierPortalIntroduction,
      },
      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "campaign/:regulationNumber",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: SupplierPortalCampaigns,
      },
      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "campaign/conflict minerals/generator",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: ExcelTabs,
      },
      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "campaign/extended minerals/generator",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: ExtendedGenerator,
      },
      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "letterofauthorisation/:filename",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: SupplierPortalLetterOfAuthorisation,
      },
      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "support",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: SupplierPortalSupport,
      },
      {
        /**
         * The path of the private route.
         * @type {string}
         */
        path: "*",
        /**
         * The child element of the private route.
         * @type {React.Component}
         */
        Component: NotFound,
      },
    ],
  },
];
/**
 * An array of public supplier routes that require authentication to access.
 * @type {Array}
 */
const ipcEditorPublicRoutes: Array<any> = [
  {
    /**
     * The root element of the public route.
     * @type {React.Component}
     */
    path: "/ipceditor",
    Component: IPC1752EditorLayout,
    children: [
      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: IPC1752EditorHome,
      },

      {
        /**
         * The path of the public route.
         * @type {string}
         */
        path: "declaration",
        /**
         * The child element of the public route.
         * @type {React.Component}
         */
        Component: IPC1752Declaration,
      },
    ],
  },
];
const router = createBrowserRouter([
  ...publicRoutes,
  ...privateRoutes,
  ...supplierPortalPublicRoutes,
  ...ipcEditorPublicRoutes,
]);

export default router;
