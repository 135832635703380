export const PrimaryArticleIdentifierTypeList = [
  {
    code: "66297",
    text: "SCIP number",
  },
  {
    code: "66298",
    text: "GTIN (Global Trade Item Number)",
  },
  {
    code: "66299",
    text: "EAN (European Article Number)",
  },
  {
    code: "66300",
    text: "GPC (Universal Product Code)",
  },
  {
    code: "66301",
    text: "JAN (Japanese Article Number)",
  },
  {
    code: "66302",
    text: "UDI (Unique Device Identification)",
  },
  {
    code: "66303",
    text: "ISBN (International Standard Book Number)",
  },
  {
    code: "66304",
    text: "catalogue number",
  },
  {
    code: "66305",
    text: "batch number",
  },
  {
    code: "66306",
    text: "part number",
  },
  {
    code: "66307",
    text: "item number",
  },
  {
    code: "66308",
    text: "reference number",
  },
  {
    code: "66309",
    text: "serial number",
  },
  {
    code: "1342",
    text: "other:",
  },
];

export const ArticleCategoryList = [
  {
    code: "104658",
    text: "8473300000 - Parts and accessories of the machines of heading|8471",
  },
  {
    code: "119182",
    text: "8537109120 - Electronic assembly containing:\n \n-a microprocessor,\n \n-a programmable memory and other electronic components mounted on a printed circuit,\n \n-with or without light-emitting diode (LED) or liquid crystal display (LCD) indicators,\nfor use in the manufacture of products of subheadings 8418 21, 8418 29, 8421 12, 8422 11, 8450 11, 8450 12, 8450 19, 8451 21, 8451 29 and 8516 60",
  },
  {
    code: "105237",
    text: "8504900500 - Electronic assemblies of machines of subheading|8504|50|20",
  },
  {
    code: "105730",
    text: "8529906528 - Electronic assembly comprising at least:\n-\ta printed circuit board with,\n-\tone or more FPGAs (Field Programmable Gate Array) and/or processors for multi-media applications and video signal processing,\n-\tflash memory,\n-\toperating memory,\n-\twith or without one or more USB, HDMI, VGA-, RJ-45 and/or other multimedia interfaces,\n-\tsockets and plugs for connecting a LCD-display, a LED lighting and a control panel",
  },
  {
    code: "104631",
    text: "8471490000 - Other, presented in the form of systems",
  },
];

export const EUProductionFlagList = [
  {
    code: "108023",
    text: "both EU produced and imported",
  },
  {
    code: "108022",
    text: "EU imported",
  },
  {
    code: "108021",
    text: "EU produced",
  },
  {
    code: "58236",
    text: "no data",
  },
];

export const ConcentrationRangeList = [
  {
    code: "66279",
    text: "> 0.1% w/w and < 0.3% w/w",
  },
  {
    code: "66280",
    text: "≥ 0.3% w/w and < 1.0% w/w",
  },
  {
    code: "66281",
    text: "≥ 1.0% w/w and < 10.0% w/w",
  },
  {
    code: "66284",
    text: "≥ 10.0% w/w and < 20.0% w/w",
  },
  {
    code: "66285",
    text: "≥ 20.0% w/w and < 100% w/w",
  },
  {
    code: "66286",
    text: "> 0.1% w/w and ≤ 100% w/w",
  },
];

export const unit = [
  {
    code: "66310",
    text: "m",
  },
  {
    code: "66311",
    text: "dm",
  },
  {
    code: "66312",
    text: "cm",
  },
  {
    code: "2120",
    text: "mm",
  },
];

export const densityUnit = [
  {
    code: "2022",
    text: "kg/m³",
  },
  {
    code: "66313",
    text: "kg/dm³",
  },
  {
    code: "1929",
    text: "g/cm³",
  },
];

export const weightUnit = [
  {
    code: "66314",
    text: "t",
  },
  {
    code: "58374",
    text: "kg",
  },
  {
    code: "58373",
    text: "g",
  },
  {
    code: "64929",
    text: "mg",
  },
];

export const volumeUnit = [
  {
    code: "58376",
    text: "m³",
  },
  {
    code: "66315",
    text: "dm³",
  },
  {
    code: "58375",
    text: "cm³",
  },
];

export const color = [
  {
    code: "64912",
    text: "colourless",
  },
  {
    code: "64913",
    text: "black",
  },
  {
    code: "64914",
    text: "blue",
  },
  {
    code: "64915",
    text: "brown",
  },
  {
    code: "64916",
    text: "gold",
  },
  {
    code: "64917",
    text: "green",
  },
  {
    code: "64918",
    text: "grey",
  },
  {
    code: "64919",
    text: "orange",
  },
  {
    code: "64920",
    text: "pink",
  },
  {
    code: "64921",
    text: "purple",
  },
  {
    code: "64922",
    text: "red",
  },
  {
    code: "64923",
    text: "silver",
  },
  {
    code: "64924",
    text: "violet",
  },
  {
    code: "64925",
    text: "white",
  },
  {
    code: "64926",
    text: "yellow",
  },
  {
    code: "64928",
    text: "mixture containing generic product identifier ‘colouring agent’ (select all relevant colours)",
  },
];

export const MixtureCategoryEUPCSList = [
  {
    code: "64945",
    text: "F Mixtures for further formulation",
    description:
      "Includes mixtures ('original mixtures') intended to be formulated into other mixtures by formulators in industrial settings i.e. mixtures which have no intended end-use. For ‘final mixtures’ which have an intended end-use (i.e. not for further formulation) are categorised under ‘P – Products’.",
  },
  {
    code: "113196",
    text: "P Products",
    description:
      "Products (‘Final mixtures’) intended to be end-used by consumers, professional users outside of an industrial setting or by industrial users only. ‘End-use’ means the use a mixture, as a last step before the end-of-life of the mixture, namely before the mixture (or each of its components) is emitted to waste streams or the environment, is included into an article or is consumed in a process by reaction during use (including intermediate use).",
  },
  {
    code: "113197",
    text: "PC Chemical products (excludes biocidal products)",
    description: "Excludes all biocidal products.",
  },
  {
    code: "113198",
    text: "PC-ADH Adhesives and sealants",
    description:
      "An adhesive is a product capable of joining materials by surface bonding (adhesion), where the bond possesses adequate internal strength (cohesion). A sealant is an adhesive product applied to joints in order to fill gaps, mechanically block or protect from, for example, air and water.",
  },
  {
    code: "64877",
    text: "PC-ADH-1 Adhesives and sealants - household, office or school use",
    description:
      "Products for general use in the home, school, or office environment. Includes craft glues, multi-purpose glues and glue sticks, instant glues, wood glues, contact adhesives, adhesive sprays…",
  },
  {
    code: "64878",
    text: "PC-ADH-2 Adhesives and sealants - building and construction works (except cement based adhesives)",
    description:
      "Adhesives and sealants (gap fillers) for on-site and off-site building works such as new work, maintenance and renovations e.g. adhesives for wall coverings (including wall paper) and flooring (including carpet, PVS and linoleum, parquet) as well as civil engineering works (bridges, highway, railroad). For cement-based adhesives, see 'Mortars' in 'Construction products'.",
  },
];

export const MaterialCategoriesList = [
  {
    code: "66323",
    text: "clays / silicate ceramic",
    description: "ceramic > clays / silicate ceramic",
    open: false,
    parameterized: false,
  },
  {
    code: "66324",
    text: "clays",
    description: "ceramic > clays / silicate ceramic > clays",
    open: false,
    parameterized: false,
  },
  {
    code: "66325",
    text: "earthenware",
    description: "ceramic > clays / silicate ceramic > earthenware",
    open: false,
    parameterized: false,
  },
  {
    code: "66326",
    text: "porcelain or china",
    description: "ceramic > clays / silicate ceramic > porcelain or china",
    open: false,
    parameterized: false,
  },
  {
    code: "66327",
    text: "stoneware",
    description: "ceramic > clays / silicate ceramic > stoneware",
    open: false,
    parameterized: false,
  },
];
