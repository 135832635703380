import React, { FC, useEffect, useState } from "react";
import { listScipAnalytics } from "../../../../redux/actions/scipAction";
import { useAppDispatch } from "../../../../redux/hooks";
import { Card, Col, Row, Spin } from "antd";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

interface ChartProps {
  data: any[];
  chart: any;
  id: string;
}

const FushionChart: FC<ChartProps> = ({ data, chart, id }) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: "pie2d",
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: { data, chart: { ...chart } },
      }).render(`chart-${id}`);
    });
  }, [data, chart, id]);

  return <div id={`chart-${id}`} />;
};

const Summary: React.FC = () => {
  const dispatch = useAppDispatch();
  const [scipComponentsAnalytics, setScipComponentsAnalytics] = useState<any>();
  const [scipProductAnalytics, setScipProductAnalytics] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = () => {
    setLoading(true);
    dispatch(listScipAnalytics({}))
      .then((response: any) => {
        setScipComponentsAnalytics(response.scipComponentsAnalytics);
        setScipProductAnalytics(response?.scipProductAnalytics);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <Spin spinning={loading} fullscreen />
      <Row>
        <Col span={12} sm={12} md={12}>
          <Card title="Products SCIP Submission">
            <FushionChart
              data={scipProductAnalytics}
              chart={{
                showlabels: "1",
                theme: "fusion",
                showPercentValues: "0",
                showValues: "1",
                showLegend: "1",
                legendPosition: "right-bottom",
                pieRadius: "120",
              }}
              id="products"
            />
          </Card>
        </Col>
        <Col span={12} sm={12} md={12}>
          <Card title="Component Dataset">
            <FushionChart
              data={scipComponentsAnalytics}
              chart={{
                showlabels: "1",
                theme: "fusion",
                showPercentValues: "0",
                showValues: "1",
                showLegend: "1",
                legendPosition: "right-bottom",
                pieRadius: "120",
              }}
              id="components"
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Summary;
