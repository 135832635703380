import React, { useEffect, useState, useRef, createContext } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

import { Space, Tabs, Typography, Input } from "antd";
import type { TabsProps } from "antd";
import { getLocalStorage } from "../../../utils/localStore";
import ComponentCreate from "../../../components/modals/SCIP/CreateComponent";
import ProductCreate from "../../../components/modals/SCIP/CreateProduct";

import Summary from "./Summary";
import Components, { ComponentHandle } from "./Components";
import Products from "./Products";

import ErrorBoundary from "../../../utils/errorBoundary";
import ScipNotification from "../../../components/modals/SCIP/ScipNotification";

const { Title } = Typography;
const { Search } = Input;

export const ProductRefContext = createContext<{
  ref: React.RefObject<any>;
  setProducts: any;
}>({
  ref: { current: null },
  setProducts: [],
});

const Scip: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [tabItems, setTabItems] = useState<TabsProps["items"]>([]);
  const componentRef = useRef<ComponentHandle>(null);
  const productRef = useRef<any>(null);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const [products, setProducts] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string>("");

  const items = [
    {
      key: "",
      label: "Summary",
      children: <Summary />,
    },
    permissions?.read?.includes("components") && {
      key: "components",
      label: "Components",
      children: <Components ref={componentRef} />,
    },
    permissions?.read?.includes("products") && {
      key: "products",
      label: "Products",
      children: (
        <ProductRefContext.Provider value={{ ref: productRef, setProducts }}>
          <Products />
        </ProductRefContext.Provider>
      ),
    },
  ].filter(Boolean);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const search = searchParams.get("search") || "";
    setSearchValue(search);
  }, [searchParams]);

  useEffect(() => {
    setTabItems(items);
  }, []);

  const handleTabChange = (key: string) => {
    navigate(key);
    setSearchValue("");
  };

  const handleComponentChild = () => {
    if (componentRef.current) {
      componentRef.current.focusInput();
    }
  };

  const handleProductChild = () => {
    if (productRef.current) {
      productRef.current.focusInput();
    }
  };

  return (
    <ErrorBoundary>
      <Space
        align="end"
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <div>
          <Title level={5} style={{ marginBottom: 0 }}>
            SCIP (EU Waste Framework Directive)
          </Title>
        </div>
      </Space>
      <Tabs
        items={tabItems}
        activeKey={
          location.pathname.split("/").length === 3
            ? ""
            : location.pathname.split("/")[3]
        }
        onChange={handleTabChange}
        tabBarExtraContent={
          location.pathname.split("/").length === 4 && (
            <Space
              align="end"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
                marginTop: "1rem",
              }}
            >
              <Search
                placeholder="Search using number or name"
                allowClear
                onSearch={(search) => {
                  setSearchParams({ search: search });
                }}
                value={searchValue}
                onChange={handleInputChange}
                style={{ width: 350 }}
              />
              {location.pathname.split("/").pop() === "components" &&
                permissions?.create.includes("components") && (
                  <ComponentCreate fetchData={handleComponentChild} />
                )}
              {location.pathname.split("/").pop() === "products" &&
                products &&
                products.length > 0 && (
                  <ScipNotification
                    formData={products}
                    fetchData={handleProductChild}
                  />
                )}
              {location.pathname.split("/").pop() === "products" &&
                permissions?.create.includes("products") && (
                  <ProductCreate fetchData={handleProductChild} />
                )}
            </Space>
          )
        }
      />
    </ErrorBoundary>
  );
};

export default Scip;
