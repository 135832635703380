import React, { useState, useEffect } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../../redux/hooks";
import {
  listScipComponents,
  createScipProduct,
} from "../../../redux/actions/scipAction";

const CreateProductScip: React.FC<{
  fetchData: any;
}> = (props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [searchComponent, setSearchComponent] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [componentsLoading, setcomponentsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    dispatch(createScipProduct(values)).then(() => {
      props.fetchData();
      form.resetFields();
      setLoading(false);
      setIsModalOpen(false);
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleComponentSearch = (search?: any) => {
    setcomponentsLoading(true);
    dispatch(
      listScipComponents({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        searchField: search?.length ? search : "",
      })
    )
      .then((response: any) => {
        setSearchComponent(
          response.scipComponents?.map((e: any) => ({
            value: e.componentId ? e.componentId : e._id,
            ...e,
          }))
        );
        setcomponentsLoading(false);
      })
      .catch(() => setcomponentsLoading(false));
  };

  useEffect(() => {
    if (isModalOpen) {
      handleComponentSearch("");
    }
  }, [isModalOpen]);

  return (
    <>
      <Button
        type="primary"
        title="New Product"
        icon={<PlusOutlined />}
        onClick={showModal}
      >
        New Product
      </Button>
      <Modal
        title="Add Product"
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        maskClosable={false}
        footer={[
          <Button onClick={handleCancel} key="cancel">
            Cancel
          </Button>,
          <Button
            onClick={form.submit}
            type="primary"
            loading={loading}
            key="add"
          >
            Add
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="name"
                label="Product Name"
                rules={[
                  { required: true, message: "Please input Product Name!" },
                  {
                    pattern: /^([a-zA-Z0-9()]+\s?)*$/,
                    message: "Special Characters not allowed!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="number"
                label="Product Id"
                rules={[
                  { required: true, message: "Please input Product Number!" },
                  {
                    pattern: /^([a-zA-Z0-9()-/.,]+\s?)*$/,
                    message:
                      'AlphaNumeric Values with Special Characters like "- / , ( ) ." allowed!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Col span={24} sm={24} md={24}>
            <Form.Item
              name="componentRefs"
              label="Tag Components"
              rules={[
                {
                  required: true,
                  message: "Please select components!",
                },
              ]}
            >
              <Select
                options={searchComponent.map((option) => ({
                  ...option,
                  label: (
                    <>
                      <div>{option?.manufacturer?.name} </div>
                      <div> {option?.manufacturer?.itemNumber} </div>
                    </>
                  ),
                }))}
                onSearch={handleComponentSearch}
                placeholder="Component Reference"
                allowClear
                mode="multiple"
                showSearch
                loading={componentsLoading}
                filterOption={false}
              />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default CreateProductScip;
