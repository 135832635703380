import React, { FC, useEffect, useState } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Maps from "fusioncharts/fusioncharts.maps";
import World from "fusioncharts/maps/fusioncharts.world";
import ReactFC from "react-fusioncharts";
import {
  Card,
  Row,
  Col,
  Table,
  Progress,
  Button,
  Tooltip,
  Typography,
  Flex,
  Modal,
  Select,
  Form,
  Input,
  message,
} from "antd";
import {
  bulkWriteConflictMineralsDocuments,
  conflictAnalytics,
} from "../../../../redux/actions/conflictMineralsAction";
import { analyticsCMRT, consolidate } from "../../../../utils/types/summary";
import { consolidatedtable } from "../../../../redux/actions/conflictMineralsAction";
import { useNavigate } from "react-router-dom";
import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../../../../utils/localStore";
import type { ColumnsType } from "antd/es/table";
import { LinkOutlined } from "@ant-design/icons";
import { baseUrl } from "../../../../utils/apiHelper";
import axios from "axios";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  readTenantDetails,
  updateTenantDetails,
} from "../../../../redux/actions/tenantsAction";
import { Tenant } from "../../../../utils/types/tenant";
import ErrorBoundary from "../../../../utils/errorBoundary";
ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, Maps, World);
const { Text } = Typography;
interface ChartProps {
  data: any[];
  chartConfig: any;
  chartType: string;
  chartSpecificConfig?: any;
  colorRange?: any;
}

const Chart: FC<ChartProps> = ({
  data,
  chartConfig,
  chartType,
  chartSpecificConfig,
  colorRange,
}) => {
  useEffect(() => {
    FusionCharts.ready(function () {
      new FusionCharts({
        type: chartType,
        width: "100%",
        height: "400",
        dataFormat: "json",
        dataSource: {
          data,
          chart: { ...chartConfig, ...chartSpecificConfig },
          colorRange,
        },
      }).render(`chart-container-${chartType}`);
    });
  }, [data, chartConfig, chartType, chartSpecificConfig, colorRange]);

  return <div id={`chart-container-${chartType}`} />;
};
const { Option } = Select;

const ConflictSummary: FC = () => {
  const [cmrtLevelDocument, setcmrtLevelDocument] = useState<any[]>([]);
  const [smelterCountryDocument, setsmelterCountryDocument] = useState<any[]>(
    []
  );
  const [smelterRiskRanking, setsmelterRiskRanking] = useState<any[]>([]);
  const [supplierSmelterRisk, setsupplierSmelterRisk] = useState<any[]>([]);
  const [suppliers, setsuppliers] = useState<number>(0);
  const [inScopeSuppliers, setinScopeSuppliers] = useState<number>(0);
  const [supplierResponses, setsupplierResponses] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [ConsolidatedData, setConsolidatedData] = useState<any[]>([]);
  const [ConsolidatedCount, setConsolidatedCount] = useState<number>(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    sorter: {
      field: "createdAt",
      order: ["ascend"],
    },
  });

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchData();
    fetchTenantData();
    handleTableChange(
      { pageSize: 10, current: 1 },
      {},
      { field: tableParams.sorter.field, order: tableParams.sorter.order }
    );
    const reportingYear =
      new Date().getMonth() < 5
        ? new Date().getFullYear()
        : new Date().getFullYear() + 1;
    form.setFieldsValue({ cmrtVersions: reportingYear });
  }, [tableParams.sorter.field, tableParams.sorter.order, modalVisible]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response: analyticsCMRT = await conflictAnalytics({
        version: getLocalStorage("cmrtversion"),
      });
      if (response) {
        setcmrtLevelDocument(response.cmrtLevelDocument);
        setsmelterCountryDocument(response.smelterCountryDocument);
        setsmelterRiskRanking(response.smelterRiskRanking);
        setsupplierSmelterRisk(response.supplierSmelterRisk);
        setsuppliers(response.suppliers);
        setinScopeSuppliers(response.inScopeSuppliers);
        setsupplierResponses(response.supplierResponses);
      } else {
        console.error("Invalid or missing data in the API response.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const user = getLocalStorage("user");
  const handleTableChange = async (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    setLoading(true);
    try {
      if (user) {
        const parsedData = user;
        const tenantNumber = parsedData.tenantNumber;

        const response = await consolidatedtable(
          {
            sortBy: sorter?.field,
            sortDesc: sorter?.order === "descend",
            pageSize: pagination.pageSize,
            pageNum: pagination.current,
            filters: JSON.stringify({ softDelete: false }),
            version: getLocalStorage("cmrtversion"),
          },
          tenantNumber
        );
        if (response && response.data) {
          setConsolidatedData(response.data.conflictMineral);
          setConsolidatedCount(response.data.documentConflictMineralsLength);
        } else {
          console.error("Invalid response format:", response);
        }
        setTableParams({
          pagination: { ...pagination },
          sorter: sorter,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const columns: ColumnsType<consolidate> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      render: (text: string, record: consolidate) => (
        <Text>{record.createdAt && new Date(text).toLocaleString()}</Text>
      ),
    },
    {
      title: "Created By",
      dataIndex: "authorizer",
    },
    {
      title: "Effective Date",
      dataIndex: "effectiveDate",
      render: (text: string, record: consolidate) => (
        <Text>{record.effectiveDate && new Date(text).toLocaleString()}</Text>
      ),
    },
    {
      title: "Declaration Scope",
      dataIndex: "declarationScope",
    },
    {
      title: "Consolidated CMRT File",
      dataIndex: "filename",
      render: (text: string, record: consolidate) => {
        return (
          <>
            <Tooltip title={record?.filename}>
              {record?.filename && (
                <a onClick={() => viewFile(record?.filename)}>
                  <LinkOutlined /> {record?.filename}
                </a>
              )}
            </Tooltip>
          </>
        );
      },
    },
  ];
  const chartConfig = {
    theme: "fusion",
  };

  const pieChartConfig = {
    plottooltext: "<b>$percentValue</b> $label Level",
    showLegend: "1",
    showPercentValues: "1",
    legendPosition: "right",
    enableMultiSlicing: "0",
    pieRadius: "120",
    bgColor: "#F5F6FA",
    theme: "fusion",
  };

  const barChartConfig = {
    yaxisname: "Smelter",
    xaxisname: "Risk",
    legendPosition: "right",
    showPercentValues: "1",
    showBorder: "1",
    enableMultiSlicing: "0",
    bgColor: "#F5F6FA",
    usePlotGradientColor: "1",
    plotGradientColor: "#039BE5",
    showlegend: "1",
    showLabels: "1",
    theme: "fusion",
  };

  const max = Math.max(
    ...smelterCountryDocument.map((item) => parseFloat(item.value))
  );
  const min = Math.min(
    ...smelterCountryDocument.map((item) => parseFloat(item.value))
  );

  const worldMapConfig = {
    theme: "fusion",
    numbersuffix: "",
    includevalueinlabels: "1",
    labelsepchar: ": ",
    entityFillHoverColor: "#ACE8FF",
    showLegend: "0",
    bgColor: "#F5F6FA",
    colorRange: {
      minvalue: "0",
      code: "#07A3DF",
      gradient: "1",
      color: [
        {
          minvalue: min,
          maxvalue: (max / 3).toFixed(),
          color: "#0A82B0",
        },
        {
          minvalue: (max / 3).toFixed(),
          maxvalue: ((max / 3) * 2).toFixed(),
          color: "#07A3DF",
        },
        {
          minvalue: ((max / 3) * 2).toFixed(),
          maxvalue: max,
          color: "#045B7C",
        },
      ],
    },
    baseFontColor: "#FFFFFF",
    toolTipBgColor: "#444444",
  };

  const navigate = useNavigate();

  const viewFile = async (filename: string) => {
    if (filename.includes("xlsx")) {
      try {
        const response = await axios.post(
          baseUrl + "/conflictminerals/document/read/conflict-minerals",
          {
            filename: filename,
            foldername: "conflict-minerals",
          },
          {
            headers: {
              "x-service-call": "conflictminerals",
              Authorization: `Bearer ${getLocalStorage("accessToken")}`,
            },
            responseType: "arraybuffer",
          }
        );

        if (response && response.status === 200 && response.data) {
          const blobPDF = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });

          const blobURL = window.URL.createObjectURL(blobPDF);
          const fileLink = document.createElement("a");
          fileLink.href = blobURL;
          fileLink.download = filename;
          fileLink.click();
        } else {
          console.error("No response data received.");
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
      }
    }
  };

  const handleGetData = () => {
    removeLocalStorage("conflictMinerals");

    const user = getLocalStorage("user");
    if (user) {
      const parsedData = user;
      const tenantNumber = parsedData.tenantNumber;

      setLocalStorage("supplier", tenantNumber);
      navigate("/app/responsibleminerals/conflictminerals/generator", {
        replace: true,
      });
    }
  };

  const percent = Math.min(
    Math.ceil((inScopeSuppliers / suppliers) * 100),
    100
  );
  const responsePercent = (supplierResponses / suppliers) * 100;
  const handleCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [tenants, setTenants] = useState<Tenant>();

  const fetchTenantData = () => {
    setLoading(true);
    const user = getLocalStorage("user");
    const tenantNumber = user.tenantNumber;
    dispatch(readTenantDetails(tenantNumber))
      .then((response) => {
        setTenants(response.tenant);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const onFormSubmit = (values: any) => {
    const formattedValue = `Reporting Year ${values.cmrtVersions}`;
    setLoading(true);
    if (checkForDuplicate(formattedValue)) {
      message.error("Reporting Year already exist.");
      setLoading(false);
      setModalVisible(false);
      form.resetFields();
      return;
    }
    dispatch(
      updateTenantDetails({
        updates: {
          cmrtVersions: formattedValue,
        },
      })
    )
      .then(() => {
        setLocalStorage("cmrtversion", formattedValue);
        fetchTenantData();
        form.resetFields();
        dispatch(bulkWriteConflictMineralsDocuments({}))
          .then(() => {
            setModalVisible(false);
            setLoading(false);
            fetchData();
            handleTableChange(
              { pageSize: 10, current: 1 },
              {},
              {
                field: tableParams.sorter.field,
                order: tableParams.sorter.order,
              }
            );
          })
          .catch((e) => setLoading(false));
        setModalVisible(false);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const checkForDuplicate = (values: any) => {
    if (tenants?.cmrtVersions) {
      for (const item of tenants?.cmrtVersions || []) {
        if (values === item?.version) {
          const isDuplicate = true;
          return isDuplicate;
        }
      }
    }
  };
  return (
    <div style={{ overflow: "auto", height: "69vh" }}>
      <ErrorBoundary>
        <Row justify="end">
          <Flex gap={"small"} justify="space-between">
            <Col span={4}>
              <Button
                type="primary"
                onClick={handleGetData}
                data-testid="consolidate-cmrt-button"
                title="Consolidate CMRT"
              >
                Consolidated CMRT
              </Button>
            </Col>
          </Flex>
        </Row>
        {loading && <p>Loading...</p>}
        <Row>
          <Col span={5}>
            <div style={{ width: "70%" }}>
              <h4> Total Suppliers in scope </h4>
              <Progress
                percent={percent}
                strokeWidth={30}
                strokeColor={"#07A3DF"}
                strokeLinecap="square"
                format={(percent) => `${inScopeSuppliers} of ${suppliers}`}
              ></Progress>
            </div>
          </Col>
          <Col span={5}>
            <div style={{ width: "70%" }}>
              <h4>Total Response</h4>
              <Progress
                percent={responsePercent}
                strokeWidth={30}
                strokeColor={"orange"}
                strokeLinecap="square"
                format={(responsePercent) =>
                  `${supplierResponses} of ${suppliers}`
                }
              ></Progress>
            </div>
          </Col>
          <Col span={5}>
            <div style={{ width: "70%" }}>
              <h4>Response Rate</h4>
              <Progress
                percent={responsePercent}
                strokeWidth={30}
                strokeColor={"orange"}
                strokeLinecap="square"
                format={(responsePercent) => `${responsePercent?.toFixed(2)} %`}
              ></Progress>
            </div>
          </Col>
          <Col
            span={9}
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "25px",
            }}
          >
            <Select
              value={getLocalStorage("cmrtversion")}
              placeholder="Select Reporting Year"
              style={{ width: 190 }}
              onSelect={(value) => {
                if (value !== "add") {
                  setLocalStorage("cmrtversion", value);
                }
                fetchData();
                handleTableChange(
                  { pageSize: 10, current: 1 },
                  {},
                  {
                    field: tableParams.sorter.field,
                    order: tableParams.sorter.order,
                  }
                );
              }}
            >
              {tenants?.cmrtVersions?.map((option, index) => (
                <Option key={index} value={option?.version}>
                  {option?.version}
                </Option>
              ))}
              <Option value="add">
                <Button
                  type="primary"
                  ghost
                  style={{ padding: "5px" }}
                  onClick={() => setModalVisible(true)}
                >
                  Create Reporting Year
                </Button>
              </Option>
            </Select>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row gutter={24}>
          <Col sm={12} md={12}>
            <Card title="CMRT Declaration Scope">
              <Chart
                data={cmrtLevelDocument}
                chartConfig={chartConfig}
                chartType="pie2d"
                chartSpecificConfig={pieChartConfig}
              />{" "}
            </Card>
          </Col>
          <Col sm={12} md={12}>
            <Card title="Smelter Risk Ranking">
              <Chart
                data={smelterRiskRanking}
                chartConfig={chartConfig}
                chartType="bar2d"
                chartSpecificConfig={barChartConfig}
              />
            </Card>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <Row>
          <Col sm={24} md={24}>
            <Card title="Smelter Location Map">
              <Chart
                data={smelterCountryDocument}
                chartConfig={worldMapConfig}
                chartType="world"
                colorRange={worldMapConfig.colorRange}
              />{" "}
            </Card>
          </Col>
        </Row>
        <div>
          <Table
            columns={columns}
            loading={loading}
            rowKey="_id"
            dataSource={ConsolidatedData}
            pagination={{
              total: ConsolidatedCount,
              showTotal: (total: number, range: [number, number]) =>
                `${range[0]}-${range[1]} of ${total} items`,
              showSizeChanger: true,
            }}
          />
        </div>{" "}
        <Modal
          title="Create New Reporting Year"
          open={modalVisible}
          centered
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button
              key="continue"
              type="primary"
              loading={loading}
              onClick={form.submit}
            >
              Create
            </Button>,
          ]}
        >
          <Form form={form} onFinish={onFormSubmit}>
            <Form.Item name="cmrtVersions">
              <Input readOnly prefix="Reporting Year " />
            </Form.Item>
          </Form>
          <p>
            Once a new Reporting Year is created, all subsequently uploaded CMRT
            files will be associated with this Reporting Year.
          </p>
        </Modal>
      </ErrorBoundary>
    </div>
  );
};

export default ConflictSummary;
