import React, { useEffect, useState } from "react";
import { Col, Row, Tabs, Typography, Carousel } from "antd";
import Compliance1 from "../../../assets/supplier portal/compliance/1.png";
import Compliance2 from "../../../assets/supplier portal/compliance/2.png";
import Compliance3 from "../../../assets/supplier portal/compliance/3.png";
import Compliance4 from "../../../assets/supplier portal/compliance/4.png";
import Compliance5 from "../../../assets/supplier portal/compliance/5.png";
import Compliance6 from "../../../assets/supplier portal/compliance/6.png";
import Compliance7 from "../../../assets/supplier portal/compliance/7.png";
import Compliance8 from "../../../assets/supplier portal/compliance/8.png";
import Compliance9 from "../../../assets/supplier portal/compliance/9.png";
import Compliance10 from "../../../assets/supplier portal/compliance/10.png";

import ComplianceBulk1 from "../../../assets/supplier portal/compliance bulk/1.png";
import ComplianceBulk2 from "../../../assets/supplier portal/compliance bulk/2.png";
import ComplianceBulk3 from "../../../assets/supplier portal/compliance bulk/3.png";
import ComplianceBulk4 from "../../../assets/supplier portal/compliance bulk/4.png";
import ComplianceBulk5 from "../../../assets/supplier portal/compliance bulk/5.png";

import Material1 from "../../../assets/supplier portal/material/1.png";
import Material2 from "../../../assets/supplier portal/material/2.png";

import Conflict1 from "../../../assets/supplier portal/conflict/1.png";
import Conflict2 from "../../../assets/supplier portal/conflict/2.png";
import Conflict3 from "../../../assets/supplier portal/conflict/3.png";
import Conflict4 from "../../../assets/supplier portal/conflict/4.png";
import Conflict5 from "../../../assets/supplier portal/conflict/5.png";
import Conflict6 from "../../../assets/supplier portal/conflict/6.png";
import Conflict7 from "../../../assets/supplier portal/conflict/7.png";
import Conflict8 from "../../../assets/supplier portal/conflict/8.png";
import Conflict9 from "../../../assets/supplier portal/conflict/9.png";
import ComplianceGuide from "../../../assets/supplier portal/compliance-guide.png";
import FMD from "../../../assets/supplier portal/fmd-card-bg.png";
import Mineral from "../../../assets/supplier portal/cmrt-supplier-portal-guide.png";
import Support1 from "../../../assets/supplier portal/support/1.png";
import Support2 from "../../../assets/supplier portal/support/2.png";
import ErrorBoundary from "../../../utils/errorBoundary";
import Icon from "./../../../Icon";
const { Title, Text } = Typography;

interface ModalBannerProps {
  bannerBg: any;
  bannerVideo?: any;
  heading: string;
  desc: string;
  onClose: any;
}

const ModalBanner: React.FC<ModalBannerProps> = ({
  bannerBg,
  bannerVideo,
  heading,
  desc,
  onClose,
}) => {
  return (
    <div
      className="modalBanner"
      style={{ backgroundImage: `url(${bannerBg})` }}
    >
      <Row>
        <Col span={9} style={{ padding: "2em 0" }}>
          <div onClick={onClose} className="backBtn">
            <Icon name="back-arrow" />
          </div>
          <Title level={2}>{heading}</Title>
          <p style={{ marginTop: "1.5em" }}>{desc}</p>
        </Col>
        <Col span={15} style={{ textAlign: "right" }}>
          <iframe
            src={bannerVideo}
            title={heading}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </div>
  );
};

const instructions = (
  <>
    <Title level={5}>IMPORTANT:</Title>
    <br />
    <Carousel
      autoplay
      speed={800}
      adaptiveHeight
      className="instruction-slides"
      style={{ width: "80%", margin: "0 auto" }} // Center the carousel and adjust its width
    >
      <div className="slide-content">
        <h4>SUPPORT</h4>
        <img src={Support1} alt="Support" />
        <p>
          Ensure that all the required information is uploaded. If you encounter
          any issues, please don&#39;t hesitate to contact our support team via
          phone or email. We are here to assist you in completing this process.
        </p>
      </div>
      <div className="slide-content instruction-slide">
        <h4> DECLARATION TEMPLATES</h4>
        <img src={Support2} alt="Declaration Templates" />
        <p>
          You can find declaration templates for the regulations in the
          introduction tab, along with additional information about them. Should
          you have any queries, please don&#39;t hesitate to visit the support
          tab and reach out to us for assistance.
        </p>
      </div>
    </Carousel>
  </>
);
const items = [
  {
    key: "1",
    label: "Method 1: Part by Part Update",
    children: (
      <>
        <p style={{ marginBottom: "20px" }}>
          <i>
            <b> Tip : </b>
            If you have internally stored your compliance data product by
            product basis then this approach will be convenient for you. Click a
            line item and submit data together for all the requested regulation
            for that particular product/Part.
          </i>
        </p>
        <Carousel
          autoplay
          speed={800}
          adaptiveHeight
          style={{ width: "80%", margin: "0 auto" }} // Center the carousel and adjust its width
        >
          <div className="slide-content">
            <h4>STEP: 1</h4>
            <img src={Compliance1} alt="Step 1" />
            <ul>
              <li>
                Once you enter the supplier portal using the link received
                through email, you will find the list of part(s) you supply to
                your customer in &#39;Campaign&#39; page. Above snapshot is an
                example of how it looks
              </li>
            </ul>
          </div>

          <div className="slide-content">
            <h4>STEP: 2</h4>
            <img src={Compliance2} alt="Step 2" />
            <ul>
              <li>
                Choose the regulation you wish to update, then click the
                &#39;Edit&#39; button to modify the compliance status for each
                section, and attach the relevant compliance document as required
                for the specified regulations.
              </li>
            </ul>
          </div>

          <div className="slide-content">
            <h4>STEP: 3</h4>

            <img src={Compliance3} alt="Step 3" />
            <ul>
              <li>
                Once you have entered &#39;Edit Compliance&#39; page, please
                update the compliance details.
              </li>
            </ul>
          </div>

          <div className="slide-content">
            <h4>STEP: 4</h4>

            <img src={Compliance4} alt="Step 4" />
            <ul>
              <li>
                You can use the dropdown to make the selection and attach the
                compliance certificate in the compliance document field as shown
                above.
              </li>
            </ul>
          </div>
          <div className="slide-content">
            <h4>STEP: 5</h4>
            <img src={Compliance5} alt="Step 5" />
          </div>

          <div className="slide-content">
            <h4>STEP: 6</h4>
            <img src={Compliance6} alt="Step 6" />
          </div>

          <div className="slide-content">
            <h4>STEP: 7</h4>
            <img src={Compliance7} alt="Step 7" />
            <ul>
              <li>
                To complete the process, click on the &#39;SUBMIT&#39; button
                located in the bottom right corner.
              </li>
            </ul>
          </div>

          <div className="slide-content">
            <h4>STEP: 8</h4>
            <img src={Compliance8} alt="Step 8" />
            <ul>
              <li>
                The screenshot abpve serves as an example of the updated view,
                displaying the attached certificate and all the necessary
                information for RoHS or California Prop 65 or any other
                regulation .
              </li>
            </ul>
          </div>
          <div className="slide-content">
            <h4>STEP: 9</h4>
            <img src={Compliance9} alt="Step 9" />
            <ul>
              <li>
                After you have updated the compliance data for a regulation,
                please navigate to other regulations tab (EU REACH, CA Prop 65 &
                TSCA 8(a)(7) PFAS) as shown above and update the information in
                the similar way.
              </li>
            </ul>
          </div>
          <div className="slide-content">
            <h4>STEP: 10</h4>
            <img src={Compliance10} alt="Step 10" />
          </div>
        </Carousel>
        <br />
      </>
    ),
  },
  {
    key: "2",
    label: "Method 2: Multiple Parts Update (Bulk Update)",
    children: (
      <>
        <p style={{ marginBottom: "20px" }}>
          <i>
            <b> Tip : </b>
            If you have internally structured your compliance data separately
            for each regulation then this approach will be convenient for you.
            Navigate to the requested compliance tab. You can select multiple
            products/parts for which you wish to submit compliance status
            together with a common compliance document.
          </i>
        </p>
        <Carousel
          autoplay
          speed={800}
          adaptiveHeight
          style={{ width: "80%", margin: "0 auto" }} // Center the carousel and adjust its width
        >
          <div className="slide-content">
            <h4>STEP: 1</h4>
            <img src={ComplianceBulk1} alt="Step 1" />
            <ul>
              <li>
                Above are few examples with screenshots on how to update
                regulations for multiple products/parts in bulk.
              </li>
              <li>
                To update compliance information in bulk, first you need to
                navigate to the respective regulation tab. Please note that only
                the regulations requested by your customer in a particular
                campaign will be displayed to you as a tab.
              </li>
              <li>
                When you navigate to a particular regulation tab, use the
                checklist box to select multiple parts for which you want to
                update the compliance data in bulk and also upload the common
                compliance document.
              </li>
            </ul>
          </div>
          <div className="slide-content">
            <h4>STEP: 2</h4>
            <img src={ComplianceBulk2} alt="Step 1" />
            <ul>
              <li>
                Now click on &#39;Submit&#39; and provide the compliance
                information with certificate in bulk for all the parts selected.
                In this case I have selected all the parts.
              </li>
            </ul>
          </div>
          <div className="slide-content">
            <h4>STEP: 3</h4>
            <img src={ComplianceBulk3} alt="Step 1" />
          </div>
          <div className="slide-content">
            <h4>STEP: 4</h4>
            <img src={ComplianceBulk4} alt="Step 1" />
          </div>
          <div className="slide-content">
            <h4>STEP: 5</h4>
            <img src={ComplianceBulk5} alt="Step 1" />
            <ul>
              <li>
                Now navigate to other requested regulation tab to update
                compliance information at bulk as shown above for TSCA 8(a)(7)
                PFAS
              </li>
              <li>
                Click on &#39;Submit&#39; once you have entered the data for all
                required fields.
              </li>
              <li>
                Similarly, above is the screenshot on how to update bulk
                information for regulations like PFAS, CA Prop 65 & TSCA PBT for
                your quick reference
              </li>
            </ul>
          </div>
        </Carousel>
        <br />
      </>
    ),
  },
];

const InstructionModal: React.FC<{
  selected: any;
  campaignData?: any;
  setSelected: any;
}> = (props) => {
  const onClose = () => {
    props?.setSelected(false);
  };

  return (
    <div>
      <ErrorBoundary>
        <div className="supplier-portal-drawer">
          {props?.selected === "regulations" && (
            <>
              <ModalBanner
                heading="Update Compliance Guide"
                desc="Follow few steps to update Compliance !"
                bannerBg={ComplianceGuide}
                onClose={onClose}
                bannerVideo="https://www.youtube.com/embed/UNYz9tU8UQ4?si=EOrbSygknHroxrE_"
              />
              <div className="modal-body">
                <Title level={5}>
                  How to submit Compliance information for Restricted Substance
                  Regulations (such as EU RoHS, EU REACH, CA PROP65, TSCA PBT,
                  POP & so on) using supplier portal?
                </Title>
                <Tabs defaultActiveKey="1" items={items} tabPosition="top" />
                <br />
                <br />
                {instructions}
              </div>
            </>
          )}
          {props?.selected === "materials" && (
            <>
              <ModalBanner
                heading="Update Full Material Declaration in Supplier Portal"
                desc="Follow few steps to update Full Material Declaration !"
                bannerBg={FMD}
                onClose={onClose}
                bannerVideo="https://www.youtube.com/embed/f2j9RN3MTVY?si=SLS4URE9EDRgsNKC"
              />
              <div className="modal-body">
                <Title level={5}>
                  How To Update the Full Material Declaration in Supplier
                  Portal?
                </Title>
                <br />
                <Carousel
                  autoplay
                  speed={800}
                  adaptiveHeight
                  style={{ width: "80%", margin: "0 auto" }} // Center the carousel and adjust its width
                >
                  <div className="slide-content">
                    <h4>STEP: 1</h4>
                    <img src={Material1} alt="" />
                    <ul>
                      <li>
                        Once you enter the supplier portal using the link
                        received through email, navigate to Material Disclosure
                        tab as shown in the screenshot above to find the part(s)
                        you supply to your customer
                      </li>
                    </ul>
                  </div>
                  <div className="slide-content">
                    <h4>STEP: 2</h4>
                    <img src={Material2} alt="" />
                    <ul>
                      <li>
                        Click on the edit (Pencil button) to update Material
                        Disclosure (FMD) information for a part.
                      </li>
                      <li>
                        Once the required fields are updated and Full material
                        declaration document is attached (preferred FMD Format:
                        IPC 1752A Class D XML file) please click on
                        &#39;SUBMIT&#39; to save.
                      </li>
                    </ul>
                  </div>
                </Carousel>
              </div>
              <br />
              <br />
              {instructions}
            </>
          )}
          {props?.selected === "cmrt" &&
            (props?.campaignData?.conflict === "Yes" ||
              props?.campaignData?.extended === "Yes") && (
              <>
                <ModalBanner
                  heading={`Update ${
                    props?.campaignData &&
                    props?.campaignData?.conflict === "Yes"
                      ? " Conflict Minerals (CMRT file)"
                      : ""
                  }
                  ${
                    props?.campaignData &&
                    props?.campaignData?.conflict === "Yes" &&
                    props?.campaignData?.extended === "Yes"
                      ? "and"
                      : ""
                  }
                  ${
                    props?.campaignData &&
                    props?.campaignData?.extended === "Yes"
                      ? " Extended Minerals (EMRT)"
                      : ""
                  }
                  in Supplier Portal`}
                  desc="Follow few steps to update CMRT and EMRT !"
                  bannerBg={Mineral}
                  onClose={onClose}
                  bannerVideo="https://www.youtube.com/embed/mY6yFQeIYhY?si=x4lQNUpkXdVK8hyi"
                />
                <div className="modal-body">
                  <Title level={5}>
                    How To Update
                    {props?.campaignData &&
                    props?.campaignData?.conflict === "Yes"
                      ? " Conflict Minerals (CMRT file)"
                      : ""}
                    {props?.campaignData &&
                    props?.campaignData?.conflict === "Yes" &&
                    props?.campaignData?.extended === "Yes"
                      ? "and"
                      : ""}
                    {props?.campaignData &&
                    props?.campaignData?.extended === "Yes"
                      ? " Extended Minerals (EMRT)"
                      : ""}
                    in Supplier Portal
                  </Title>
                  <br />
                  <Carousel
                    autoplay
                    speed={800}
                    adaptiveHeight
                    style={{ width: "80%", margin: "0 auto" }} // Center the carousel and adjust its width
                  >
                    <div className="slide-content">
                      <h4>STEP: 1</h4>
                      <img
                        src={Conflict1}
                        alt=""
                        style={{ marginTop: "1rem", marginBottom: "1rem" }}
                      />
                      <ul>
                        <li>
                          After accessing the Supplier Portal through the email
                          link you received, click on either the &#39;Conflict
                          Minerals&#39; or &#39;Extended Minerals&#39; tab,
                          depending on what you intend to upload.
                        </li>
                        <li>
                          Select &#39;IMPORT&#39; to upload the most recent
                          reporting template file, as demonstrated above for
                          your convenience.{" "}
                        </li>
                        <li>
                          This example illustrates how to upload a CMRT, and the
                          process will be similar for Extended Minerals as well.
                        </li>
                      </ul>
                    </div>
                    <div className="slide-content">
                      <h4>STEP: 2</h4>

                      <img
                        src={Conflict2}
                        alt=""
                        style={{ marginTop: "1rem" }}
                      />
                      <ul>
                        <li>
                          After the CMRT file is successfully imported, tool
                          will capture the information automatically from your
                          file and you will see a message “Submit & Close”.
                          <li>
                            Click &#39;Submit & Close&#39; OR if you wish to
                            review the data before submitting, click on the tabs
                            as shown above.
                          </li>
                          <li>
                            Above screenshots are examples for your easy
                            reference.
                          </li>
                        </li>
                      </ul>
                    </div>
                    <div className="slide-content">
                      <h4>STEP: 3</h4>

                      <img src={Conflict3} alt="" />
                    </div>
                    <div className="slide-content">
                      <h4>STEP: 4</h4>

                      <img src={Conflict4} alt="" />
                    </div>
                    <div className="slide-content">
                      <h4>STEP: 5</h4>

                      <img src={Conflict5} alt="" />
                    </div>
                    <div className="slide-content">
                      <h4>STEP: 6</h4>

                      <img src={Conflict6} alt="" />
                    </div>
                    <div className="slide-content">
                      <h4>STEP: 7</h4>

                      <img src={Conflict7} alt="" />
                    </div>
                    <div className="slide-content">
                      <h4>STEP: 8</h4>

                      <img
                        src={Conflict8}
                        alt=""
                        style={{ marginBottom: "1rem" }}
                      />
                      <ul>
                        <li>
                          Once you have verified the CMRT data click on
                          &#39;Submit & Close&#39;.
                        </li>
                      </ul>
                    </div>
                    <div className="slide-content">
                      <h4>STEP: 9</h4>

                      <img
                        src={Conflict9}
                        alt=""
                        style={{ marginTop: "1rem" }}
                      />
                    </div>
                  </Carousel>
                </div>
                <br />
                <br />
                {instructions}
              </>
            )}
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default InstructionModal;
