import React from "react";
import { Card, Progress, Button, Space, Typography, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import regulations from "../../../utils/constants/regulations";

import { useCampaignAnalytics } from "..";
import { DownloadOutlined, EyeOutlined } from "@ant-design/icons";
import axios from "axios";
import { baseUrl } from "../../../utils/apiHelper";
import ErrorBoundary from "../../../utils/errorBoundary";

const Introduction: React.FC = () => {
  const { campaignAnalytics } = useCampaignAnalytics();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState("");

  const DownloadTemplate = async (name: string, filename: string) => {
    setLoading(name);
    try {
      const response = await axios.post(
        baseUrl + "/public/declaration/read",
        {
          filename: filename,
        },
        {
          responseType: "arraybuffer",
        }
      );
      if (response && response.status === 200 && response.data) {
        // const extention = {
        //   "docx" : "application/octet-stream",
        // }
        const blobPDF = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const blobURL = window.URL.createObjectURL(blobPDF);
        const fileLink = document.createElement("a");
        fileLink.href = blobURL;
        fileLink.download = filename;
        fileLink.click();
        setLoading("");
      } else {
        console.error("No response data received.");
        setLoading("");
      }
    } catch (error) {
      console.error("Error fetching file data:", error);
      setLoading("");
    }
  };

  return (
    <div
      style={{
        columns: 2,
        gap: "1rem",
      }}
    >
      <ErrorBoundary>
        {campaignAnalytics.length &&
          campaignAnalytics.map(
            (e: {
              name: string;
              displayName: string;
              responses: number;
              totalComponents: number;
            }) => {
              const regulation =
                regulations[e.name as keyof typeof regulations];
              return (
                regulation && (
                  <Card
                    key={e.name}
                    title={e.displayName}
                    extra={
                      <Space align="center" size="small">
                        <Typography.Text style={{ color: "white" }}>
                          Progress
                        </Typography.Text>
                        <Progress
                          percent={(e.responses / e.totalComponents) * 100}
                          size={[150, 15]}
                          showInfo={false}
                          style={{
                            marginTop: "10px",
                          }}
                        />
                        <Typography.Text style={{ color: "white" }}>
                          {e.responses}/{e.totalComponents}
                        </Typography.Text>
                      </Space>
                    }
                    actions={[
                      e.name !== "scip" && (
                        <Button
                          key="1"
                          icon={<DownloadOutlined />}
                          style={{ backgroundColor: "rgb(217, 217, 217, 0.6)" }}
                          type="text"
                          onClick={() =>
                            DownloadTemplate(e.name, regulation.filename)
                          }
                          loading={loading === e.name}
                        >
                          Declaration Template
                        </Button>
                      ),
                      <Button
                        key="2"
                        icon={<EyeOutlined />}
                        type="primary"
                        onClick={() => navigate("../campaign/" + e.name)}
                      >
                        View Request
                      </Button>,
                    ]}
                    style={{
                      breakInside: "avoid",
                      marginBottom: "1rem",
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: regulation.description,
                      }}
                    ></span>
                    <Alert
                      message={
                        regulation.name === "material disclosure"
                          ? "Please provide us with the accurate Full Material Disclosure(FMD) data preferably in IPC 1752A Class D format. You can get started from here."
                          : "Please provide us with the accurate compliance data for " +
                            e.displayName +
                            ". You can get started from here."
                      }
                      type="warning"
                      style={{ marginTop: "1rem" }}
                    />
                  </Card>
                )
              );
            }
          )}
      </ErrorBoundary>
    </div>
  );
};

export default Introduction;
