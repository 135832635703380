import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FilterOutlined,
  DeleteOutlined,
  EyeOutlined,
  ExperimentOutlined,
  ExportOutlined,
  FlagFilled,
  CodeSandboxOutlined,
  CommentOutlined,
  SettingOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Icon from "./../../../../Icon";
import {
  Spin,
  Space,
  Typography,
  Button,
  Table,
  Input,
  Tabs,
  Tag,
  Col,
  Row,
  Card,
  Tooltip,
  Empty,
  Avatar,
  Pagination,
  List,
  Divider,
  Form,
  Select,
  DatePicker,
} from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import FileUploader from "../../../../components/layouts/FileUploader";

import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import { Product } from "../../../../utils/types/product";
import { Component } from "../../../../utils/types/component";
import { useAppDispatch } from "../../../../redux/hooks";
import { updateProduct } from "../../../../redux/actions/productsAction";
import { readProduct } from "../../../../redux/actions/productsAction";
import { listComponentFilters } from "../../../../redux/actions/componentsAction";
import { Regulation } from "../../../../utils/types/regulation";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";
import ProgressChart from "./ProgressChart";
import { listUsers } from "../../../../redux/actions/usersAction";
import ComponentForm from "../../../../components/forms/ComponentForm";
import type { MenuProps, SelectProps } from "antd";
import ComponentEditForm from "../../../../components/modals/editComponentModal";
import ComplianceForm from "../../../../components/forms/ComplianceForm";
import BulkEditSpecifications from "../../../../components/modals/BulkEditSpecifications";
import ReadSupportingDocument from "../../../../components/modals/ReadSupportingDocuments";
import ImportDataForm from "../../../../components/forms/ImportDataForm";
import EditMaterialDisclosureComponents from "../../../../components/modals/editMaterialDisclosureComponent";
import ErrorBoundary from "../../../../utils/errorBoundary";
import productIcon from "../../../../assets/side-nav-icons/products-profile-icon.png";
import "../../../../index.css";
import { User } from "../../../../utils/types/user";
import dayjs from "dayjs";

const { Search } = Input;
const { Title, Text } = Typography;

function getLinearGradient(hexColor: string) {
  let hex = hexColor?.replace(/^#/, "");
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }
  const num = parseInt(hex, 16);
  const r = (num >> 16) & 255;
  const g = (num >> 8) & 255;
  const b = num & 255;

  const fromColor = r + 50 + "," + (g + 50) + "," + (b + 50);
  const color2 = r + 10 + "," + (g + 10) + "," + (b + 10);
  const toColor = r - 50 + "," + (g - 50) + "," + (b - 50);
  return `linear-gradient(90deg, rgba(${fromColor},1) 0%, rgba(${color2}, 1) 40%, rgba(${toColor}, 1) 100%)`;
}

const ProductProfile: React.FC<{
  productData?: any;
  onPreviewClick: any;
}> = ({ productData, onPreviewClick }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { Meta } = Card;
  const { Option } = Select;
  const [search, setSearch] = useState<any>();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>("overview");
  const [components, setComponents] = useState<Component[]>([]);
  const [product, setProduct] = useState<any | null>(null);
  const [componentCount, setComponentCount] = useState<number>(0);
  const [isHideDetails, setDetailsVisible] = useState(true);
  const [showEditBtn, setShowEditBtn] = useState(false);

  const regulations = getRegulationsFromLocalStorage() ?? [];
  const [selectedComponents, setSelectedComponents] = useState<{
    key: string;
    value: any;
  } | null>(null);
  const onSearch = (value: any) => {
    setSearch({
      searchField: true,
      query:
        value?.key === "Enter" ? value?.target?.value.trim() : value.trim(),
    });
    setTablePagination({
      pageSize: tablePagination.pageSize,
      current: 1,
    });
  };
  const { tenantNumber } = getLocalStorage("user");
  const [isUploading, setIsUploading] = useState(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [usersOptions, setUsersOptions] = useState<any>([]);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [filterData, setFilterData] = useState<any>({});
  const productSpecifications = getLocalStorage("user").productSpecifications;

  const user = getLocalStorage("user");
  const parseUser = user ? user : null;
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  interface componentProfileParams {
    id: string;
  }

  const fetchFilterData = (
    columnName: string,
    columnSearch?: string,
    filterValue?: any
  ) => {
    setLoadingData(true);
    dispatch(
      listComponentFilters({
        column: columnName,
        value: columnSearch ?? "",
        filter: JSON.stringify(filterValue),
        productId: productData.key,
      })
    ).then((response: any) => {
      setFilterData((prev: any) => ({ ...prev, [columnName]: response }));
      setLoadingData(false);
    });
  };

  const handleHideDetails = () => {
    setDetailsVisible(!isHideDetails);
  };

  const onSelectComponents = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Component[]
  ) => {
    setSelectedComponents({
      key: activeTab,
      value: selectedRows,
    });
  };

  const componentSelection: TableRowSelection<Component> = {
    selectedRowKeys:
      selectedComponents && selectedComponents.value
        ? selectedComponents?.value?.map((e: any) =>
            e.componentId ? e.componentId : e._id
          )
        : [],
    onChange: onSelectComponents,
    preserveSelectedRowKeys: true,
  };

  const handleTabChange = (key: any) => {
    setActiveTab(key);
    setSelectedComponents(null);
  };

  const onClickViewLink = (filename: string) => {
    window.open(filename, "_blank");
  };

  const handlePreviewClick = (record: any) => {
    const params: componentProfileParams = {
      id: record?.componentId ? record?.componentId : record._id,
    };
    const route = `/components/${params.id}`;
    const currentRoute = window.location.pathname;
    const basePath = currentRoute.split("/").slice(0, 2).join("/");
    const finalPath = basePath + route;
    navigate(finalPath);
  };

  const handleSubAssembliesClick = (record: any) => {
    const values = {
      productNumber: productData.number,
      type: "SubAssemblies",
      key: record._id,
      manufacturerItemNumber: record.manufacturer.itemNumber,
      manufacturerName: record.manufacturer.name,
      bomLevel: "2",
    };
    onPreviewClick(values);
  };

  const componentsList = components?.map((item) => {
    const itemValue: Component = { ...item, componentId: item._id };

    regulations?.forEach((e: Regulation) => {
      const compliance = item.compliances?.find(
        (o) => o.regulationNumber === e.regulationNumber
      );
      itemValue[e.regulationNumber] = compliance
        ? {
            ...compliance,
            internalItemNumber: item.internalItemNumber,
            manufacturer: item.manufacturer,
            componentId: item._id,
            components: item.components,
          }
        : {
            internalItemNumber: item.internalItemNumber,
            manufacturer: item.manufacturer,
            componentId: item._id,
            components: item.components,
          };
    });

    return itemValue;
  });

  const menu = (value: any): MenuProps => ({
    items: [
      {
        key: "Delete",
        onClick: () => handlePreviewClick(value),
        label: "Delete",
        icon: <DeleteOutlined />,
      },
      {
        key: "Preview",
        onClick: () => handlePreviewClick(value),
        label: "Preview",
        icon: <EyeOutlined />,
      },
    ],
  });

  const columns: ColumnsType<Component> = [
    {
      title: "Action",
      key: "operation",
      width: 130,
      render: (text: any, record: any) => (
        <Space size={0}>
          {record.components > 0 && (
            <Button
              type="text"
              icon={
                <Icon
                  name="plus-circle"
                  style={{ color: "#1890FF", fontSize: 20 }}
                />
              }
              onClick={() => handleSubAssembliesClick(record)}
              data-testid="subAssemblies-component-button"
              title="Sub Assemblies"
            />
          )}
          {permissions?.update?.includes("components") && (
            <ComponentEditForm
              formData={record}
              fetchData={fetchData}
              activeTab="all"
            />
          )}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-component-button"
            title="Preview"
          />
          {/* <Dropdown menu={menu(record)}>
            <Space>
              <Button type="text" icon={<MoreOutlined />} />
            </Space>
          </Dropdown> */}
        </Space>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("internalItemNumber"),
      filters: filterData?.internalItemNumber || [],
      filteredValue: tableFilters.internalItemNumber || null,
      filterSearch: true,
      render: (_: any, record: any) => (
        <Text className={record.status === "Inactive" ? "inactive-text" : ""}>
          {record.internalItemNumber}
        </Text>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer.name",
      key: "manufacturer.name",
      sorter: true,
      render: (_: any, record: any) => (
        <Text className={record.status === "Inactive" ? "inactive-text" : ""}>
          {record.manufacturer.itemNumber}
          <br></br>
          <small>{record.manufacturer.name}</small>
        </Text>
      ),
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("manufacturer.name"),
      filters: filterData["manufacturer.name"] || [],
      filteredValue: tableFilters["manufacturer.name"] || null,
      filterSearch: true,
    },
    ...(parseUser?.specifications || [])
      .filter((o: any) => !o.hidden)
      .map((e: any) => ({
        title: e.name.charAt(0).toUpperCase() + e.name.slice(1),
        dataIndex: `specification.${e.value}`,
        key: `specification.${e.value}`,
        sorter: true,
        render(_: any, record: any) {
          if (e.dataType === "Dropdown") {
            if (
              record.specification &&
              record.specification[e.value]?.toUpperCase().trim() === "ACTIVE"
            ) {
              return <Tag color="green">{record.specification[e.value]}</Tag>;
            } else if (
              record.specification &&
              record.specification[e.value]?.toUpperCase().trim() === "NRND"
            ) {
              return <Tag color="orange">{record.specification[e.value]}</Tag>;
            } else if (
              record.specification &&
              record.specification[e.value]?.toUpperCase().trim() === "UNKNOWN"
            ) {
              return <Tag color="grey">{record.specification[e.value]}</Tag>;
            } else if (record.specification && record.specification[e.value]) {
              return record.specification && record.specification[e.value] ? (
                <Tag color="red">{record.specification[e.value]}</Tag>
              ) : (
                ""
              );
            } else {
              return record.specification && record.specification[e.value] ? (
                <Tag color="default">{record.specification[e.value]}</Tag>
              ) : (
                ""
              );
            }
          } else if (e.dataType === "Fileinput") {
            return (
              <>
                {record?.specification &&
                record?.specification[e.value]?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(record?.specification[e.value])
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="products/components/document/download"
                    filename={
                      record?.specification && record?.specification[e.value]
                    }
                    foldername="components"
                  />
                )}
              </>
            );
          } else if (e.dataType === "Dateinput") {
            return record?.specification && record?.specification[e.value]
              ? new Date(record?.specification[e.value])?.toLocaleDateString()
              : "";
          } else {
            return (
              (record.specification && record.specification[e.value]) || ""
            );
          }
        },
        onFilterDropdownOpenChange: (open: any) =>
          open && fetchFilterData(`specification.${e.value}`),
        filters: e.filter ? filterData[`specification.${e.value}`] || [] : null,
        filteredValue: tableFilters[`specification.${e.value}`] || null,
        filterSearch: true,
      })),
    permissions?.read.includes("materials") && {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      render: (text: string) => {
        return text ? (
          text?.includes("http") ? (
            <span>
              <ExportOutlined onClick={() => onClickViewLink(text)} /> Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="materials/document/download"
              filename={text}
              foldername="materials"
            />
          )
        ) : null;
      },
    },
    permissions?.read.includes("materials") && {
      title: "Part Weight in mg",
      dataIndex: "partWeight",
      key: "partWeight",
    },
    ...(regulations || []).map((e: Regulation) => ({
      title: e.name,
      children: e.configuration
        .filter(
          (o) =>
            !o.hidden &&
            o.value !== "manufacturer" &&
            o.value !== "internalItemNumber" &&
            o.value !== "operations"
        )
        .map((k: any) => ({
          title: k.text,
          dataIndex: `${e.regulationNumber}.${k.value}`,
          key: `${e.regulationNumber}.${k.value}`,
          render: (value: any, record: any) => {
            const compliances = record.compliances.find(
              (item: any) => item.regulationNumber === e.regulationNumber
            );
            if (k.value === "complianceDocument") {
              return compliances && compliances.complianceDocument ? (
                compliances?.complianceDocument?.includes("http") ? (
                  <span>
                    <ExportOutlined
                      onClick={() =>
                        onClickViewLink(compliances?.complianceDocument)
                      }
                    />{" "}
                    Link
                  </span>
                ) : (
                  <ReadSupportingDocument
                    postUrl="compliances/document/download"
                    filename={compliances.complianceDocument}
                    foldername="compliances"
                  />
                )
              ) : null;
            } else if (k.value.includes("complianceOutputs.compliant")) {
              if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() ===
                  "YES"
              ) {
                return <Tag color="green"> YES </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() === "NO"
              ) {
                return <Tag color="red"> NO </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs.compliant?.toUpperCase() ===
                  "YES WITH EXEMPTION"
              ) {
                return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
              } else if (
                compliances &&
                compliances.complianceOutputs &&
                compliances.complianceOutputs?.compliant?.toUpperCase() ===
                  "UNKNOWN"
              ) {
                return <Tag color="default"> UNKNOWN </Tag>;
              } else {
                return compliances && compliances.complianceOutputs ? (
                  <Tag color="grey">
                    {compliances &&
                      compliances.complianceOutputs &&
                      compliances.complianceOutputs.compliant}
                  </Tag>
                ) : (
                  ""
                );
              }
            } else if (k.value.includes("complianceOutputs.version")) {
              return (
                <Text
                  className={
                    record.status === "Inactive" ? "inactive-text" : ""
                  }
                >{`${
                  compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.version
                    ? compliances.complianceOutputs.version
                    : ""
                }`}</Text>
              );
            } else if (k.value.includes("complianceOutputs.exemption")) {
              return (
                <Text
                  style={{ width: 200 }}
                  className={
                    record.status === "Inactive" ? "inactive-text" : ""
                  }
                  ellipsis={{
                    tooltip: compliances?.complianceOutputs?.exemption,
                  }}
                >
                  {compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.exemption
                    ? compliances.complianceOutputs.exemption
                    : ""}
                </Text>
              );
            } else if (k.value.includes("complianceOutputs.substance")) {
              return (
                <Text
                  className={
                    record.status === "Inactive" ? "inactive-text" : ""
                  }
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip: record?.complianceOutputs?.substance,
                  }}
                >
                  {compliances &&
                  compliances.complianceOutputs &&
                  compliances.complianceOutputs.substance
                    ? compliances.complianceOutputs.substance
                    : ""}
                </Text>
              );
            } else {
              return (
                <Text
                  className={
                    record.status === "Inactive" ? "inactive-text" : ""
                  }
                >
                  {compliances && compliances?.complianceOutputs
                    ? compliances?.complianceOutputs[
                        k.value?.split(".").pop().toString()
                      ]
                    : ""}
                </Text>
              );
            }
          },
          onFilterDropdownOpenChange: (open: any) =>
            open &&
            fetchFilterData(`compliances.${k.value}`, "", {
              "compliances.regulationNumber": e.regulationNumber,
            }),
          filters: k.filter ? filterData[`compliances.${k.value}`] || [] : null,
          filteredValue:
            tableFilters[`${e.regulationNumber}.${k.value}`] || null,
        })),
    })),
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text className={record.status === "Inactive" ? "inactive-text" : ""}>
          {new Date(text).toLocaleString()}
        </Text>
      ),
    },
  ].filter(Boolean);

  const getRegulation = (componentId: string) => {
    const data =
      activeTab === "materials"
        ? componentsList.filter(
            (val: { _id: string }) => val._id === componentId
          )[0]
        : componentsList.filter((val) => val._id === componentId)[0];
    const materials = data?.materials;
    const extractRegulation: any[] = materials
      ?.flatMap((val: { substances: any[] }) =>
        val.substances?.map(
          (ele: any) =>
            ele?.compliantalert === "Yes" && ele.compliantalertRegulation
        )
      )
      .filter((val: any) => val !== false)
      .flat();

    const regulationData = extractRegulation?.filter((item, index, array) => {
      return array.indexOf(item) === index;
    });

    const regulationName = regulations
      .filter((v: any) => regulationData?.includes(v.regulationNumber))
      .map((e: any) => e.name);
    return (
      activeTab === "materials" &&
      renderRegulationList(regulationName, componentId)
    );
  };

  const getSubstance = (val: any, regulationNumber: string) => {
    const combine = val
      ?.flatMap((element: any) =>
        element.substances.map((val: any) =>
          val?.compliantalert?.toLowerCase() === "yes" &&
          val?.compliantalertRegulation === regulationNumber
            ? val.name.substance
              ? val.name.substance
              : val.name
            : ""
        )
      )
      .filter((val: any) => val !== false);
    const substances = combine
      .filter((item: any, index: any, array: any) => {
        return array.indexOf(item) === index;
      })
      .filter((v: any) => v && v);
    return substances.join(",");
  };

  const getSubstancesForCompliance = (
    record: any,
    regulationNumber: string
  ) => {
    const data = componentsList.filter(
      (element) => element._id === record.componentId
    )[0];
    return data.materials &&
      data.materials.length > 0 &&
      data.materials.find((record) =>
        record.substances?.find(
          (ele: any) =>
            ele !== undefined && ele?.compliantalert?.toLowerCase() === "yes"
        )
      )
      ? getSubstance(data.materials, regulationNumber)
      : "";
  };

  const fmdHeaders = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (text: any, record: any) => (
        <Space>
          {permissions?.update?.includes("components") && (
            <ComponentEditForm
              formData={record}
              fetchData={fetchData}
              activeTab="materials"
            />
          )}
          {permissions?.update?.includes("components") && (
            <ImportDataForm
              type="create"
              fetchData={fetchData}
              xmlImport={true}
            />
          )}
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => handlePreviewClick(record)}
            data-testid="preview-component-button"
            title="Preview"
          />
        </Space>
      ),
    },
    {
      title: "Internal #",
      dataIndex: "internalItemNumber",
      key: "internalItemNumber",
      sorter: true,
      width: 150,
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("internalItemNumber"),
      filters: filterData?.internalItemNumber || [],
      filteredValue: tableFilters.internalItemNumber || null,
      filterSearch: true,
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer.name",
      key: "manufacturer.name",
      width: 200,
      sorter: true,
      render: (_: any, record: any) => (
        <Text>
          {record.manufacturer.itemNumber}
          <br></br>
          <small>{record.manufacturer.name}</small>
        </Text>
      ),
      onFilterDropdownOpenChange: (open: any) =>
        open && fetchFilterData("manufacturer.name"),
      filters: filterData["manufacturer.name"] || [],
      filteredValue: tableFilters["manufacturer.name"] || null,
      filterSearch: true,
    },
    {
      title: "FMD Document",
      dataIndex: "fmdDocument",
      key: "fmdDocument",
      width: 150,
      render: (text: string) => {
        return text ? (
          text?.includes("http") ? (
            <span>
              <ExportOutlined onClick={() => onClickViewLink(text)} /> Link
            </span>
          ) : (
            <ReadSupportingDocument
              postUrl="materials/document/download"
              filename={text}
              foldername="materials"
            />
          )
        ) : null;
      },
    },
    {
      title: "Weight",
      dataIndex: "partWeight",
      key: "partWeight",
      width: 150,
    },
    {
      title: "Flag",
      dataIndex: "materials",
      key: "materials",
      width: 150,
      render: (text: any, record: any) => (
        <>
          {record.materials &&
            record.materials.length > 0 &&
            record.materials.find(
              (val: any) =>
                val.substances &&
                val.substances.find(
                  (ele: any) =>
                    ele &&
                    ele?.compliantalert &&
                    ele?.compliantalert.toLowerCase() === "yes"
                )
            ) && (
              <Tooltip title={getRegulation(record._id)}>
                <ExperimentOutlined style={{ color: "red" }} />
              </Tooltip>
            )}
        </>
      ),
    },
    {
      title: "FMD Comments",
      dataIndex: "fmdComments",
      key: "fmdComments",
      width: 150,
      render: (text: string) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
  ];

  const fetchData = () => {
    const filters: { [x: string]: FilterValue | null }[] = [];
    Object.keys(tableFilters).forEach((key) => {
      if (tableFilters[key] && tableFilters[key]?.length) {
        filters.push({ [key]: tableFilters[key] });
      }
    });
    setLoading(true);
    dispatch(
      readProduct(productData.key, {
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["updatedAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        softDelete: false,
        searchField:
          !filters.length && search?.searchField ? search?.query : "",
        filtersUsed: filters.length ? "useFilters" : false,
        dataTypes: JSON.stringify({
          compliant: "Dropdown",
          version: "Dropdown",
        }),
        filters: filters.length
          ? JSON.stringify({
              elements: filters,
              path: [],
            })
          : [],
      })
    )
      .then((response) => {
        setComponents(response?.components);
        setProduct(response.product);
        setComponentCount(response?.componentsCount);
        setmaterialSubstanceSelect(true);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const [maxDisplayCompliance, setmaxDisplayCompliance] = useState<number>(4);

  const renderRegulationList = (regulationList: any, data: string) => {
    if (regulationList?.length <= maxDisplayCompliance) {
      {
        regulationList?.length > 0 && (
          <FlagFilled style={{ color: "red", marginRight: 5 }} />
        );
      }
      return (
        <>
          {regulationList?.length > 0 && (
            <>
              <FlagFilled style={{ color: "red", marginRight: 5 }} />
              {regulationList?.map((regulation: any, index: number) => (
                <Tag
                  key={index}
                  bordered={false}
                  style={{
                    background:
                      selectedComponentData?._id === data
                        ? "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))"
                        : "grey",
                    color: "white",
                  }}
                >
                  {regulation}
                </Tag>
              ))}
            </>
          )}
        </>
      );
    } else {
      const visibleItems = regulationList?.slice(0, maxDisplayCompliance);
      const remainingItems = regulationList?.slice(maxDisplayCompliance);
      const remainingCount = regulationList?.length - maxDisplayCompliance;

      return (
        <>
          {visibleItems &&
            visibleItems?.map((regulation: any, index: number) => (
              <Tag
                key={index}
                style={{
                  backgroundColor:
                    selectedComponentData?._id === data ? "#000116" : "grey",
                  color: "white",
                }}
              >
                {regulation}
              </Tag>
            ))}
          <Tooltip
            title={
              remainingItems &&
              remainingItems?.map((item: any) => item).join(", ")
            }
          >
            <span
              style={{
                color:
                  selectedComponentData?._id === data ? "#eeeeee" : "#000116",
              }}
            >
              {remainingCount
                ? `
              + ${remainingCount} more`
                : ""}
            </span>
          </Tooltip>
        </>
      );
    }
  };

  const [selectedComponentData, setselectedComponentData] =
    useState<Component | null>(null);
  const [selectedMaterialData, setselectedMaterialData] =
    useState<Component | null>(null);
  const [materialSubstanceSelect, setmaterialSubstanceSelect] = useState(true);

  if (materialSubstanceSelect && componentsList) {
    setselectedComponentData(componentsList && componentsList[0]);
    setselectedMaterialData(
      componentsList[0]?.materials && componentsList[0]?.materials[0]
    );
    setmaterialSubstanceSelect(false);
  }

  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    const entries = Object.entries(filters);
    const filteredEntries = entries.filter(([key, value]) => value !== null);
    const cleanFilters = Object.fromEntries(filteredEntries);
    setTablePagination(pagination);
    setTableFilters(cleanFilters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      // setComponents([]);
    }
  };

  const onChildClick = () => {
    setSelectedComponents(null);
  };

  const regulationsTabs = regulations?.map((regulation: any) => ({
    key: regulation.regulationNumber,
    label: regulation.name,
    children: (
      <>
        <Space
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1rem",
            flexWrap: "wrap",
          }}
        >
          {selectedComponents?.key === activeTab &&
            selectedComponents?.value?.length > 0 && (
              <Tag>Selections {selectedComponents?.value?.length}</Tag>
            )}
          {Object.keys(tableFilters).length > 0 &&
            Object.keys(tableFilters).reduce<JSX.Element[]>((acc, key) => {
              const elements =
                tableFilters[key]?.map((item, idx) => (
                  <Tag
                    className="filter-tag"
                    key={`${key}-${idx}`}
                    bordered={false}
                    closable
                    onClose={() => {
                      const tablefiltrs = { ...tableFilters };
                      const arr = tablefiltrs[key];
                      if (arr) {
                        const arrIndex = arr?.indexOf(item);
                        if (arrIndex !== -1) {
                          arr.splice(arrIndex, 1);
                        }
                        setTableFilters({ ...tablefiltrs });
                      }
                    }}
                  >
                    {typeof item === "string" && item}
                  </Tag>
                )) || [];
              acc.push(...elements);
              return acc;
            }, [])}
          {Object.keys(tableFilters).length > 0 ? (
            <Button
              icon={<FilterOutlined />}
              onClick={() => {
                setTableFilters({});
                setSearch({});
              }}
            >
              Reset Filters
            </Button>
          ) : (
            <Search
              placeholder="Search using internal item number, manufacturer name or item number"
              onSearch={onSearch}
              onPressEnter={onSearch}
              allowClear
              style={{ width: 350 }}
            />
          )}
          {/* {permissions?.create?.includes("compliances") && (
            <ComplianceForm
              type="create"
              formRegulations={{
                name: regulation.name,
                regulationNumber: regulation?.regulationNumber,
              }}
              fetchData={fetchData}
            />
          )} */}
          {permissions?.update?.includes("compliances") &&
            selectedComponents?.key === activeTab &&
            selectedComponents.value.length > 0 && (
              <ComplianceForm
                type="bulk"
                formRegulations={{
                  name: regulation.name,
                  regulationNumber: regulation?.regulationNumber,
                }}
                fetchData={fetchData}
                formData={selectedComponents?.value}
                onChildClick={onChildClick}
              />
            )}
          {/* <Button type="text" icon={<FilterOutlined />} /> */}
        </Space>
        {permissions?.read?.includes("compliances") && (
          <Table
            loading={loading}
            bordered
            dataSource={componentsList?.map(
              (e: any) => e[regulation.regulationNumber]
            )}
            scroll={{
              x: 1000,
              y: "calc(100vh - 285px)",
            }}
            rowSelection={componentSelection}
            columns={[
              {
                title: "Action",
                key: "operation",
                width: 150,
                render: (_, record) => (
                  <Space>
                    {permissions?.update.includes("components") && (
                      <ComponentEditForm
                        formData={
                          componentsList.filter(
                            (e: any) => e._id === record?.componentId
                          )[0]
                        }
                        fetchData={fetchData}
                        activeTab={regulation.regulationNumber}
                      />
                    )}
                    <Button
                      type="text"
                      icon={<EyeOutlined />}
                      onClick={() => handlePreviewClick(record)}
                      data-testid="preview-component-button"
                      title="Preview"
                    />
                  </Space>
                ),
              },
              ...regulation.configuration
                .filter((e: any) => !e.hidden)
                .map((k: any, i: any) => ({
                  title: `${k.text}`,
                  dataIndex: `${k.value}`,
                  key: `${
                    k.value === "manufacturer"
                      ? "manufacturer.name"
                      : k.value.includes("complianceOutputs")
                      ? regulation.regulationNumber + "." + k.value
                      : k.value
                  }`,
                  width: k.value === "complianceOutputs.substance" ? 255 : 180,
                  render: (value: any, record: any) => {
                    if (k.value === "internalItemNumber") {
                      return (
                        <>
                          <Text>{`${record?.internalItemNumber}`}</Text>
                          {
                            <Tooltip
                              title={getSubstancesForCompliance(
                                record,
                                regulation.regulationNumber
                              )}
                            >
                              {getSubstancesForCompliance(
                                record,
                                regulation.regulationNumber
                              ).length > 0 && (
                                <ExperimentOutlined style={{ color: "red" }} />
                              )}
                            </Tooltip>
                          }
                        </>
                      );
                    }
                    if (k.value === "manufacturer") {
                      return (
                        <Text>
                          {value.itemNumber} <br></br>
                          <small>{value.name}</small>
                        </Text>
                      );
                    } else if (k.value === "complianceDocument") {
                      return value ? (
                        value?.includes("http") ? (
                          <span>
                            <ExportOutlined
                              onClick={() => onClickViewLink(value)}
                            />{" "}
                            Link
                          </span>
                        ) : (
                          <ReadSupportingDocument
                            postUrl="compliances/document/download"
                            filename={value}
                            foldername="compliances"
                          />
                        )
                      ) : null;
                    } else if (
                      k.value.includes("complianceOutputs.compliant")
                    ) {
                      if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "YES"
                      ) {
                        return <Tag color="green"> YES </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "NO"
                      ) {
                        return <Tag color="red"> NO </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "YES WITH EXEMPTION"
                      ) {
                        return <Tag color="orange"> YES WITH EXEMPTION </Tag>;
                      } else if (
                        record &&
                        record.complianceOutputs &&
                        record.complianceOutputs.compliant?.toUpperCase() ===
                          "UNKNOWN"
                      ) {
                        return <Tag color="default"> UNKNOWN </Tag>;
                      } else {
                        return record && record.complianceOutputs ? (
                          <Tag color="grey">
                            {record &&
                              record.complianceOutputs &&
                              record.complianceOutputs.compliant}
                          </Tag>
                        ) : (
                          ""
                        );
                      }
                    } else if (k.value.includes("complianceOutputs.version")) {
                      return (
                        <Text>{`${
                          record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.version
                            ? record.complianceOutputs.version
                            : ""
                        }`}</Text>
                      );
                    } else if (
                      k.value.includes("complianceOutputs.exemption")
                    ) {
                      return (
                        <Text
                          style={{ width: 200 }}
                          ellipsis={{
                            tooltip: record?.complianceOutputs?.exemption,
                          }}
                        >
                          {record &&
                          record.complianceOutputs &&
                          record.complianceOutputs.exemption
                            ? record.complianceOutputs.exemption
                            : ""}
                        </Text>
                      );
                    } else if (
                      k.value.includes("complianceOutputs.substance")
                    ) {
                      return (
                        <>
                          <Text>
                            {getSubstancesForCompliance(
                              record,
                              regulation.regulationNumber
                            )}
                          </Text>
                          <Text
                            style={{ width: 200 }}
                            ellipsis={{
                              tooltip: record?.complianceOutputs?.substance,
                            }}
                          >
                            {record &&
                            record.complianceOutputs &&
                            record.complianceOutputs.substance
                              ? record.complianceOutputs.substance
                              : ""}
                          </Text>
                        </>
                      );
                    } else {
                      return (
                        <Text>
                          {record && record?.complianceOutputs
                            ? record?.complianceOutputs[
                                k.value?.split(".").pop().toString()
                              ]
                            : ""}
                        </Text>
                      );
                    }
                  },
                  onFilterDropdownOpenChange: (open: any) =>
                    open &&
                    fetchFilterData(
                      `${
                        k.value === "manufacturer"
                          ? "manufacturer.name"
                          : k.value.includes("complianceOutputs")
                          ? "compliances." + k.value
                          : k.value
                      }`,
                      "",
                      {
                        "compliances.regulationNumber":
                          regulation.regulationNumber,
                      }
                    ),
                  filters: k.filter
                    ? filterData[
                        `${
                          k.value === "manufacturer"
                            ? "manufacturer.name"
                            : k.value.includes("complianceOutputs")
                            ? "compliances." + k.value
                            : k.value
                        }`
                      ] || []
                    : null,
                  filteredValue:
                    tableFilters[
                      `${
                        k.value === "manufacturer"
                          ? "manufacturer.name"
                          : k.value.includes("complianceOutputs")
                          ? regulation.regulationNumber + "." + k.value
                          : k.value
                      }`
                    ] || null,
                  filterSearch: true,
                })),
            ]}
            rowKey="componentId"
            pagination={{
              ...tablePagination,
              total: componentCount,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (totalCount) => `Total  ${totalCount}  items`,
            }}
            onChange={handleTableChange}
            // expandable={{
            //   rowExpandable: (record) => record.components,
            //   expandedRowRender: (record: any) => (
            //     <ExpandableTableComponent
            //       type="regulation"
            //       record={record?.componentId}
            //       regulation={regulation.regulationNumber}
            //       bomLevel="2"
            //     />
            //   ),
            // }}
          />
        )}
      </>
    ),
  }));
  const fetchUsersData = (search?: any) => {
    setLoading(true);
    dispatch(
      listUsers({
        pageSize: 10,
        pageNum: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        filters: JSON.stringify({
          firstName: { $regex: search, $options: "i" },
        }),
      })
    )
      .then((response) => {
        setUsersOptions(
          response.users.map((e: User) => ({
            label: e.firstName,
            value: e.emailAddress,
            ...e,
          }))
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const items = [
    {
      key: "overview",
      label: "Overview",
      children: (
        <>
          <ProgressChart
            loading={loading}
            regulation={product?.regulations}
            materials={product?.materials}
          />
        </>
      ),
    },
    {
      key: "all",
      label: "Component Details",
      children: (
        <>
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            {selectedComponents?.key === activeTab &&
              selectedComponents?.value?.length > 0 && (
                <Tag>Selections {selectedComponents?.value?.length}</Tag>
              )}
            {Object.keys(tableFilters).length > 0 &&
              Object.keys(tableFilters).reduce<JSX.Element[]>((acc, key) => {
                const elements =
                  tableFilters[key]?.map((item, idx) => (
                    <Tag
                      className="filter-tag"
                      key={`${key}-${idx}`}
                      bordered={false}
                      closable
                      onClose={() => {
                        const tablefiltrs = { ...tableFilters };
                        const arr = tablefiltrs[key];
                        if (arr) {
                          const arrIndex = arr?.indexOf(item);
                          if (arrIndex !== -1) {
                            arr.splice(arrIndex, 1);
                          }
                          setTableFilters({ ...tablefiltrs });
                        }
                      }}
                    >
                      {typeof item === "string" && item}
                    </Tag>
                  )) || [];
                acc.push(...elements);
                return acc;
              }, [])}
            {Object.keys(tableFilters).length > 0 ? (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setTableFilters({});
                  setSearch({});
                }}
              >
                Reset Filters
              </Button>
            ) : (
              <Search
                placeholder="Search using internal item number, manufacturer name or item number"
                onSearch={onSearch}
                onPressEnter={onSearch}
                allowClear
                style={{ width: 350 }}
              />
            )}
            {permissions?.create.includes("components") && (
              <ComponentForm type="create" fetchData={fetchData} />
            )}
            {permissions?.update?.includes("components") &&
              selectedComponents?.key === activeTab &&
              selectedComponents.value.length > 0 && (
                <BulkEditSpecifications
                  fetchData={fetchData}
                  formData={selectedComponents?.value}
                  onChildClick={onChildClick}
                />
              )}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
          {permissions?.read.includes("components") && (
            <Table
              loading={loading}
              bordered
              dataSource={componentsList}
              scroll={{
                x: 1000 + 1000 * regulations.length,
                y: "calc(100vh - 285px)",
              }}
              columns={columns}
              pagination={{
                ...tablePagination,
                total: componentCount,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (totalCount) => `Total  ${totalCount}  items`,
              }}
              rowKey="_id"
              onChange={handleTableChange}
              // expandable={{
              //   rowExpandable: (record) => record.components,
              //   expandedRowRender: (record: any) => (
              //     <ExpandableTableComponent
              //       type="component"
              //       record={record._id}
              //       bomLevel="2"
              //     />
              //   ),
              // }}
              rowSelection={componentSelection}
            />
          )}
        </>
      ),
    },
    ...regulationsTabs,
    permissions?.read.includes("materials") && {
      key: "materials",
      label: "Material Disclosure",
      children: (
        <>
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginBottom: "1rem",
            }}
          >
            {Object.keys(tableFilters).length > 0 ? (
              <Button
                icon={<FilterOutlined />}
                onClick={() => {
                  setTableFilters({});
                  setSearch({});
                }}
              >
                Reset Filters
              </Button>
            ) : (
              <Search
                placeholder="Search using internal item number, manufacturer name or item number"
                onSearch={onSearch}
                onPressEnter={onSearch}
                allowClear
                style={{ width: 350 }}
              />
            )}
            {/* { permissions?.create.includes("materials") && 
              <Button type="primary" icon={<PlusOutlined />}> Create </Button>
            } */}
            {/* <Button type="text" icon={<FilterOutlined />} /> */}
          </Space>
          {permissions?.read.includes("materials") && (
            <>
              <Row gutter={16}>
                <Col span={8}>
                  <Card title="Components" bordered={false}>
                    <List
                      key={"1"}
                      dataSource={componentsList}
                      loading={loading}
                    >
                      <div
                        style={{
                          height: 500,
                          overflow: "auto",
                          border: "1px solid rgba(140, 140, 140, 0.35)",
                        }}
                      >
                        {componentsList && componentsList.length !== 0 ? (
                          componentsList?.map((item: any, index: any) => (
                            <Card
                              key={item._id}
                              type="inner"
                              size="small"
                              style={{
                                backgroundColor:
                                  selectedComponentData?._id === item._id
                                    ? user?.primaryColor
                                    : "#eeeeee",
                                color:
                                  selectedComponentData?._id === item._id
                                    ? "#eeeeee"
                                    : user?.primaryColor,
                                marginBottom: "1rem",
                                overflow: "hidden",
                              }}
                              bordered={false}
                              onClick={() => {
                                setselectedComponentData(item);
                                setselectedMaterialData(
                                  item?.materials ? item?.materials[0] : null
                                );
                              }}
                            >
                              <Meta
                                title={
                                  <Space
                                    align="end"
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: "1rem",
                                      background:
                                        "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
                                    }}
                                  >
                                    {selectedComponentData?._id === item._id &&
                                    item?.fmdDocument ? (
                                      <span>
                                        {item?.fmdDocument?.includes("http") ? (
                                          <span>
                                            <ExportOutlined
                                              onClick={() =>
                                                onClickViewLink(
                                                  item?.fmdDocument
                                                )
                                              }
                                            />{" "}
                                          </span>
                                        ) : (
                                          <ReadSupportingDocument
                                            postUrl="materials/document/download"
                                            filename={item?.fmdDocument}
                                            foldername="materials"
                                            type={"fmdComponentList"}
                                          />
                                        )}
                                      </span>
                                    ) : (
                                      " "
                                    )}
                                    {permissions?.update?.includes(
                                      "components"
                                    ) &&
                                      (selectedComponentData?._id ===
                                      item._id ? (
                                        <span style={{ color: "white" }}>
                                          <ImportDataForm
                                            type="create"
                                            fetchData={fetchData}
                                            xmlImport={true}
                                            importTypeData={{
                                              manufacturer: item.manufacturer,
                                              id: item.componentId,
                                            }}
                                            module={"product"}
                                          />
                                          {item.fmdComments && (
                                            <Tooltip title={item.fmdComments}>
                                              <CommentOutlined />
                                            </Tooltip>
                                          )}
                                          <EditMaterialDisclosureComponents
                                            type={"Component"}
                                            fetchData={fetchData}
                                            formData={{
                                              component: selectedComponentData,
                                              material: selectedMaterialData,
                                            }}
                                          />
                                        </span>
                                      ) : undefined)}
                                  </Space>
                                }
                              />
                              <small>{item?.internalItemNumber}</small>
                              <br></br>
                              <Row>
                                <Col span={18}>
                                  <b>{item?.manufacturer?.itemNumber}</b>
                                </Col>
                                <Col span={4}>
                                  <b>
                                    {item?.partWeight
                                      ? parseFloat(item?.partWeight).toString()
                                      : "NA"}
                                    {item?.partUnitOfMeasure && item?.partWeight
                                      ? item?.partUnitOfMeasure
                                      : undefined}
                                  </b>
                                </Col>
                              </Row>
                              {item?.manufacturer?.name}
                              <br></br>

                              {getRegulation(item._id)}
                            </Card>
                          ))
                        ) : (
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        )}
                      </div>
                    </List>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Material"
                    bordered={false}
                    extra={
                      componentsList.length > 0 && (
                        <EditMaterialDisclosureComponents
                          type={"Material"}
                          fetchData={fetchData}
                          formData={{
                            component: selectedComponentData,
                            material: selectedMaterialData,
                          }}
                        />
                      )
                    }
                  >
                    <div
                      style={{
                        height: 500,
                        overflow: "auto",
                        border: "1px solid rgba(140, 140, 140, 0.35)",
                      }}
                    >
                      {selectedComponentData &&
                      selectedComponentData?.materials ? (
                        selectedComponentData?.materials?.map(
                          (item: any, index: any) => (
                            <Card
                              key={index}
                              type="inner"
                              size="small"
                              onClick={() => setselectedMaterialData(item)}
                              style={{
                                backgroundColor:
                                  selectedMaterialData?.name === item.name &&
                                  selectedMaterialData?.weight ===
                                    item.weight &&
                                  selectedMaterialData?.unitOfMeasure ===
                                    item.unitOfMeasure
                                    ? user?.secondaryColor
                                    : "#eeeeee",
                                color:
                                  selectedMaterialData?.name === item.name &&
                                  selectedMaterialData?.weight ===
                                    item.weight &&
                                  selectedMaterialData?.unitOfMeasure ===
                                    item.unitOfMeasure
                                    ? "#eeeeee"
                                    : "black",
                                marginBottom: "1rem",
                                overflow: "hidden",
                              }}
                            >
                              <Row>
                                <Col span={16}>
                                  {
                                    <CodeSandboxOutlined
                                      style={{
                                        marginRight: 5,
                                        color:
                                          item?.substances &&
                                          item?.substances.some(
                                            (subItem: {
                                              compliantalert: string;
                                            }) =>
                                              subItem?.compliantalert?.toLowerCase() ===
                                              "yes"
                                          ) &&
                                          selectedMaterialData?.name !==
                                            item?.name &&
                                          selectedMaterialData?.weight !==
                                            item.weight &&
                                          selectedMaterialData?.unitOfMeasure !==
                                            item.unitOfMeasure
                                            ? "red"
                                            : selectedMaterialData?.name ===
                                                item?.name &&
                                              selectedMaterialData?.weight ===
                                                item.weight &&
                                              selectedMaterialData?.unitOfMeasure ===
                                                item.unitOfMeasure
                                            ? "white"
                                            : "black",
                                      }}
                                    />
                                  }
                                  {item?.name}
                                </Col>
                                <Col span={8}>
                                  {parseFloat(item?.weight).toString()}
                                  {item?.unitOfMeasure || item?.unitOfMeasure}
                                </Col>
                              </Row>
                            </Card>
                          )
                        )
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title="Substance"
                    bordered={false}
                    extra={
                      selectedComponentData?.materials?.length &&
                      selectedComponentData?.materials?.length > 0 && (
                        <EditMaterialDisclosureComponents
                          type={"Substance"}
                          fetchData={fetchData}
                          formData={{
                            component: selectedComponentData,
                            material: selectedMaterialData,
                          }}
                        />
                      )
                    }
                  >
                    <div
                      style={{
                        height: 500,
                        overflow: "auto",
                        border: "1px solid rgba(140, 140, 140, 0.35)",
                      }}
                    >
                      {selectedComponentData &&
                      selectedMaterialData &&
                      selectedMaterialData?.substances?.length > 0 ? (
                        selectedMaterialData?.substances?.map(
                          (item: any, index: any) => (
                            <Card
                              key={index}
                              type="inner"
                              size="small"
                              style={{
                                backgroundColor: "#eeeeee",
                                color: "black",
                                marginBottom: "1rem",
                                overflow: "hidden",
                              }}
                            >
                              {
                                <ExperimentOutlined
                                  style={{
                                    marginRight: 5,
                                    color:
                                      item?.compliantalert &&
                                      item?.compliantalert === "Yes"
                                        ? "red"
                                        : "black",
                                  }}
                                />
                              }
                              <b>
                                {" "}
                                {typeof item?.name === "object"
                                  ? item.name?.substance
                                  : item.name}
                              </b>
                              <br></br>
                              <Row>
                                <Col span={16} style={{ paddingLeft: "20px" }}>
                                  {"CAS # "}
                                  <b>{item?.casNumber}</b> <br></br>
                                </Col>
                                <Col span={8}>
                                  <b>
                                    {parseFloat(item?.weight).toString()}
                                    {item?.unitOfMeasure}
                                  </b>
                                </Col>
                              </Row>
                              {item.compliantalertRegulation?.length > 0 && (
                                <>
                                  <FlagFilled
                                    style={{ color: "red", marginRight: 5 }}
                                  />
                                  {regulations
                                    .filter(
                                      (regulation: { regulationNumber: any }) =>
                                        item?.compliantalertRegulation.includes(
                                          regulation.regulationNumber
                                        )
                                    )
                                    .map((item: any) => (
                                      <Tag
                                        key={item?.regulationNumber}
                                        style={{
                                          backgroundColor: "grey",
                                          color: "white",
                                        }}
                                      >
                                        {item.name}
                                      </Tag>
                                    ))}
                                </>
                              )}
                            </Card>
                          )
                        )
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </div>
                  </Card>
                </Col>
              </Row>
              <Pagination
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
                {...tablePagination}
                total={componentCount}
                showTotal={(totalCount) => `Total  ${totalCount}  items`}
                showSizeChanger={true}
                showQuickJumper={true}
                onChange={(page, pageSize) =>
                  setTablePagination({
                    ...tablePagination,
                    current: page,
                    pageSize,
                  })
                }
              />
            </>
          )}
        </>
      ),
    },
  ];
  useEffect(() => {
    fetchData();
    fetchUsersData("");
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setmaxDisplayCompliance(2);
      } else if (window.innerWidth < 1024) {
        setmaxDisplayCompliance(3);
      } else {
        setmaxDisplayCompliance(7);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    search,
  ]);

  const onFormSubmit = (values: Product) => {
    dispatch(updateProduct({ updates: values }, product._id))
      .then(() => {
        fetchData();
        setShowEditBtn(false);
      })
      .catch((e: any) => console.log(e));
  };
  const options: SelectProps["options"] = [];

  regulations.forEach((regulation: any) => {
    options.push({
      label: regulation.name,
      value: regulation.regulationNumber,
    });
  });

  return (
    <>
      <ErrorBoundary>
        <Spin fullscreen spinning={loadingData} />
        <Card
          bordered={false}
          loading={loading}
          style={{
            background: getLinearGradient(user?.primaryColor || "#045B7C"),
            margin: "-1em -30px 0",
          }}
        >
          <Row justify="space-between" align="bottom">
            <Col>
              <Row align="middle" gutter={25}>
                <Col>
                  <Avatar
                    size={85}
                    src={productIcon}
                    style={{ backgroundColor: "#fff" }}
                  />
                </Col>
                <Col>
                  <div>
                    <Title level={5} style={{ color: "white", marginTop: 0 }}>
                      {productData?.name}
                    </Title>
                    <Title level={4} style={{ color: "white", margin: 0 }}>
                      {productData?.number}
                    </Title>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col>
              <Space>
                <Button
                  type="primary"
                  onClick={handleHideDetails}
                  className="btn-light"
                >
                  {" "}
                  {isHideDetails ? "Hide Details" : "Show Details"}
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
        {isHideDetails && (
          <Card style={{ marginTop: "1rem" }}>
            <Space
              align="end"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <Meta
                title="About the Product :"
                description={
                  product?.createdAt
                    ? `Created on : ${new Date(
                        product?.createdAt
                      ).toLocaleString("en-US", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}`
                    : "N/A"
                }
              />
              <Space>
                {!showEditBtn ? (
                  <Button
                    type="primary"
                    ghost
                    onClick={() => setShowEditBtn(!showEditBtn)}
                    icon={<SettingOutlined />}
                  >
                    Edit
                  </Button>
                ) : (
                  <Space>
                    <Button onClick={() => setShowEditBtn(!showEditBtn)}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={form.submit}
                      disabled={isUploading}
                    >
                      Save
                    </Button>
                  </Space>
                )}
              </Space>
            </Space>
            <Divider />
            <Form
              wrapperCol={{ span: 24 }}
              layout="vertical"
              initialValues={{ ...product }}
              onFinish={onFormSubmit}
              form={form}
            >
              <Row gutter={24}>
                {productSpecifications &&
                  productSpecifications?.length > 0 &&
                  productSpecifications?.map((specification: any) => (
                    <Col key={specification.value} span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name={["specification", specification.value]}
                          label={
                            specification.name
                              ? specification.name.charAt(0).toUpperCase() +
                                specification.name.slice(1)
                              : ""
                          }
                          initialValue={
                            product?.specification !== undefined &&
                            specification?.dataType === "Dateinput" &&
                            product?.specification[specification?.value]
                              ? dayjs(
                                  product?.specification[specification?.value]
                                )
                              : product?.specification &&
                                product?.specification[specification?.value]
                          }
                        >
                          {specification.dataType === "Textfield" && <Input />}
                          {specification.dataType === "Dropdown" && (
                            <Select
                              allowClear
                              placeholder={`Select ${specification.name}`}
                              options={specification.dataValue
                                .split(",")
                                .map((value: string) => ({
                                  label: value,
                                  value,
                                }))}
                            />
                          )}
                          {specification.dataType === "Fileinput" && (
                            <FileUploader
                              postUrl="products/components/document/upload"
                              tenantNumber={tenantNumber}
                              setFormField={(value: string) =>
                                form.setFieldValue(
                                  ["specification", specification.value],
                                  value
                                )
                              }
                              onUploadStatusChange={setIsUploading}
                            />
                          )}
                          {specification.dataType === "Dateinput" && (
                            <DatePicker />
                          )}
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>
                            {specification.name
                              ? specification.name.charAt(0).toUpperCase() +
                                specification.name.slice(1)
                              : ""}
                          </Text>
                          <br />
                          <Text type="secondary">
                            {product?.specification !== undefined &&
                            specification?.dataType === "Dateinput" &&
                            product?.specification[specification?.value]
                              ? new Date(
                                  product?.specification[specification?.value]
                                ).toLocaleDateString()
                              : (product?.specification &&
                                  product?.specification[
                                    specification?.value
                                  ]) ||
                                "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                  ))}
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="name"
                      label="Product Name"
                      initialValue={(product && product?.name) || "N/A"}
                    >
                      <Input disabled />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Product Name</Text>
                      <br />
                      <Text type="secondary">{product?.name || "N/A"}</Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="number"
                      label="Product Id"
                      initialValue={(product && product?.number) || "N/A"}
                    >
                      <Input disabled />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Product Id</Text>
                      <br />
                      <Text type="secondary">{product?.number || "N/A"}</Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="revision"
                      label="Revision"
                      initialValue={(product && product?.revision) || "NA"}
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Revision</Text>
                      <br />
                      <Text type="secondary">{product?.revision || "N/A"}</Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="bomLevel"
                      label="Bom Level"
                      initialValue={(product && product?.bomLevel) || "0"}
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Bom Level</Text>
                      <br />
                      <Text type="secondary">{product?.bomLevel || "0"}</Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item name="outOfScope" label="Out of scope for">
                      <Select
                        mode="multiple"
                        allowClear
                        placeholder="Select Regulations"
                        options={options}
                      />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Out of scope for</Text>
                      <br />
                      <Text type="secondary">
                        {product?.outOfScope || "N/A"}
                      </Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="countryOfOrigin"
                      label="Country of Origin"
                      initialValue={
                        (product && product?.countryOfOrigin) || "N/A"
                      }
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Country of Origin</Text>
                      <br />
                      <Text type="secondary">
                        {product?.countryOfOrigin || "N/A"}
                      </Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="assignedTo"
                      label="Assigned to"
                      initialValue={(product && product?.assignedTo) || "N/A"}
                    >
                      <Select
                        options={usersOptions}
                        onSearch={(text) => fetchUsersData(text)}
                        placeholder="Please select a users"
                        allowClear
                      />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Assigned to</Text>
                      <br />
                      <Text type="secondary">
                        {product?.assignedTo || "N/A"}
                      </Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="risk"
                      label="Risk Level"
                      initialValue={(product && product?.risk) || "Low"}
                    >
                      <Select>
                        <Option value="High">High</Option>
                        <Option value="Medium">Medium</Option>
                        <Option value="Low">Low</Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Risk Level</Text>
                      <br />
                      <Text type="secondary">{product?.risk || "N/A"}</Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="countriesSoldIn"
                      label="Countries Sold In"
                      initialValue={
                        (product && product?.countriesSoldIn) || "N/A"
                      }
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Countries Sold In</Text>
                      <br />
                      <Text type="secondary">
                        {product?.countriesSoldIn || "N/A"}
                      </Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="externalProductNumber"
                      label="External Product Number"
                      initialValue={
                        (product && product?.externalProductNumber) || "N/A"
                      }
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Countries Sold In</Text>
                      <br />
                      <Text type="secondary">
                        {product?.externalProductNumber || "N/A"}
                      </Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="industries"
                      label="Industries"
                      initialValue={(product && product?.industries) || "N/A"}
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Industries</Text>
                      <br />
                      <Text type="secondary">
                        {product?.industries || "N/A"}
                      </Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="customers"
                      label="Customers"
                      initialValue={(product && product?.customers) || "N/A"}
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Customers</Text>
                      <br />
                      <Text type="secondary">
                        {product?.customers || "N/A"}
                      </Text>
                    </div>
                  )}
                </Col>
                <Col span={6} sm={6} md={6}>
                  {showEditBtn ? (
                    <Form.Item
                      name="comments"
                      label="Comments"
                      initialValue={(product && product?.comments) || "N/A"}
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <div className="form-values">
                      <Text>Comments</Text>
                      <br />
                      <Text type="secondary">{product?.comments || "N/A"}</Text>
                    </div>
                  )}
                </Col>

                {/* {Object.keys(product?.specification)?.length > 0 && (
                  <>
                    <Col span={6} sm={6} md={6}>
                      {showEditBtn ? (
                        <Form.Item
                          name="productdescription"
                          label="Product Description"
                          initialValue={
                            (product?.specification &&
                              product?.specification?.productdescription) ||
                            "N/A"
                          }
                        >
                          <Input />
                        </Form.Item>
                      ) : (
                        <div className="form-values">
                          <Text>Product Description</Text>
                          <br />
                          <Text type="secondary">
                            {product?.specification?.productdescription ||
                              "N/A"}
                          </Text>
                        </div>
                      )}
                    </Col>
                  </>
                )} */}
              </Row>
            </Form>
          </Card>
        )}
        <Tabs
          defaultActiveKey="overview"
          items={items}
          tabPosition="top"
          activeKey={activeTab}
          onChange={handleTabChange}
          className="sub-assemblies-tabs"
        >
          {" "}
        </Tabs>
      </ErrorBoundary>
    </>
  );
};

export default ProductProfile;
