import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Row, Col, Space, Result } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { sendRequest } from "../../redux/actions/supplierPortalAction";

const TechnicalSupport: React.FC <{ showTechnicalSupportModal: boolean, setShowTechnicalSupportModal: any }> = (props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(()=>{
    setIsModalOpen(props?.showTechnicalSupportModal);
  }, [props?.showTechnicalSupportModal]);

  const onClose = () => {
    setIsModalOpen(false);
    props?.setShowTechnicalSupportModal(false);
  };

  const onFormSubmit = (values: any) => {
    setLoading(true);
    dispatch(
      sendRequest({
        receiver: "support@acquiscompliance.com",
        ...values,
      })
    )
      .then(() => {
        setSuccess(true);
        setLoading(false);
      })
      .catch(() => {
        setSuccess(false);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        title="Technical Support"
        footer={
          !success && (
            <Button type="primary" onClick={form.submit} loading={loading}>
              Send Mail
            </Button>
          )
        }
        open={isModalOpen}
        onCancel={onClose}
      >
        {success ? (
          <Result
            status="success"
            title="Technical Support request sent"
            subTitle="The administrator has received your request, the administrator will contact you soon."
          />
        ) : (
          <Space direction="vertical" size="large">
            <Form form={form} layout="vertical" onFinish={onFormSubmit}>
              <Row gutter={16} justify="center">
                <Col span={12}>
                  <Form.Item
                    label="Your Name"
                    name="name"
                    rules={[{ required: true, message: "Name is required" }]}
                  >
                    <Input placeholder="Your Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Your Email"
                    name="sender"
                    rules={[
                      { required: true, message: "Email address is required" },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}
                  >
                    <Input type="email" placeholder="Your Email" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is required" }]}
                  >
                    <Input placeholder="Subject" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Message"
                    name="message"
                    rules={[{ required: true, message: "Message is required" }]}
                  >
                    <Input.TextArea placeholder="Your Message" rows={4} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Space>
        )}
      </Modal>
    </>
  );
};

export default TechnicalSupport;
