import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Space, Typography, Input, Table, Button } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Regulation } from "../../../utils/types/regulation";

import { FileExcelOutlined, EyeOutlined } from "@ant-design/icons";
import { getRegulationsFromLocalStorage } from "../../../utils/localStore";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";

const { Title } = Typography;

const ExportData: React.FC = () => {
  const navigate = useNavigate();
  const [exports, setExports] = useState<Regulation[]>([]);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Regulation>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();
  const regulations = getRegulationsFromLocalStorage() || [];
  const [reportTemplates, setReportTemplates] = useState([]);

  const columns: ColumnsType<Regulation> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: Regulation) => (
        // (record.status === "complete" || record.status === "incomplete") && (
        <>
          {/* <Button
            type="text"
            icon={<EyeOutlined />}
            title="Preview"
            onClick={() => onClickDownload(record)}
          /> */}
          <Button
            type="text"
            icon={<FileExcelOutlined />}
            title="Generate Report"
            onClick={() => navigate("generator/" + record.regulationNumber)}
          />
        </>
        // ),
      ),
    },
    {
      title: "Report Template",
      dataIndex: "reportName",
      key: "reportName",
      sorter: true,
    },
  ];

  const handleTableChange: TableProps<Regulation>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Regulation> | SorterResult<Regulation>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Regulation>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setExports([]);
    }
  };

  useEffect(() => {
    setReportTemplates(
      regulations.map((regulation: Regulation) => ({
        regulationNumber: regulation.regulationNumber,
        reportName: regulation.name + " Compliance",
      }))
    );
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Export Reports
            </Title>
          </div>
          {/* <Space>
            <Search
              placeholder="Search using filename or status or module"
              allowClear
              onSearch={(text) =>
                fetchData({
                  softDelete: false,
                  $or: [
                    { filename: { $regex: text, $options: "i" } },
                    { status: { $regex: text, $options: "i" } },
                    { ExportType: { $regex: text, $options: "i" } },
                  ],
                })
              }
              style={{ width: 350 }}
            />
            <ExportDataForm fetchData={fetchData} />
          </Space> */}
        </Space>
        <Table
          loading={loading}
          dataSource={reportTemplates}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: reportTemplates.length,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </>
  );
};

export default ExportData;
