import React, { useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import Suppliers from "./suppliers";
import Components from "./components";

import { Button, Tabs } from "antd";
import type { TabsProps } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { getLocalStorage } from "../../../../../utils/localStore";
import EditListForm from "../../../../../components/forms/EditListForm";
import { Supplier } from "../../../../../utils/types/supplier";
import { useAppDispatch } from "../../../../../redux/hooks";
import {
  readListsComponents,
  readListsSuppliers,
} from "../../../../../redux/actions/CampaignManager/listsAction";
import { Component } from "../../../../../utils/types/component";

const SurveyDetails: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const location = useLocation();
  const navigate = useNavigate();
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const dispatch = useAppDispatch();
  const [listSuppliers, setListSuppliers] = useState<Supplier[]>([]);
  const [listSupplierCount, setListSupplierCount] = useState<number>(0);
  const [listComponents, setListComponents] = useState<Component[]>([]);
  const [listComponentCount, setListComponentCount] = useState<number>(0);
  const [emitChild, setChildEmit] = useState("");
  const [childLoading, setChildLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Supplier>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const fetchSupplierData = () => {
    dispatch(
      readListsSuppliers({
        id: id,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: "",
      })
    )
      .then((response: any) => {
        setListSuppliers(response.listSuppliers);
        setListSupplierCount(response.listSupplierCount);
        setTimeout(() => {
          setChildLoading(false);
        }, 1500);
      })
      .catch(() => {
        setChildLoading(false);
      });
  };

  const fetchComponentData = () => {
    dispatch(
      readListsComponents({
        id: id,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: "",
      })
    )
      .then((response: any) => {
        setListComponents(response.listComponents);
        setListComponentCount(response.listComponentCount);
        setTimeout(() => {
          setChildLoading(false);
        }, 1500);
      })
      .catch(() => {
        setChildLoading(false);
      });
  };

  const fetchData = () => {
    setChildLoading(true);
    fetchSupplierData();
    permissions?.read?.includes("components") && fetchComponentData();
    setChildEmit("emitChild");
  };

  const handleTabChange = (key: any) => {
    navigate(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "",
      label: "Suppliers",
      children: (
        <Suppliers
          suppliers={listSuppliers}
          count={listSupplierCount}
          childLoading={childLoading}
          type={emitChild}
        />
      ),
    },
    permissions?.read?.includes("components") && {
      key: "components",
      label: "Components",
      children: (
        <Components
          components={listComponents}
          count={listComponentCount}
          childLoading={childLoading}
          type={emitChild}
        />
      ),
    },
  ].filter(Boolean);

  const onClickBack = () => {
    navigate("/app/campaignmanager/survey");
  };

  return (
    <Tabs
      tabBarExtraContent={{
        left: (
          <Button style={{ marginRight: "1rem" }} onClick={onClickBack}>
            Back
          </Button>
        ),
        right: (
          <EditListForm
            fetchData={fetchData}
            formData={{ id: id }}
          />
        ),
      }}
      activeKey={
        location.pathname.split("/").pop() === id
          ? ""
          : location.pathname.split("/").pop()
      }
      items={items}
      onChange={handleTabChange}
    />
  );
};

export default SurveyDetails;
