import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  RollbackOutlined,
  WarningFilled,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Space,
  Typography,
  Button,
  Table,
  Input,
  Tooltip,
  Popconfirm,
  Tag,
} from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";

import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Supplier } from "../../../../../utils/types/supplier";
import { useAppDispatch } from "../../../../../redux/hooks";
import { readListsSuppliers } from "../../../../../redux/actions/CampaignManager/listsAction";
import EditListForm from "../../../../../components/forms/EditListForm";
import { setLocalStorage } from "../../../../../utils/localStore";
import SupplierForm from "../../../../../components/forms/SupplierForm";
import { deleteContacts } from "../../../../../redux/actions/CampaignManager/contactsAction";
import { getLocalStorage } from "../../../../../utils/localStore";
import ErrorBoundary from "../../../../../utils/errorBoundary";

const { Search } = Input;
const { Text } = Typography;

const SuverySuppliers: React.FC<{
  suppliers: Supplier[];
  count: number;
  childLoading: boolean;
  type: string;
}> = ({ suppliers, count, childLoading, type }) => {
  const { id } = useParams<{ id: any }>();
  const dispatch = useAppDispatch();
  const [listSuppliers, setListSuppliers] = useState<Supplier[]>([]);
  const [listSupplierCount, setListSupplierCount] = useState<number>(0);
  const navigate = useNavigate();
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Supplier>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});

  const columns: ColumnsType<Supplier> = [
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (_: any, record: any) => (
        <Space>
          <Popconfirm
            title={`Are you sure want to delete ${record?.name}`}
            onConfirm={() => deleteContactList(record)}
            okText="Ok"
            cancelText="Cancel"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              data-testid="delete-supplier-button"
              title="Delete Supplier"
            />
          </Popconfirm>
          <SupplierForm fetchData={fetchData} formData={record} type="edit" />
        </Space>
      ),
    },
    {
      title: "Supplier ID",
      dataIndex: "number",
      key: "number",
      sorter: true,
      width:150,

    },
    {
      title: "Supplier Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Last Updated",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (text: string, record: any) => (
        <Text>{new Date(text).toLocaleString()}</Text>
      ),
    },
    {
      title: "Recipient E-Mail",
      dataIndex: "activeContacts",
      key: "activeContacts",
      render: (value: any, record: any) => (
        <Text>
          {!(
            record.contacts &&
            record.contacts.length &&
            record.contacts.find((e: any) => e.isPrimary && e.emailAddress)
          ) ? (
            <>
              <Tooltip title={`${value} contacts`}>
                <WarningFilled style={{ color: "red" }} />
              </Tooltip>
            </>
          ) : (
            record.contacts &&
            record.contacts.length &&
            record.contacts.find((e: any) => e.isPrimary)?.emailAddress
          )}
        </Text>
      ),
    },
    permissions?.read?.includes("components") && {
      title: "Parts Selected",
      key: "partsSelected",
      render: (record: any) => (
        <Text>
          {`Selected: ${record.selectedComponents || 0} / Active: ${
            record.activeComponents || 0
          }`}
        </Text>
      ),
    },
    {
      title: "In Scope for",
      dataIndex: "inScope",
      key: "inScope",
      ellipsis: true,
      render: (inScope: any) => {
        if (!inScope || inScope.length === 0) return null;

        const firstItem = inScope[0].name;
        const remainingItems = inScope.slice(1).map((e: any) => e.name);

        return (
          <>
            <Tag color="orange">{firstItem}</Tag>
            {remainingItems.length > 0 && (
              <Tooltip title={remainingItems.join(", ")}>
                <Text style={{ color: "orange" }}>
                  +{remainingItems.length} more
                </Text>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ].filter(Boolean);

  const deleteContactList = (record: any) => {
    dispatch(
      deleteContacts({
        id: {
          listId: id,
          supplier: record?._id,
          supplierName: record?.name,
        },
      })
    ).then(() => {
      fetchData();
    });
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(
      readListsSuppliers({
        id: id,
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        softDelete: false,
        search: "",
      })
    )
      .then((response: any) => {
        setListSuppliers(response.listSuppliers);
        setListSupplierCount(response.listSupplierCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Supplier>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Supplier> | SorterResult<Supplier>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Supplier>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      return;
    }
  };

  const onClickBack = () => {
    navigate(-1);
    setLocalStorage("BackTabActivate", "list");
  };

  useEffect(() => {
    fetchData();
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Table
          loading={type === "emitChild" ? childLoading : loading}
          dataSource={type === "emitChild" ? suppliers : listSuppliers}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: type === "emitChild" ? count : listSupplierCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default SuverySuppliers;
