import React, { useEffect } from "react";

import {
  CheckCircleFilled,
  ExclamationCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

import { Space, Card, Typography, Col, Row, Tag } from "antd";
import { getRegulationsFromLocalStorage } from "../../../../utils/localStore";

const { Text } = Typography;

const Overview: React.FC<{
  componentCampaign: any;
  component: any;
  alternates: any;
}> = (props) => {
  const [compliances, setCompliances] = React.useState<any[]>();
  const regulations = getRegulationsFromLocalStorage() ?? [];

  const fetchData = () => {
    const compliances: any[] = [];
    regulations?.forEach((regulation: any) => {
      const compliance = props?.component?.compliances.find(
        (item: any) => item.regulationNumber === regulation.regulationNumber
      );
      if (compliance) {
        compliances.push({
          regulationName: regulation.name,
          ...compliance,
        });
      }
    });
    setCompliances(compliances);
  };

  useEffect(() => {
    fetchData();
  }, [props.component]);

  return (
    <>
      <Row>
        {compliances &&
          compliances.map((item: any) => (
            <Col key={item.regulationNumber} span={8}>
              <Card
                style={{
                  marginRight: "1rem",
                }}
                title={
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Space>
                      <Text>{item.regulationName}</Text>
                    </Space>
                  </Space>
                }
              >
                <div style={{ textAlign: "center" }}>
                  <Tag
                    color={
                      item.complianceOutputs?.compliant
                        ?.toUpperCase()
                        .trim() === "YES"
                        ? "#73d13d"
                        : item.complianceOutputs?.compliant
                              ?.toUpperCase()
                              .trim() === "NO"
                          ? "#ff7875"
                          : item.complianceOutputs?.compliant
                                ?.toUpperCase()
                                .trim() === "YES WITH EXEMPTION"
                            ? "#ffa940"
                            : "grey"
                    }
                    icon={
                      item.complianceOutputs?.compliant
                        ?.toUpperCase()
                        .trim() === "YES" ? (
                        <CheckCircleFilled />
                      ) : item.complianceOutputs?.compliant
                          ?.toUpperCase()
                          .trim() === "NO" ? (
                        <CloseCircleOutlined />
                      ) : item.complianceOutputs?.compliant
                          ?.toUpperCase()
                          .trim() === "YES WITH EXEMPTION" ? (
                        <ExclamationCircleOutlined />
                      ) : (
                        <QuestionCircleOutlined />
                      )
                    }
                  >
                    {item?.complianceOutputs &&
                      item.complianceOutputs?.compliant}
                  </Tag>{" "}
                  <br></br>
                  <Text>
                    {" "}
                    <b> Version: </b>
                    {(item?.complianceOutputs &&
                      item.complianceOutputs?.version) ||
                      "NA"}{" "}
                  </Text>{" "}
                  <br></br>
                  {item?.complianceOutputs &&
                    item?.complianceOutputs?.substance && (
                      <Text>
                        {" "}
                        <b> Substance of concern: </b>
                        {item.complianceOutputs?.substance}{" "}
                      </Text>
                    )}
                  {item?.complianceOutputs &&
                    item?.complianceOutputs?.exemption && (
                      <Text>
                        {" "}
                        <b> Exemption Claimed: </b>
                        {item.complianceOutputs?.exemption}{" "}
                      </Text>
                    )}
                </div>
                <Space
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "2rem",
                  }}
                >
                  <Text type="secondary">
                    <small>
                      Last updated :{" "}
                      {props.component &&
                        props.component?.updatedAt &&
                        new Date(
                          props.component?.updatedAt
                        ).toLocaleString()}{" "}
                      <br></br>
                      By: {props.component && props.component?.updatedBy}
                    </small>
                  </Text>
                </Space>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
};

export default Overview;
