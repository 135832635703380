import React, { useState } from "react";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { getRegulationsFromLocalStorage } from "../../../utils/localStore";
import { useAppDispatch } from "../../../redux/hooks";
import { createScipComponent } from "../../../redux/actions/scipAction";

const CreateComponentScip: React.FC<{
  fetchData: any;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const regulations = getRegulationsFromLocalStorage() ?? [];
  const configuration = regulations
    .find((e: any) => e.regulationNumber === "eu_reach")
    ?.configuration.find((v: any) => v.value === "complianceOutputs.version");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = (values: any) => {
    setLoading(true);
    dispatch(createScipComponent(values)).then(() => {
      props.fetchData();
      form.resetFields();
      setLoading(false);
      setIsModalOpen(false);
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        title="New Component"
        icon={<PlusOutlined />}
        onClick={showModal}
      >
        New Component
      </Button>
      <Modal
        title="Add Component"
        open={isModalOpen}
        onCancel={handleCancel}
        centered
        maskClosable={false}
        footer={[
          <Button onClick={handleCancel} key="cancel">
            Cancel
          </Button>,
          <Button
            onClick={form.submit}
            type="primary"
            loading={loading}
            key="add"
          >
            Add
          </Button>,
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.List name="manufacturer">
            {() => (
              <>
                <Row gutter={16}>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="name"
                      label="Manufacturer Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Manufacturer Name!",
                        },
                        {
                          pattern: /^([a-zA-Z0-9()&.{}[\],"'-]+\s?)*$/,
                          message: "Not allowed!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="itemNumber"
                      label="Manufacturer Item Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input Manufacturer Item Number!",
                        },
                        {
                          pattern: /^([a-zA-Z0-9-/,(.)_*])+$/,
                          message:
                            'AlphaNumeric Values with Special Characters like "- / , ( ) . _ * " allowed without space',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
          <Col span={24} sm={24} md={24}>
            <Form.Item
              name="internalItemNumber"
              label="Internal Item Number"
              rules={[
                {
                  required: true,
                  message: "Please input Internal item number!",
                },
                {
                  pattern: /^([a-zA-Z0-9-/])+$/,
                  message:
                    'AlphaNumeric Values with Special Characters like "- /" allowed without space',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} sm={24} md={24}>
            <Form.Item name="version" label="EU REACH SVHC Version">
              <Select
                placeholder={`Select ${configuration.text}`}
                options={configuration.dataValue
                  .split(",")
                  .map((value: string) => ({ label: value, value }))}
              />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default CreateComponentScip;
