import React, { useEffect, useState } from "react";
import { LockOutlined, UnlockOutlined, EyeOutlined } from "@ant-design/icons";
import { Space, Typography, Button, Table, Input, Popconfirm } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";

import { Tenant } from "../../../../utils/types/tenant";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  listTenants,
  updateTenant,
} from "../../../../redux/actions/tenantsAction";
import { getLocalStorage } from "../../../../utils/localStore";

import TenantForm from "../../../../components/forms/TenantForm";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { Search } = Input;
const { Title, Text } = Typography;

const TenantList: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [tenantCount, setTenantCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Tenant>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();
  const [search, setSearch] = useState<any>();
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const columns: ColumnsType<Tenant> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: Tenant) => (
        <Space>
          <TenantForm type="update" fetchData={fetchData} formData={record} />
          <Popconfirm
            title={record.blocked ? "Unblock Tenant" : "Block Tenant"}
            description={
              "Are you sure you want to " +
              (record.blocked ? "unblock" : "block") +
              " tenant?"
            }
            onConfirm={() =>
              blockAccess(record._id, { blocked: !record.blocked })
            }
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              danger={record.blocked}
              title={record.blocked ? "Unblock Tenant" : "Block Tenant"}
              icon={record.blocked ? <LockOutlined /> : <UnlockOutlined />}
              disabled={!permissions["update"]?.includes("tenants")}
            />
          </Popconfirm>
          <Button
            type="text"
            icon={<EyeOutlined />}
            onClick={() => navigate(record._id.toString())}
          />
        </Space>
      ),
    },
    {
      title: "Tenant #",
      dataIndex: "number",
      key: "number",
      sorter: true,
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      sorter: true,
    },
    {
      title: "POC Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const fetchData = (payload?: any) => {
    setLoading(true);
    dispatch(
      listTenants({
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.column
          ? tableSorter.order === "descend"
            ? false
            : true
          : false,
        filters: payload ? payload : null,
      })
    )
      .then((response) => {
        setTenants(response.tenants);
        setTenantCount(response.tenantCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Tenant>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Tenant> | SorterResult<Tenant>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Tenant>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setTenants([]);
    }
  };

  const blockAccess = (id: string, payload?: any) => {
    setLoading(true);
    dispatch(
      updateTenant(
        {
          currentLocationOrigin: window.location.origin,
          updates: payload,
        },
        id
      )
    )
      .then(() => {
        fetchData();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(JSON.stringify(search));
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    JSON.stringify(search),
  ]);

  return (
    <div>
      <ErrorBoundary>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <div>
            <Title level={5} style={{ marginBottom: 0 }}>
              Tenants
            </Title>
          </div>
          <Space>
            <Search
              placeholder="Search using number or company name or first name or last name"
              allowClear
              onSearch={(text) =>
                setSearch({
                  softDelete: false,
                  $or: [
                    { number: { $regex: text.trim(), $options: "i" } },
                    { companyName: { $regex: text.trim(), $options: "i" } },
                    { firstName: { $regex: text.trim(), $options: "i" } },
                    { lastName: { $regex: text.trim(), $options: "i" } },
                  ],
                })
              }
              style={{ width: 350 }}
            />
            <TenantForm type="create" fetchData={fetchData} />
          </Space>
        </Space>
        <Table
          loading={loading}
          dataSource={tenants}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={columns}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: tenantCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </div>
  );
};

export default TenantList;
