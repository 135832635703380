import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Space,
  Button,
  Drawer,
  Divider,
  Select,
  DatePicker,
  Typography,
} from "antd";

import { Product } from "../../utils/types/product";
import { useAppDispatch } from "../../redux/hooks";
import {
  createProduct,
  updateProduct,
} from "../../redux/actions/productsAction";
import { getLocalStorage } from "../../utils/localStore";
import FileUploader from "../layouts/FileUploader";

const { Title } = Typography;

const ProductForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Product;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const productSpecifications = getLocalStorage("user").productSpecifications;
  const { tenantNumber } = getLocalStorage("user");

  const [isUploading, setIsUploading] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFormSubmit = (values: Product) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(createProduct(values))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(updateProduct({ updates: values }, props.formData._id))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <div>
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        title={props.type === "create" ? "New Product" : "Edit Product"}
        data-testid={
          props.type === "create"
            ? "create-product-button"
            : "edit-product-button"
        }
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
      >
        {props.type === "create" ? "Add Product" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Product" : "Edit Product"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-product-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-product-button"
              disabled={isUploading}
            >
              {props.type === "create" ? "Submit" : "Save"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.formData }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="name"
                label="Product Name"
                rules={[
                  { required: true, message: "Please input Product Name!" },
                  {
                    pattern: /^([a-zA-Z0-9()]+\s?)*$/,
                    message: "Special Characters not allowed!",
                  },
                ]}
              >
                <Input disabled={props.type === "edit"} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="number"
                label="Product Id"
                rules={[
                  { required: true, message: "Please input Product Number!" },
                  {
                    pattern: /^([a-zA-Z0-9()-/.,]+\s?)*$/,
                    message:
                      'AlphaNumeric Values with Special Characters like "- / , ( ) ." allowed!',
                  },
                ]}
              >
                <Input disabled={props.type === "edit"} />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="revision" label="Revision">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="bomLevel"
                label="Bom Level"
                initialValue={props.formData?.bomLevel || 0}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          {productSpecifications?.length > 0 && (
            <>
              <Title level={5}>Product Specification</Title>
              <Divider />
              <Row gutter={24}>
                {productSpecifications?.map((specification: any) => (
                  <Col key={specification.value} span={12} sm={12} md={12}>
                    <Form.Item
                      name={["specification", specification.value]}
                      label={specification.name}
                    >
                      {specification.dataType === "Textfield" && <Input />}
                      {specification.dataType === "Dropdown" && (
                        <Select
                          allowClear
                          placeholder={`Select ${specification.name}`}
                          options={specification.dataValue
                            .split(",")
                            .map((value: string) => ({
                              label: value,
                              value,
                            }))}
                        />
                      )}
                      {specification.dataType === "Fileinput" && (
                        <FileUploader
                          postUrl="products/components/document/upload"
                          tenantNumber={tenantNumber}
                          setFormField={(value: string) =>
                            form.setFieldValue(
                              ["specification", specification.value],
                              value
                            )
                          }
                          formField={""}
                          onUploadStatusChange={setIsUploading}
                        />
                      )}
                      {specification.dataType === "Dateinput" && <DatePicker />}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Form>
      </Drawer>
    </div>
  );
};

export default ProductForm;
