import React, { useEffect, useState } from "react";
import { Tabs, Typography, Space } from "antd";
import ProductList from "./ProductList";
import ProductProfile from "./ProductProfile";
import SubAssemblies from "./SubAssemblies";
import { getLocalStorage, setLocalStorage } from "../../../utils/localStore";
import "../../../index.css";
import Icon from "./../../../Icon";

const { Text } = Typography;

const Products: React.FC = () => {
  const [product, setProduct] = useState<any | null>([]);
  const [activeKey, setActiveKey] = useState<string>("Products");
  const [tabItems, setTabItems] = useState<any[]>([]);
  const user = getLocalStorage("user");

  const handleTabChange = (key: string) => {
    setActiveKey(key);
    setLocalStorage("setActiveKey", key);
  };

  const handlePreviewClick = (values: any) => {
    setProduct((prevData: any) => {
      if (prevData !== null) {
        if (prevData.find((ele: any) => ele.key === values.key)) {
          return prevData;
        } else {
          setLocalStorage("tabItems", [...prevData, values]);
          return [...prevData, values];
        }
      }
    });
    setTabItems((prevItems: any) => {
      if (prevItems.find((ele: any) => ele.key === values.key)) {
        return prevItems;
      } else {
        return [
          ...prevItems,
          {
            key: values.key,
            label:
              values.type === "ProductProfile" ? (
              <Space size={10} className="products-tab">
                <Icon name="products" className="products-icon" width={27} height={27} />
                <Text>
                  {values?.number}
                  <br/>
                  <small>Product</small>
                </Text>
              </Space>
              ) : (
                <Space size={10} className="products-tab">
                  <Icon name="product-level-icon" className="products-icon" width={24} height={24} />
                  <Text>
                    L{values.bomLevel}-{values?.manufacturerItemNumber}
                    <br/>
                    <small>{values.productNumber}</small>
                  </Text>
                </Space>
              ),
            type: values.type,
            children:
              values.type === "ProductProfile" ? (
                <ProductProfile
                  productData={values}
                  onPreviewClick={handlePreviewClick}
                />
              ) : (
                <SubAssemblies
                  componentData={values}
                  onPreviewClick={handlePreviewClick}
                />
              ),
            closable: true,
          },
        ];
      }
    });
    setLocalStorage("setActiveKey", values.key);
    setActiveKey(values.key);
  };

  const onEdit = (targetKey: any, action: any) => {
    if (action === "remove") {
      const newTabItems = tabItems.filter((tab) => tab.key !== targetKey);
      const targetIndex = tabItems.findIndex((pane) => pane.key === targetKey);
      if (newTabItems.length && targetKey === activeKey) {
        const { key } =
          newTabItems[
            targetIndex === newTabItems.length ? targetIndex - 1 : targetIndex
          ];
        setActiveKey(key);
        setLocalStorage("setActiveKey", key);
      }
      setTabItems(newTabItems);
      const storedTabItems = getLocalStorage("tabItems") || [];
      const filteredValue = storedTabItems.filter(
        (item: { key: string }) => item.key !== targetKey
      );
      setLocalStorage("tabItems", filteredValue);
    }
  };

  useEffect(() => {
    const storageTabData = getLocalStorage("tabItems") || [];
    if (storageTabData.length) {
      setActiveKey(getLocalStorage("setActiveKey"));
      setProduct(storageTabData);
      setTabItems([
        {
          key: "Products",
          label: (
            <>
              <Text>Products</Text>
            </>
          ),
          type: "Products",
          children: <ProductList onPreviewClick={handlePreviewClick} />,
          closable: false,
        },
        ...storageTabData.map((item: any) => ({
          key: item.key,
          label:
            item.type === "ProductProfile" ? (
              <Space size={10} className="products-tab">
                <Icon name="products" className="products-icon" width={27} height={27} />
                <Text>
                  {item?.number}
                  <br/>
                  <small>Product</small>
                </Text>
              </Space>
            ) : (
              <Space size={10} className="products-tab">
                <Icon name="product-level-icon" className="products-icon" width={27} height={27} />
                <Text>
                L{item?.bomLevel}-{item?.manufacturerItemNumber}
                <br/>
                <small>{item?.productNumber}</small>
                </Text>
              </Space>
            ),
          type: item.type,
          children:
            item.type === "ProductProfile" ? (
              <ProductProfile
                productData={item}
                onPreviewClick={handlePreviewClick}
              />
            ) : (
              <SubAssemblies
                componentData={item}
                onPreviewClick={handlePreviewClick}
              />
            ),
          closable: true,
        })),
      ]);
    } else {
      setActiveKey("Products");
      setProduct([]);
      setTabItems([
        {
          key: "Products",
          label: (
            <>
              <Text>Products</Text>
            </>
          ),
          type: "Products",
          children: <ProductList onPreviewClick={handlePreviewClick} />,
          closable: false,
        },
      ]);
    }
  }, []);

  return (
    <Tabs
      className={`tabs-nav-wrap ${tabItems.length > 1 ? '' : 'hidden'}`}
      type="editable-card"
      defaultActiveKey="Products"
      activeKey={activeKey}
      onChange={handleTabChange}
      items={tabItems}
      onEdit={onEdit}
      hideAdd
      size="small"
    />
  );
};

export default Products;
