import React, { useState } from "react";
import { Modal, Button } from "antd";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { uploadExtendedMineralsDocument } from "../../redux/actions/extendedMineralsActions";
import { getLocalStorage, setLocalStorage } from "../../utils/localStore";
import dayjs, { Dayjs } from "dayjs";
import { Tour } from "antd";
import type { TourProps } from "antd";
import { useRef } from "react";
import Conflict3 from "../../assets/supplier portal/emrt/emrt-3.png";
import Conflict4 from "../../assets/supplier portal/emrt/emrt-4.png";
import Conflict5 from "../../assets/supplier portal/emrt/emrt-5.png";
import Conflict6 from "../../assets/supplier portal/emrt/emrt-6.png";
import Conflict7 from "../../assets/supplier portal/emrt/emrt-7.png";
import Conflict8 from "../../assets/supplier portal/emrt/emrt-8.png";
import Support1 from "../../assets/supplier portal/support/1.png";
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";

interface ExcelModalProps {
  supplier: string;
  open: boolean;
  onCancel: () => void;
  importButtonRef?: any;
  showModal?: () => void;
  show?: any;
  setShow?: any;
}

interface Declaration {
  question: string;
  name: string;
  state: null | string;
  comments: null | string;
}

interface Policy {
  question: string;
  name: string;
  state: null | string;
  comments: null | string;
}
interface Smelter {
  number: string;
  metal: string;
  lookUp: string;
  sourceOfIdentificationNumber: string;
  address: {
    country: string;
    city: string;
    state: string;
  };
}
interface Products {
  manufacturer: {
    itemNumber: string;
    name: string;
  };
  comments: string;
}

interface FormGenerate {
  emrtVersion: null | string;
  company: {
    name: string;
    declarationScope: string;
    declarationDescription: string;
    uniqueId: string;
    uniqueIdAuthority: string;
    address: string;
    contact: {
      name: string;
      emailAddress: string;
      phoneNumber: string;
    };
    authorizer: {
      name: string;
      title: string;
      emailAddress: string;
      phoneNumber: string;
      effectiveDate: Dayjs | null;
    };
  };
  declaration: Declaration[];
  declarationScope: Declaration[];
  policy: Policy[];
  smelter: Smelter[];
  products: Products[];
  rawFileName: string;
  templateType: string;
}

const ImportEMRT: React.FC<ExcelModalProps> = ({
  open,
  onCancel,
  supplier,
  importButtonRef,
  showModal,
  show,
  setShow,
}) => {
  const navigate = useNavigate();
  const [formGenerate, setFormGenerate] = useState<FormGenerate | null>(null);
  const [file, setfile] = useState<File | null>(null);
  const [fileReadError, setFileReadError] = useState<string | null>(null);
  const [islatestVersion, setIslatestVersion] = useState<boolean>(false);

  const handleFileUpload = (file: File) => {
    setFileReadError(null);
    setIslatestVersion(false);
    setfile(file);
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const data = e.target?.result;
      try {
        if (data) {
          const workbook = XLSX.read(data, { type: "binary" });
          const Revisionworksheet = workbook.Sheets["Revision"];

          const Declarationworksheet = workbook.Sheets["Declaration"];

          const Smelterworksheet = workbook.Sheets["Smelter List"];

          const Productworksheet = workbook.Sheets["Product List"];

          if (!Revisionworksheet["!ref"]) {
            return;
          }
          const Range = XLSX.utils.decode_range(Revisionworksheet["!ref"]);
          let secondLastValue;

          let nonEmptyCount = 0;
          for (let rowNum = Range.e.r; rowNum >= Range.s.r; rowNum--) {
            const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: 1 });
            const cell = Revisionworksheet[cellAddress];

            if (
              cell &&
              cell.v !== undefined &&
              cell.v !== null &&
              cell.v !== ""
            ) {
              nonEmptyCount++;

              if (nonEmptyCount === 2) {
                secondLastValue = cell.v;
                break;
              }
            }
          }

          if (!Smelterworksheet["!ref"]) {
            throw new Error("Worksheet range is not defined");
          }

          const range = XLSX.utils.decode_range(Smelterworksheet["!ref"]);

          const Smelterdata: Smelter[] = [];

          for (let rowNum = 4; rowNum <= range.e.r; rowNum++) {
            const row: Smelter = {
              number: "",
              metal: "",
              lookUp: "",
              sourceOfIdentificationNumber: "",
              address: {
                country: "",
                city: "",
                state: "",
              },
            };

            for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
              const cellAddress = { r: rowNum, c: colNum };
              const cellRef = XLSX.utils.encode_cell(cellAddress);
              const cell = Smelterworksheet[cellRef];

              if (cell && cell.v !== undefined) {
                switch (colNum) {
                  case 0:
                    row.number = cell.v;
                    break;
                  case 5:
                    if (row.number === "") {
                      row.number = cell.v;
                    }
                    break;
                  case 1:
                    row.metal = cell.v;
                    break;
                  case 2:
                    row.lookUp = cell.v;
                    break;
                  case 6:
                    row.sourceOfIdentificationNumber = cell.v;
                    break;
                  case 4:
                    row.address.country = cell.v;
                    break;
                  case 8:
                    row.address.city = cell.v;
                    break;
                  case 9:
                    row.address.state = cell.v;
                    break;
                  default:
                    break;
                }
              }
            }

            if (
              row.number ||
              row.metal ||
              row.lookUp ||
              row.address.country ||
              row.address.city ||
              row.address.state ||
              row.sourceOfIdentificationNumber
            ) {
              Smelterdata.push(row);
            } else {
              break;
            }
          }
          if (!Productworksheet["!ref"]) {
            throw new Error("Worksheet range is not defined");
          }

          const productrange = XLSX.utils.decode_range(
            Productworksheet["!ref"]
          );
          const Productdata: Products[] = [];
          for (let rowNum = 5; rowNum <= productrange.e.r; rowNum++) {
            const row: Products = {
              manufacturer: {
                itemNumber: "",
                name: "",
              },
              comments: "",
            };

            for (
              let colNum = productrange.s.c + 1;
              colNum <= productrange.e.c;
              colNum++
            ) {
              const cellAddress = { r: rowNum, c: colNum };
              const cellRef = XLSX.utils.encode_cell(cellAddress);
              const cell = Productworksheet[cellRef];

              if (cell && cell.v !== undefined) {
                if (colNum === 1) {
                  row.manufacturer.itemNumber = cell.v;
                } else if (colNum === 2) {
                  row.manufacturer.name = cell.v;
                } else if (colNum === 3) {
                  row.comments = cell.v;
                }
              } else {
                break;
              }
            }

            if (
              row.manufacturer.itemNumber ||
              row.manufacturer.name ||
              row.comments
            ) {
              Productdata.push(row);
            } else {
              break;
            }
          }
          const excelDateToJSDate = (excelDate: number): Date => {
            const date = new Date(Date.UTC(1900, 0, excelDate - 1));
            return date;
          };
          const isISO8601Format = (value: string): boolean => {
            return (
              /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z/.test(value) ||
              /\d{4}-\d{2}-\d{2}/.test(value)
            );
          };
          const cellValue = Declarationworksheet["D22"]?.v || null;
          const capitalizeFirstLetter = (string: any) => {
            return (
              string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
            );
          };

          const extractedData = {
            templateType: Declarationworksheet["D2"]?.v?.trim(),
            emrtVersion: secondLastValue || null,
            company: {
              name: Declarationworksheet["D8"]?.v?.trim() || null,
              declarationScope: Declarationworksheet["D9"]?.v?.trim() || null,
              declarationDescription:
                Declarationworksheet["D10"]?.v?.trim() || null,
              uniqueId: Declarationworksheet["D12"]?.v?.trim() || null,
              uniqueIdAuthority: Declarationworksheet["D13"]?.v?.trim() || null,
              address: Declarationworksheet["D14"]?.v?.trim() || null,
              contact: {
                name: Declarationworksheet["D15"]?.v?.trim() || null,
                emailAddress: Declarationworksheet["D16"]?.v?.trim() || null,
                phoneNumber: Declarationworksheet["D17"]?.v?.trim() || null,
              },
              authorizer: {
                name: Declarationworksheet["D18"]?.v?.trim() || null,
                title: Declarationworksheet["D19"]?.v?.trim() || null,
                emailAddress: Declarationworksheet["D20"]?.v?.trim() || null,
                phoneNumber: Declarationworksheet["D21"]?.v?.trim() || null,
                effectiveDate: isISO8601Format(cellValue)
                  ? dayjs(cellValue)
                  : dayjs(excelDateToJSDate(cellValue)),
              },
            },
            declaration: [
              {
                question:
                  "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?",
                name: "Cobalt",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D26"]?.v) || null,
                comments: Declarationworksheet["G26"]?.v || null,
              },
              {
                question:
                  "Is any of the cobalt or natural mica intentionally added or used in the product(s) or in the production process?",
                name: "Mica",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D27"]?.v) || null,
                comments: Declarationworksheet["G27"]?.v || null,
              },
              {
                question:
                  "Does any cobalt or natural mica remain in the product(s)?",
                name: "Cobalt",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D32"]?.v) || null,
                comments: Declarationworksheet["G32"]?.v || null,
              },
              {
                question:
                  "Does any cobalt or natural mica remain in the product(s)?",
                name: "Mica",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D33"]?.v) || null,
                comments: Declarationworksheet["G33"]?.v || null,
              },
            ],
            declarationScope: [
              {
                question:
                  "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)",
                name: "Cobalt",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D38"]?.v) || null,
                comments: Declarationworksheet["G38"]?.v || null,
              },
              {
                question:
                  "Do any of the smelters or processors in your supply chain source the cobalt or natural mica from conflict-affected and high-risk areas? (OECD Due Diligence Guidance, see definitions tab)",
                name: "Mica",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D39"]?.v) || null,
                comments: Declarationworksheet["G39"]?.v || null,
              },
              {
                question:
                  "Does 100 percent of the cobalt originate from recycled or scrap sources?",
                name: "Cobalt",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D44"]?.v) || null,
                comments: Declarationworksheet["G44"]?.v || null,
              },
              {
                question:
                  "What percentage of relevant suppliers have provided a response to your supply chain survey?",
                name: "Cobalt",
                state: Declarationworksheet["D50"]?.v || null,
                comments: Declarationworksheet["G50"]?.v || null,
              },
              {
                question:
                  "What percentage of relevant suppliers have provided a response to your supply chain survey?",
                name: "Mica",
                state: Declarationworksheet["D51"]?.v || null,
                comments: Declarationworksheet["G51"]?.v || null,
              },
              {
                question:
                  "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?",
                name: "Cobalt",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D56"]?.v) || null,
                comments: Declarationworksheet["G56"]?.v || null,
              },
              {
                question:
                  "Have you identified all of the smelters or processors supplying the cobalt or natural mica to your supply chain?",
                name: "Mica",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D57"]?.v) || null,
                comments: Declarationworksheet["G57"]?.v || null,
              },
              {
                question:
                  "Has all applicable smelter or processor information received by your company been reported in this declaration?",
                name: "Cobalt",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D62"]?.v) || null,
                comments: Declarationworksheet["G62"]?.v || null,
              },
              {
                question:
                  "Has all applicable smelter or processor information received by your company been reported in this declaration?",
                name: "Mica",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D63"]?.v) || null,
                comments: Declarationworksheet["G63"]?.v || null,
              },
            ],
            policy: [
              {
                question:
                  "A. Have you established a responsible minerals sourcing policy?",
                name: "Point A",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D69"]?.v) || null,
                comments: Declarationworksheet["G69"]?.v || null,
              },
              {
                question:
                  "B. Is your responsible minerals sourcing policy publicly available on your website? (Note – If yes, the user shall specify the URL in the comment field.)",
                name: "Point B",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D71"]?.v) || null,
                comments: Declarationworksheet["G71"]?.v || null,
              },
              {
                question:
                  "C. Do you require your direct suppliers to source cobalt from smelters or natural mica from processors whose due diligence practices have been validated by an independent third-party audit program?",
                name: "Point C",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D74"]?.v) || null,
                comments: Declarationworksheet["G74"]?.v || null,
              },
              {
                question:
                  "C. Do you require your direct suppliers to source cobalt from smelters or natural mica from processors whose due diligence practices have been validated by an independent third-party audit program?",
                name: "Point C",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D75"]?.v) || null,
                comments: Declarationworksheet["G75"]?.v || null,
              },
              {
                question:
                  "D. Have you implemented due diligence measures for responsible sourcing?",
                name: "Point D",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D77"]?.v) || null,
                comments: Declarationworksheet["G77"]?.v || null,
              },
              {
                question:
                  "E. Does your company conduct cobalt and/or natural mica supply chain survey(s) of your relevant supplier(s)?",
                name: "Point E",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D79"]?.v) || null,
                comments: Declarationworksheet["G79"]?.v || null,
              },
              {
                question:
                  "F. Do you review due diligence information received from your suppliers against your company’s expectations?",
                name: "Point F",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D81"]?.v) || null,
                comments: Declarationworksheet["G81"]?.v || null,
              },
              {
                question:
                  "G. Does your review process include corrective action management?",
                name: "Point G",
                state:
                  capitalizeFirstLetter(Declarationworksheet["D83"]?.v) || null,
                comments: Declarationworksheet["G83"]?.v || null,
              },
            ],
            smelter: Smelterdata,
            products: Productdata,
            rawFileName: file.name,
          };

          setFormGenerate(extractedData);
          if (parseFloat(secondLastValue) === 1.3) {
            setIslatestVersion(true);
          }
        }
      } catch (error) {
        setFileReadError(
          `We are unable to read the file and extract EMRT. Please try fixing errors and re-upload. ${
            (error as Error).message
          }`
        );
      }
    };
    fileReader.onerror = (event) => {
      setFileReadError(
        `Error reading the file: ${
          fileReader.error?.message || "Unknown error"
        }`
      );
    };
    fileReader.readAsBinaryString(file);
  };

  const fetchData = async () => {
    try {
      if (file) {
        await uploadExtendedMineralsDocument({
          file: file,
          foldername: "extended-minerals",
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = () => {
    if (getLocalStorage("accessToken")) {
      fetchData();
      setLocalStorage("supplier", supplier);
      setLocalStorage("extendedMinerals", formGenerate);
      return navigate("../generator", { replace: true });
    } else {
      setLocalStorage("supplier", supplier);
      setLocalStorage("extendedMinerals", formGenerate);
      return navigate("generator");
    }
  };

  const handleCancel = () => {
    setfile(null);
    const inputElement = document.querySelector(
      'input[type="file"]'
    ) as HTMLInputElement | null;
    if (inputElement) {
      inputElement.value = "";
    }
    setFileReadError(null);
    onCancel();
  };

  const inputRef = useRef(null);
  const continueRef = useRef(null);

  const steps: TourProps["steps"] = [
    {
      title:
        "Step 1: Import the Latest EMRT File",
      description: <p>Click the <b>‘Import EMRT’</b> button to submit the latest version of your completed Extended Minerals Reporting Template (EMRT).</p>,
      target: () => importButtonRef?.current,
      placement: "right",
      nextButtonProps: { children: "Next", onClick: showModal },
    },
    {
      title: "Step 2: Upload Your EMRT",
      description: (
        <>
          <p>In the <b>Upload EMRT File</b> pop-up window, click <b>‘Choose file’</b> to select your updated EMRT file. Then, press Continue.</p>
          <p><b>Note:</b> Only the latest EMRT versions recommended by the Responsible Minerals Initiative (RMI) are accepted. If you have an older version, download the latest form from the RMI website (link available in the Introduction tab), complete the template, and resubmit it.</p>
        </>
      ),
      cover: <img alt="" src={Conflict3} />,
      placement: "right",
      target: () => inputRef?.current,
      prevButtonProps: { children: "Previous", onClick: onCancel },
    },
    {
      title: "Step 3: Automatic Data Capture",
      description: (
        <>
          <p>
          Once your EMRT file is successfully imported, the tool will automatically extract the necessary information from your file. You’ll then see a <b>‘Save & Close’</b> button.
          </p>
        </>
      ),
      cover: <img alt="" src={Conflict4} />,
      placement: "center",
      nextButtonProps: { children: "Next", onClick: onCancel },
    },
    {
      title: "Review or Submit",
      description: (
        <p>Click <b>‘Save & Close’</b> to finalize the process. If you want to review the data first, click on the relevant tabs (e.g., Company Information, Declaration, Declaration Scope, Policy, Smelter List) to validate the information before submitting.</p>
      ),
      cover: <img alt="" src={Conflict5} />,
      placement: "center",
    },
    {
      title:
        "Step 5: Navigate Between Tabs",
      description: "You can navigate through the tabs using the Next or Back buttons located at the bottom left corner of each tab.",
      cover: <img alt="" src={Conflict6} />,
      placement: "center",
    },
    {
      title: "Step 6: Verify and Submit",
      description: (
        <p>
          After reviewing and verifying the EMRT data, click <b>‘Save & Close’</b> to complete your submission.
        </p>
      ),
      cover: <img alt="" src={Conflict7} />,
      placement: "center",
    },
    {
      title:
        "Step 7: Important: Get Help If Needed",
      description: (
          <>
            <p>Double-check your upload for completeness. If you need assistance or have questions:</p>
            <ul>
              <li><b>Compliance/Part Queries:</b> Click Contact Support</li>
              <li><b>Technical Issues/Feedback:</b> Click Technical Support</li>
            </ul>
            <p>Our support team is here to help you via phone or email.</p>
          </>
        ),
      cover: <img alt="" src={Conflict8} />,
      placement: "center",
    },
  ];
  return (
    <>
      <Tour
        open={show}
        onClose={() => setShow(false)}
        steps={steps}
        disabledInteraction={true}
      />
      <Modal
        open={open}
        centered
        title="Upload EMRT File"
        onCancel={handleCancel}
        footer={[
          <Button
            key="onCancel"
            onClick={handleCancel}
            data-testid="cancel-import-emrt-button"
          >
            Cancel
          </Button>,
          <Button
            key="continue"
            type="primary"
            onClick={handleClick}
            disabled={!file || fileReadError !== null || !islatestVersion}
            data-testid="continue-import-emrt-button"
            ref={continueRef}
          >
            Continue
          </Button>,
        ]}
      >
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={(e) => {
            const selectedFile = e.target.files && e.target.files[0];
            if (selectedFile) {
              handleFileUpload(selectedFile);
            }
          }}
          ref={inputRef}
        />
        {formGenerate?.emrtVersion &&
          file &&
          !fileReadError &&
          parseFloat(formGenerate.emrtVersion) !== 1.3 && (
            <p style={{ color: "red", marginTop: "10px", marginBottom: 0 }}>
              The uploaded EMRT version appears to be outdated.
            </p>
          )}
        <p style={{ marginTop: "10px" }}>
          EMRT version. 1.3 or higher is Recommended for the Reporting Year.
        </p>
        {fileReadError && <p style={{ color: "red" }}>{fileReadError}</p>}{" "}
        {file &&
          formGenerate?.emrtVersion &&
          parseFloat(formGenerate?.emrtVersion) !== 1.3 &&
          !fileReadError &&
          formGenerate?.templateType?.toLowerCase().includes("emrt") && (
            <Checkbox
              checked={islatestVersion}
              onChange={(e: CheckboxChangeEvent) => {
                setIslatestVersion(e.target.checked);
              }}
            >
              Convert uploaded EMRT to latest EMRT version
            </Checkbox>
          )}
      </Modal>
    </>
  );
};

export default ImportEMRT;
