import React, { useState, useRef, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useAppDispatch } from "../../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import {
  Space,
  Typography,
  Button,
  Steps,
  Form,
  Input,
  Select,
  Card,
  DatePicker,
  Popconfirm,
  Alert,
  Row,
  Col,
} from "antd";
import {
  checkLists,
  listCampaignsList,
} from "../../../../redux/actions/CampaignManager/listsAction";
import {
  createCampaignManager,
  readCampaignManager,
} from "../../../../redux/actions/CampaignManager/campaignManagerAction";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../../../utils/localStore";
import { Campaign } from "../../../../utils/types/CampaignManager/campaignManager";
import dayjs, { Dayjs } from "dayjs";
import ErrorBoundary from "../../../../utils/errorBoundary";

const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Meta } = Card;

interface SelectedOptions {
  type: string;
  name: string;
  value: { name: string };
}

const CampaignEdit: React.FC = () => {
  const { id } = useParams<{ id: any }>();
  const [form] = Form.useForm();

  const [campaign, setCampaignData] = useState<Campaign>();
  const [campaignStep, setCampaignStep] = useState<number>(0);
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions[]>([]);
  const dispatch = useAppDispatch();
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [sendGridCampaignId, setSendGridCampaignId] = useState<string>("");
  const editorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checkListUpdated, setCheckListUpdated] = useState(false);

  const user = getLocalStorage("user");
  const letterOfAuthorization = user.letterOfAuthorisation;

  const listOpts = listOptions.filter((o) => campaign?.lists?.includes(o._id));
  form.setFieldValue(
    "lists",
    listOpts?.map((e: any) => e?.name)
  );

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    sorter: {
      field: "createdAt",
      order: "ascend",
    },
  });

  const regulations = getRegulationsFromLocalStorage() ?? [];

  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const multipleSelect = () => {
    const value: SelectedOptions[] = [];

    if (permissions?.read?.includes("conflictminerals")) {
      value.push({
        type: "Conflict Minerals",
        name: "Conflict Minerals",
        value: { name: "conflict" },
      });
    }

    if (permissions?.read?.includes("materials")) {
      value.push({
        type: "FMD",
        name: "FMD (Materials)",
        value: { name: "materials" },
      });
    }

    if (permissions?.read?.includes("extendedminerals")) {
      value.push({
        type: "Extended Minerals",
        name: "Extended Minerals",
        value: { name: "extended" },
      });
    }

    if (permissions?.read?.includes("scip")) {
      value.push({
        type: "SCIP (WFD)",
        name: "SCIP (WFD)",
        value: { name: "scip" },
      });
    }

    if (permissions?.read?.includes("regulations")) {
      regulations?.forEach((val: any) =>
        value.unshift({
          type: "Regulation",
          name: `${val.name}`,
          value: { name: val.regulationNumber },
        })
      );
    }

    return value;
  };
  const options = multipleSelect();

  const handleRequestedDataChange = (values: any) => {
    setSelectedOptions(values);
    form.setFieldValue("selectOptions", values);
  };

  const onSaveCampaign = async () => {
    try {
      if (campaignStep === 0) {
        (await validateForm1()) && submitCampaign();
      } else if (campaignStep === 1) {
        (await validateForm2()) && submitCampaign();
      }
    } catch (error) {
      return;
    }
  };

  const validateForm1 = async () => {
    try {
      await form.validateFields(["name", "lists", "selectOptions"]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const validateForm2 = async () => {
    try {
      await form.validateFields(["subject", "message"]);
      return true;
    } catch (error) {
      return false;
    }
  };

  const submitCampaign = () => {
    setLoading(true);
    let message = editorRef?.current?.getContent();
    const values = form.getFieldsValue();
    const listOpts = values?.lists?.map((e: any) =>
      listOptions.find((o: any) => o?.name === e)
    );
    const requestedOpts =
      values?.selectOptions?.map((e: any) =>
        options.find((o: any) => o?.value?.name === e)
      ) || [];
    const windowUrl = window.location.href.split("/");
    const link = `${windowUrl[0]}//${
      windowUrl[2]
    }/supplier/{{unique_name}}/${window.btoa(user?.tenantNumber)}/${window.btoa(
      sendGridCampaignId ? sendGridCampaignId : id
    )}`;

    message =
      message &&
      message?.replace(
        "{{supplier_portal_link}}",
        `<a href='${link}'>${link}</a>`
      );

    message =
      letterOfAuthorization && message
        ? message.replace(
            "{{letter_of_authorization}}",
            `<a href='${link}/letterofauthorisation/${letterOfAuthorization
              .replace(".pdf", "")
              .replace(
                / /g,
                "%20"
              )}'>${link}/letterofauthorisation/${letterOfAuthorization
              .replace(".pdf", "")
              .replace(/ /g, "%20")}</a>`
          )
        : message;

    const formValues = {
      name: values?.name,
      lists: listOpts?.map((e: any) => ({
        _id: e._id,
      })),
      regulations: requestedOpts
        ?.filter((e: any) => e?.type === "Regulation")
        .map((e: any) => e?.value?.name),
      materials: requestedOpts.find((v: any) => v.type === "FMD")
        ? "Yes"
        : "No",
      conflict: requestedOpts.find((v: any) => v.type === "Conflict Minerals")
        ? "Yes"
        : "No",
      extended: requestedOpts.find((v: any) => v.type === "Extended Minerals")
        ? "Yes"
        : "No",
      scip: requestedOpts.find((v: any) => v.type === "SCIP (WFD)")
        ? "Yes"
        : "No",
      campaignStep: campaignStep + 1,
      sendgridCampaignId: id ? id : null,
      subject: values?.subject,
      message: message,
    };

    dispatch(createCampaignManager(formValues)).then((response) => {
      setSendGridCampaignId(response?.sendgridCampaignId);
      setCampaignStep(campaignStep + 1);
      setLoading(false);
    });
  };

  const prevStep = () => {
    setCampaignStep(campaignStep - 1);
  };

  const onClose = () => {
    navigate(-1);
  };

  const disabledDate = (current: Dayjs | undefined): boolean => {
    return !!current && current.isBefore(dayjs().startOf("day"));
  };

  const disabledTime = (current: Dayjs | undefined, type: "start" | "end") => {
    if (!current || !current.isSame(dayjs(), "day")) {
      return {};
    }

    const currentHour = dayjs().hour();
    const currentMinute = dayjs().minute();

    return {
      disabledHours: () =>
        Array.from(
          { length: type === "start" ? currentHour : 24 - currentHour - 1 },
          (_, index) => (type === "start" ? index : currentHour + 1 + index)
        ),
      disabledMinutes: () =>
        Array.from(
          { length: type === "start" ? currentMinute : 60 - currentMinute - 1 },
          (_, index) => (type === "start" ? index : currentMinute + 1 + index)
        ),
    };
  };

  const scheduleCampaign = () => {
    setLoading(true);
    const values = form.getFieldsValue();
    const windowUrl = window.location.href.split("/");
    const link = `${windowUrl[0]}//${
      windowUrl[2]
    }/supplier/{{unique_name}}/${window.btoa(user?.tenantNumber)}/${window.btoa(
      sendGridCampaignId ? sendGridCampaignId : id
    )}`;
    const listOpts = values?.lists?.map((e: any) =>
      listOptions.find((o: any) => o?.name === e)
    );
    const currentDate = new Date();
    const startDate = new Date(
      values?.dateRange?.map((date: any) => date)[0].$d
    );
    const diff = startDate.getTime() - currentDate.getTime();
    const setTime =
      diff < 120000 ? new Date(new Date().getTime() + 1 * 120000) : startDate;

    const formValues = {
      lists: listOpts.map((e: any) => ({
        _id: e._id,
      })),
      campaignStep: campaignStep + 1,
      sendgridCampaignId: id ? id : null,
      startDate: setTime.toISOString(),
      endDate: new Date(
        values?.dateRange?.map((date: any) => date)[1].$d
      ).toISOString(),
      link: link,
    };

    dispatch(createCampaignManager(formValues)).then((response) => {
      setSendGridCampaignId(response?.sendgridCampaignId);
      setCampaignStep(campaignStep + 1);
      setOpen(false);
      navigate(-1);
      setLoading(false);
    });
  };

  const onCancel = () => {
    setOpen(false);
  };

  const actionCards = [
    <Space key="1" style={{ display: "flex", justifyContent: "end" }}>
      {campaignStep > 0 && (
        <Button
          style={{ margin: "0 8px" }}
          type="primary"
          onClick={prevStep}
          data-testid="previous-campaign-button"
        >
          Previous
        </Button>
      )}
      {campaignStep < 2 && (
        <Button
          style={{ background: "#045B7C", color: "white", marginRight: 8 }}
          onClick={onSaveCampaign}
          loading={loading}
          data-testid="save-campaign-button"
        >
          Save & Continue
        </Button>
      )}
      {campaignStep <= 2 && (
        <Button
          style={{ marginRight: 8 }}
          type="text"
          onClick={onClose}
          data-testid="close-campaign-button"
        >
          Close
        </Button>
      )}
      {campaignStep === 2 && (
        <>
          <Popconfirm
            title={"Schedule Campaign"}
            description={"Are you certain about scheduling this campaign ?"}
            onConfirm={scheduleCampaign}
            okText="Yes"
            open={open}
            cancelText="No"
            onCancel={onCancel}
            placement="topLeft"
          >
            <Button
              style={{ background: "#045B7C", color: "white", marginRight: 8 }}
              onClick={form.submit}
              disabled={checkListUpdated === true}
              loading={loading}
              data-testid="submit-campaign-button"
            >
              Submit
            </Button>
          </Popconfirm>
        </>
      )}
    </Space>,
  ];

  const onSearch = (value: any) => {
    readlists(
      { pageSize: 10, current: 1, total: 0 },
      {
        query: value?.key === "Enter" ? value?.target?.value : value,
      },
      {
        field: tableParams.sorter.field,
        order: tableParams.sorter.order,
      }
    );
  };

  const readlists = (pagination: any, filters: any, sorter: any) => {
    dispatch(
      listCampaignsList({
        limit: pagination.pageSize,
        page: pagination.current,
        sortBy: sorter.column ? sorter.field : ["createdAt"],
        sortDesc: sorter.order === "descend" ? true : false,
        softDelete: false,
        search: filters.query ? filters.query : "",
      })
    ).then((response: any) => {
      setListOptions(
        response.lists?.map((e: any) => ({
          value: e.name,
          ...e,
        }))
      );
      setTableParams({
        pagination: { ...pagination },
        sorter: sorter,
      });
    });
  };

  const readListsFromSendGrid = (values: any) => {
    const listOpts = listOptions.filter((o) => values?.includes(o.value));
    dispatch(
      checkLists({
        lists: listOpts.map((e: any) => ({
          _id: e._id,
        })),
      })
    ).then((response) => {
      const checkContactsAttached = response?.contacts
        ? response?.contacts
        : false;
      const checkListUpdated = response?.importContactsStatus
        ? response?.importContactsStatus
        : false;
      setCheckListUpdated(checkListUpdated);
      if (checkContactsAttached) {
        form.setFields([
          {
            name: "lists",
            errors: [
              "Campaign survey list attached does not include any contacts, please update the survey list.",
            ],
          },
        ]);
      }
      // if(checkListUpdated) {
      //   form.setFields([
      //     {
      //       name: "lists",
      //       errors: ["Campaign survey list attached is not updated, please update the survey list."],
      //     },
      //   ]);
      // }
    });
  };

  const onFormSubmit = () => {
    if (campaignStep === 2) {
      if (!form.validateFields(["dateRange"])) return;
    }
    const values = form.getFieldsValue();
    const startDate = new Date(
      values?.dateRange?.map((date: any) => date)[0].$d
    );
    const endDate = new Date(values?.dateRange?.map((date: any) => date)[1].$d);
    const currentDate = new Date();
    if (startDate < currentDate) {
      form.setFields([
        {
          name: "dateRange",
          errors: ["Start date cannot be earlier than current date"],
        },
      ]);
    }
    if (endDate < startDate) {
      form.setFields([
        {
          name: "dateRange",
          errors: ["End date cannot be earlier than start date"],
        },
      ]);
    }
    setOpen(true);
  };

  const readSingleCampaignData = () => {
    setLoading(true);
    dispatch(
      readCampaignManager({
        id: id,
      })
    )
      .then((response: any) => {
        setCampaignData(response?.campaign);
        setCampaignStep(response?.campaign?.campaignStep);
        setLoading(false);
        const selectData = [
          ...(response?.campaign?.regulations ?? []).map((e: any) => e),
          response?.campaign?.materials === "Yes" && "materials",
          response?.campaign?.conflict === "Yes" && "conflict",
          response?.campaign?.extended === "Yes" && "extended",
          response?.campaign?.scip === "Yes" && "scip",
        ].filter((value) => value !== false);
        selectData?.length && form.setFieldValue("selectOptions", selectData);

        if (response?.campaign && selectData.length) {
          form.setFieldsValue({ selectOptions: selectData });
        }
        form.setFieldValue("name", response?.campaign?.name);
        form.setFieldValue("subject", response?.campaign?.subject);
        form.setFieldValue("message", response?.campaign?.message);
        (response?.campaign?.startDate || response?.campaign?.endDate) &&
          form.setFieldValue("dateRange", [
            response?.campaign?.startDate &&
              dayjs(response?.campaign?.startDate),
            response?.campaign?.endDate && dayjs(response?.campaign?.endDate),
          ]);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    readSingleCampaignData();
    readlists(
      { pageSize: 10, current: 1 },
      { query: "" },
      {
        field: tableParams.sorter.field,
        order: tableParams.sorter.order,
      }
    );
  }, []);

  return (
    <ErrorBoundary>
      <Card
        actions={actionCards}
        loading={loading}
        // style={{
        //   width: "1000",
        //   height: "calc(100vh - 380px)",
        //   overflowX: 'auto',
        //   overflowY: 'auto'
        // }}
      >
        <Steps
          items={[
            {
              title: "Send To & Request Data",
            },
            {
              title: "Email Content",
            },
            {
              title: "Schedule Campaign",
            },
          ]}
          current={campaignStep}
        />
        <div style={{ marginTop: "20px" }}>
          <Form
            form={form}
            onFinish={onFormSubmit}
            layout="vertical"
            autoComplete="off"
          >
            <Card style={{ display: campaignStep === 0 ? "block" : "none" }}>
              <div style={{ display: "flex", gap: "20px" }}>
                <div style={{ flex: 1 }}>
                  <Form.Item
                    name="name"
                    label="Campaign Name"
                    rules={[
                      { required: true, message: "Please enter Campaign name" },
                      {
                        pattern: /^([a-zA-Z0-9()-/.,&_@*]+\s?)*$/,
                        message: "Special Characters not allowed!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Item
                    name="lists"
                    label="Lists"
                    rules={[
                      {
                        required: true,
                        message: "Please select from the list",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Type to Search for lists"
                      options={listOptions.map((option) => ({
                        ...option,
                        label: (
                          <>
                            <div>{option.value}</div>
                            <div
                              style={{ fontSize: "smaller", color: "green" }}
                            >
                              {"Total supplier: " + option?.suppliersCount}
                            </div>
                            <div style={{ fontSize: "smaller", color: "red" }}>
                              {"Missing contacts: " +
                                (option?.suppliersCount -
                                  option?.suppliersMissingCount?.filter(
                                    (e: any) => e === true
                                  ).length)}
                            </div>
                          </>
                        ),
                      }))}
                      onSearch={onSearch}
                      onSelect={readListsFromSendGrid}
                      mode="multiple"
                    />
                  </Form.Item>
                </div>
              </div>
              <Form.Item
                name="selectOptions"
                label="Requested data"
                rules={[
                  {
                    required: true,
                    message: "Please select requested data from the list",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  value={selectedOptions}
                  onChange={handleRequestedDataChange}
                >
                  {options.map((option) => (
                    <Select.Option key={option.name} value={option.value.name}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
            <Card style={{ display: campaignStep === 1 ? "block" : "none" }}>
              <Form.Item
                name="subject"
                label="Subject"
                rules={[{ required: true, message: "Subject cannot be null" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="message"
                label="Message"
                rules={[{ required: true, message: "Required" }]}
                hidden={true}
              >
                <Input.TextArea />
              </Form.Item>
              <label style={{ display: "block", marginBottom: "0.5rem" }}>
                * Message
              </label>
              <Editor
                apiKey="eph2salzmox3uyanm02ujyd63j89lg4hp5o450729hkt5xf1"
                onInit={(evt, editor) => {
                  editorRef.current = editor;
                  form.setFieldValue("message", editor.getContent());
                }}
                onChange={(content: any) =>
                  form.setFieldValue("message", content)
                }
                initialValue={campaign?.message}
                init={{
                  height: 400,
                  menubar: false,
                  convert_urls: true,
                  relative_urls: false,
                  remove_script_host: false,
                  setup: function (editor: any) {
                    editor.ui.registry.addButton("compliancebutton", {
                      text: "Supplier Portal Link",
                      tooltip: "Supplier Portal Link",
                      onAction: function () {
                        editor.focus();
                        editor.selection.setContent(
                          " {{supplier_portal_link}} "
                        );
                      },
                    });
                    editor.ui.registry.addButton("firstNameButton", {
                      text: "First Name",
                      tooltip: "First Name",
                      onAction: function () {
                        editor.focus();
                        editor.selection.setContent(" {{first_name}} ");
                      },
                    });
                    editor.ui.registry.addButton("lastNameButton", {
                      text: "Last Name",
                      tooltip: "Last Name",
                      onAction: function () {
                        editor.focus();
                        editor.selection.setContent(" {{last_name}} ");
                      },
                    });
                    editor.ui.registry.addButton("addressButton", {
                      text: "Address",
                      tooltip: "Address",
                      onAction: function () {
                        editor.focus();
                        editor.selection.setContent(" {{address}} ");
                      },
                    });
                    if (letterOfAuthorization) {
                      editor.ui.registry.addButton(
                        "letterOfAuthorizationButton",
                        {
                          text: "Letter of Authorization",
                          tooltip: "Letter of Authorization",
                          onAction: function () {
                            editor.focus();
                            editor.selection.setContent(
                              " {{letter_of_authorization}} "
                            );
                          },
                        }
                      );
                    }
                  },
                  plugins:
                    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen table insertdatetime media table paste code wordcount",
                  toolbar1:
                    // eslint-disable-next-line no-multi-str
                    " undo redo | bold italic underline | \
                      alignleft aligncenter alignright alignjustify | \
                      bullist numlist outdent indent | removeformat preview | advlist autolink lists link image charmap",
                  toolbar2:
                    "compliancebutton firstNameButton lastNameButton addressButton letterOfAuthorizationButton",
                }}
              />
            </Card>
            <Card style={{ display: campaignStep === 2 ? "block" : "none" }}>
              {checkListUpdated === true && (
                <Alert
                  message="Campaign survey list attached is being prepared , please wait for few mins and come back to schedule a campaign."
                  type="warning"
                  showIcon
                  style={{ marginBottom: "2rem" }}
                />
              )}
              <Card style={{ marginBottom: "2rem" }}>
                <Meta
                  title={
                    <div style={{ textAlign: "center" }}>
                      {"Review Campaign-" + form.getFieldValue("name")}
                    </div>
                  }
                  description={
                    <span>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Text strong>Total contacts: </Text>
                          <Text>
                            {listOptions
                              .filter((e) =>
                                form.getFieldValue("lists")?.includes(e.value)
                              )
                              .map(
                                (o: any) =>
                                  o?.suppliersMissingCount.filter((o: any) => o)
                                    .length
                              )
                              .reduce((a: any, b: any) => a + b, 0) +
                              " out of " +
                              listOptions
                                .filter((o) =>
                                  form.getFieldValue("lists")?.includes(o.value)
                                )
                                .map((e: any) => e?.suppliersCount)
                                .reduce((a, b) => a + b, 0) +
                              " will receive the following message."}
                          </Text>
                        </Col>
                        <Col span={24}>
                          <Text strong>Subject: </Text>
                          <Text>{form.getFieldValue("subject")}</Text>
                        </Col>
                        <Col span={24}>
                          <Text strong>Requested Data: </Text>
                          <Text>
                            {options
                              .filter((o: any) =>
                                form
                                  .getFieldValue("selectOptions")
                                  ?.includes(o?.value?.name)
                              )
                              .map((o: any) => (
                                <span key={o.value.name}>{o.name + ", "}</span>
                              ))}
                          </Text>
                        </Col>
                      </Row>
                    </span>
                  }
                />
              </Card>
              <Form.Item
                name="dateRange"
                label="Schedule"
                rules={[
                  { required: true, message: "Please select a date range" },
                ]}
                initialValue={[
                  dayjs().add(5, "minutes"),
                  dayjs().add(3, "months"),
                ]}
              >
                <RangePicker
                  disabled={checkListUpdated === true}
                  disabledDate={(current) => disabledDate(dayjs(current))}
                  disabledTime={(current, type) =>
                    disabledTime(dayjs(current), type as "start" | "end")
                  }
                  showTime={{ format: "HH:mm" }}
                  format="YYYY-MM-DD HH:mm"
                  size="large"
                />
              </Form.Item>
            </Card>
          </Form>
        </div>
      </Card>
    </ErrorBoundary>
  );
};

export default CampaignEdit;
