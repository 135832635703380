import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import { useAppDispatch } from "../../../redux/hooks";
import { validateDossier } from "../../../redux/actions/scipAction";
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

const { Text, Paragraph } = Typography;
const DossierValidation: React.FC<{
  productId: any;
  failCounts: any;
}> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [validationData, setValidationData] = useState<any>([]);
  const [passCount, setPassCount] = useState<number>();
  const [failCount, setFailCount] = useState<number>();
  const [warningCount, setWarningCount] = useState<number>();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchData = () => {
    setLoading(true);
    dispatch(validateDossier({ productId: props.productId })).then(
      (response) => {
        setPassCount(
          response?.filter((e: any) => e.ruleStatus === "PASS")?.length
        );
        setValidationData(
          response
            .map((e: any) => e.checks)
            ?.filter((v: any) => v && v)
            .flat(1)
        );
        const validateValues = response
          .map((e: any) => e.checks)
          ?.filter((v: any) => v && v)
          .flat(1);
        setFailCount(
          validateValues.filter((e: any) => e.level === "FAILURE").length
        );
        setWarningCount(
          validateValues.filter((e: any) => e.level === "WARNING").length
        );
        props.failCounts(
          validateValues.filter((e: any) => e.level === "FAILURE").length === 0
        );
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (isModalOpen) {
      fetchData();
    }
  }, [isModalOpen]);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        Validate Dossier
      </Button>
      <Modal
        title="Validation Report"
        open={isModalOpen}
        loading={loading}
        centered
        maskClosable={false}
        closable={false}
        width={720}
        footer={[
          <Button key="Close" onClick={handleCancel} type="primary">
            Close
          </Button>,
        ]}
      >
        <Card style={{ overflow: "auto", height: "80vh" }}>
          <Space direction="vertical">
            <Text strong>
              <CheckCircleFilled
                style={{ color: "#7cb305", marginRight: "2px" }}
              />
              Total Pass : {passCount}
            </Text>
            <Text strong>
              <CloseCircleFilled
                style={{ color: "#F5222D", marginRight: "2px" }}
              />
              Total Fail : {failCount}
            </Text>
            <Text strong>
              <ExclamationCircleFilled
                style={{ color: "#ffc53d", marginRight: "2px" }}
              />
              Total Warning : {warningCount}
            </Text>
          </Space>
          <Divider />
          {validationData.length ? (
            validationData?.map((e: any, i: any) => (
              <>
                <Row>
                  <Col key={i}>
                    <Space direction="vertical">
                      <Space
                        align="end"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Text strong style={{ color: "#00516E" }}>
                          {e.level === "FAILURE" ? (
                            <CloseCircleFilled
                              style={{ color: "#F5222D", marginRight: "5px" }}
                            />
                          ) : (
                            <ExclamationCircleFilled
                              style={{
                                color: "#ffc53d",
                                marginRight: "5px",
                              }}
                            />
                          )}
                          {e.element?.documentName}
                        </Text>
                        <Space>
                          <Text type="secondary">
                            {e.ruleCheckId?.includes("BR")
                              ? "Business rule (" + e.ruleCheckId + ")"
                              : "Quality check (" + e.ruleCheckId + ")"}
                          </Text>
                        </Space>
                      </Space>
                      <Space style={{ marginLeft: "1rem" }}>
                        <Paragraph> {e.message}</Paragraph>
                      </Space>
                      <Divider />
                    </Space>
                  </Col>
                </Row>
              </>
            ))
          ) : (
            <Text>No Business rule or Quality check failures identified.</Text>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default DossierValidation;
