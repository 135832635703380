import {
  privatePostApi,
  privateGetApi,
  privatePutApi,
  publicGetApi,
  publicPostApi,
} from "../../utils/apiHelper";
import { ScipState } from "../../utils/types/scip";

/**
 * Bulk Operations
 */
export const listScipAnalytics = (payload: any) => () => {
  return new Promise<ScipState>((resolve, reject) => {
    privateGetApi("/scip", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const createScipArticle = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const validateDossier = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/dossier/validate", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createDossier = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/dossier/create", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const listScipComponents = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/scip/components", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const listScipProducts = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/scip/products", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export const readScipProductComponents = (id: string, payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/scip/components/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readScipComponent = (id: string, payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/scip/component/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readScipProductArticle = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/scip/product/" + id, payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const listScipSubstances = (payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicGetApi("/public/scip/reference-substance", payload)
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readScipArticle = (id: string, payload?: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privateGetApi("/scip/articles/" + id, payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readDownloadExport = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/download/export", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readDownloadZip = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/download/zip", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readDownloadPdf = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/download/pdf", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readDownloadStatus = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/download/status", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const readDownloadResult = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/download/result", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createScipComponent = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/component/create", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createScipProduct = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/product/create", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateScip = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    privatePostApi("/scip/update", payload)
      .then((response: any) => {
        if (response && response.status === 200 && response.data) {
          resolve(response.data.data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
