import React from "react";

import { Outlet } from "react-router-dom";

const IPC1752EditorLayout: React.FC = () => {
  return (
    <div style={{ height: "100vh" }}>
      <Outlet />
    </div>
  );
};

export default IPC1752EditorLayout;
