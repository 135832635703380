import React, { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Space,
  Button,
  Drawer,
  AutoComplete,
  Typography,
  Divider,
  DatePicker,
} from "antd";
import type { SelectProps } from "antd";

import { Component } from "../../utils/types/component";
import { useAppDispatch } from "../../redux/hooks";
import {
  createComponent,
  updateComponent,
  getAutoCompleteData,
} from "../../redux/actions/componentsAction";
import { listProducts } from "../../redux/actions/productsAction";
import FileUploader from "../layouts/FileUploader";
import {
  getLocalStorage,
  getRegulationsFromLocalStorage,
} from "../../utils/localStore";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

const { Title } = Typography;
const ComponentForm: React.FC<{
  type: string;
  fetchData: any;
  formData?: Component;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { tenantNumber } = getLocalStorage("user");

  const regulations = getRegulationsFromLocalStorage() ?? [];
  const options: SelectProps["options"] = [];
  const [searchProduct, setSearchProduct] = useState<any[]>([]);
  const [searchComponent, setSearchComponent] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const user = getLocalStorage("user");
  const parseUser = user ? user : null;

  const handleProductSearch = (search?: any) => {
    dispatch(
      listProducts({
        search: true,
        pageNum: 1,
        pageSize: 10,
        searchField: search?.length ? search : "",
        softDelete: false,
        filters: encodeURIComponent(
          JSON.stringify({
            $or: [
              { number: { $regex: search, $options: "i" } },
              { name: { $regex: search, $options: "i" } },
            ],
          })
        ),
      })
    )
      .then((response: any) => {
        setSearchProduct(
          response.products.map((e: any) => ({
            value: e.name,
            ...e,
          }))
        );
      })
      .catch((e) => console.log(e));
  };

  const handleComponentSearch = (search: any) => {
    if (search?.length > 0) {
      dispatch(
        getAutoCompleteData({
          search: true,
          pageNum: 1,
          pageSize: 10,
          query: search,
          softDelete: false,
          filters: encodeURIComponent(
            JSON.stringify({
              $or: [
                {
                  "manufacturer.itemNumber": { $regex: search, $options: "i" },
                },
                { "manufacturer.name": { $regex: search, $options: "i" } },
              ],
            })
          ),
        })
      )
        .then((response: any) => {
          setSearchComponent(
            response?.autoCompleteComponents[0]?.paginatedResults?.map(
              (e: any) => ({
                value: e.manufacturer?.itemNumber,
                ...e,
              })
            )
          );
        })
        .catch((e) => console.log(e));
    }
  };

  regulations?.forEach((regulation: any) => {
    options.push({
      label: regulation.name,
      value: regulation.regulationNumber,
    });
  });

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onFormSubmit = (values: Component) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(
        createComponent({
          ...values,
          productRefs:
            values?.productRefs && values?.productRefs?.length
              ? searchProduct.find((e) => e.value === values?.productRefs)?._id
              : [],
          componentRefs:
            values?.componentRefs && values?.componentRefs?.length
              ? searchComponent.find((e) => e.value === values?.componentRefs)
                  ?._id
              : [],
        })
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(updateComponent({ updates: values }, props.formData._id))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (open && props.type === "create") {
      handleComponentSearch("a");
      handleProductSearch();
    }
  }, [open]);

  return (
    <div>
      <Button
        type={props.type === "create" ? "primary" : "text"}
        onClick={showDrawer}
        title={props.type === "create" ? "New Component" : "Edit Component"}
        data-testid={
          props.type === "create"
            ? "create-component-button"
            : "edit-component-button"
        }
        icon={props.type === "create" ? <PlusOutlined /> : <EditOutlined />}
      >
        {props.type === "create" ? "Add Component" : null}
      </Button>
      <Drawer
        title={props.type === "create" ? "Add Component" : "Edit Component"}
        width={720}
        onClose={onClose}
        open={open}
        styles={{
          body: {
            paddingBottom: 80,
          },
        }}
        extra={
          <Space>
            <Button onClick={onClose} data-testid="cancel-component-button">
              Cancel
            </Button>
            <Button
              onClick={form.submit}
              type="primary"
              loading={loading}
              data-testid="submit-component-button"
              disabled={isUploading}
            >
              {props.type === "create" ? "Submit" : "Save"}
            </Button>
          </Space>
        }
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={props.type === "create" ? {} : { ...props.formData }}
          onFinish={onFormSubmit}
          autoComplete="off"
        >
          <Title level={5}>Link to Parent</Title>
          <Divider />
          <Row gutter={24}>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="productRefs" label="Product Reference">
                <AutoComplete
                  options={searchProduct.map((option) => ({
                    ...option,
                    label: (
                      <>
                        <div>{option?.name} </div>
                        <div> {option?.number} </div>
                      </>
                    ),
                  }))}
                  onSearch={handleProductSearch}
                  placeholder="Product Reference"
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="componentRefs" label="Component Reference">
                <AutoComplete
                  options={searchComponent.map((option) => ({
                    ...option,
                    label: (
                      <>
                        <div> {option?.manufacturer?.itemNumber} </div>
                        <div> {option?.manufacturer?.name} </div>
                      </>
                    ),
                  }))}
                  onSearch={handleComponentSearch}
                  placeholder="Component Reference"
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Title level={5}>Component/ Item Identifier</Title>
          <Divider />
          <Form.List name="manufacturer">
            {() => (
              <>
                <Row gutter={16}>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="name"
                      label="Manufacturer Name"
                      rules={[
                        {
                          required: true,
                          message: "Please input Manufacturer Name!",
                        },
                        {
                          pattern: /^([a-zA-Z0-9()&.{}[\],"'-]+\s?)*$/,
                          message: "Not allowed!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} sm={12} md={12}>
                    <Form.Item
                      name="itemNumber"
                      label="Manufacturer Item Number"
                      rules={[
                        {
                          required: true,
                          message: "Please input Manufacturer Item Number!",
                        },
                        {
                          pattern: /^([a-zA-Z0-9-/,(.)_*])+$/,
                          message:
                            'AlphaNumeric Values with Special Characters like "- / , ( ) . _ * " allowed without space',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form.List>
          <Row gutter={16}>
            <Col span={12} sm={12} md={12}>
              <Form.Item
                name="internalItemNumber"
                label="Internal Item Number"
                rules={[
                  {
                    required: true,
                    message: "Please input Internal item number!",
                  },
                  {
                    pattern: /^([a-zA-Z0-9-/])+$/,
                    message:
                      'AlphaNumeric Values with Special Characters like "- /" allowed without space',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} sm={12} md={12}>
              <Form.Item name="outOfScope" label="Out of Scope">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select out of scope values"
                  options={options}
                />
              </Form.Item>
            </Col>
          </Row>
          {parseUser.specifications && parseUser.specifications?.length > 0 && (
            <>
              <Title level={5}>Component/ Item Specification</Title>
              <Divider />
              <Row gutter={24}>
                {parseUser.specifications?.map((specification: any) => (
                  <Col key={specification.value} span={12} sm={12} md={12}>
                    <Form.Item
                      name={["specification", specification.value]}
                      label={specification.name}
                    >
                      {specification.dataType === "Textfield" && <Input />}
                      {specification.dataType === "Dropdown" && (
                        <Select
                          allowClear
                          placeholder={`Select ${specification.name}`}
                          options={specification.dataValue
                            .split(",")
                            .map((value: string) => ({ label: value, value }))}
                        />
                      )}
                      {specification.dataType === "Fileinput" && (
                        <FileUploader
                          postUrl="products/components/document/upload"
                          tenantNumber={tenantNumber}
                          setFormField={(value: string) =>
                            form.setFieldValue(
                              ["specification", specification.value],
                              value
                            )
                          }
                          formField={""}
                          onUploadStatusChange={setIsUploading}
                        />
                      )}
                      {specification.dataType === "Dateinput" && <DatePicker />}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Form>
      </Drawer>
    </div>
  );
};

export default ComponentForm;