import React, { useEffect, useState } from "react";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Space, Typography, Table, Button, Popconfirm } from "antd";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";

import { Regulation } from "../../../../utils/types/regulation";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  deleteTenantsRegulation,
  listTenantRegulations,
} from "../../../../redux/actions/tenantsAction";

import RegulationForm from "../../../../components/forms/RegulationForm";
import DeclarationTemplateForm from "../../../../components/forms/DeclarationTemplateForm";

const { Text } = Typography;

const TenantRegulations: React.FC<{
  id: string;
  tenantNumber: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [regulations, setRegulations] = useState<Regulation[]>([]);
  const [regulationCount, setRegulationCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Regulation>>({});
  const [tableFilters, setTableFilters] =
    useState<Record<string, FilterValue | null>>();
  const [search, setSearch] = useState<any>();

  const deleteRegulation = (value: any) => {
    console.log(value);
    dispatch(
      deleteTenantsRegulation(value._id, { tenantNumber: props.tenantNumber })
    )
      .then(() => {
        fetchData();
      })
      .catch((e) => console.log(e));
  };

  const cancel = () => {
    fetchData();
  };

  const columns: ColumnsType<Regulation> = [
    {
      title: "Action",
      key: "_id",
      width: 150,
      render: (_, record: Regulation) => (
        <Space>
          <RegulationForm
            type="update"
            fetchData={fetchData}
            id={props.id}
            formData={record}
          />
          <DeclarationTemplateForm
            type="update"
            fetchData={fetchData}
            id={props.id}
            formData={record}
          />
          <Popconfirm
            title="Delete Regulation"
            description={`Are you sure want to delete this regulation ${record.name} ?`}
            onConfirm={() => deleteRegulation(record)}
            onCancel={cancel}
            okText="Ok"
            cancelText="Cancel"
          >
            <Button type="text" icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text: string) => (
        <Text style={{ width: 200 }} ellipsis={{ tooltip: text }}>
          {text}
        </Text>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Exemption",
      dataIndex: "hasExemptions",
      key: "hasExemptions",
      render: (text: boolean) => (text ? <CheckOutlined /> : <CloseOutlined />),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text: string, record: any) => (
        <Text>
          {new Date(text).toLocaleString()}
          <br />
          {record.createdBy}
        </Text>
      ),
    },
  ];

  const fetchData = (payload?: any) => {
    setLoading(true);
    dispatch(
      listTenantRegulations(props.id, {
        pageSize: tablePagination.pageSize,
        pageNum: tablePagination.current,
        sortBy: tableSorter.column ? tableSorter.field : ["createdAt"],
        sortDesc: tableSorter.order === "descend" ? false : true,
        filters: payload ? payload : null,
      })
    )
      .then((response) => {
        setRegulations(response.regulations);
        setRegulationCount(response.regulationCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Regulation>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Regulation> | SorterResult<Regulation>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Regulation>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      setRegulations([]);
    }
  };

  useEffect(() => {
    fetchData(JSON.stringify(search));
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    JSON.stringify(search),
  ]);

  return (
    <>
      <Table
        loading={loading}
        dataSource={regulations}
        scroll={{
          x: 1000,
          y: "calc(100vh - 285px)",
        }}
        columns={columns}
        rowKey="_id"
        pagination={{
          ...tablePagination,
          total: regulationCount,
          showQuickJumper: true,
          showSizeChanger: true,
          showTotal: (totalCount) => `Total  ${totalCount}  items`,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TenantRegulations;
