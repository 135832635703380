import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
  Form,
  Input,
  Alert,
} from "antd";
import {
  readDownloadExport,
  readDownloadStatus,
  readDownloadResult,
  updateScip,
} from "../../../redux/actions/scipAction";
import { useAppDispatch } from "../../../redux/hooks";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const { Text, Paragraph } = Typography;

interface ExportStatus {
  [id: number]: "PENDING" | "IN_PROGRESS" | "DOWNLOADED" | "FAILED";
}

const ScipNotification: React.FC<{ formData: any; fetchData: any }> = (
  props
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [download, setDownload] = useState(false);
  const [exportStatus, setExportStatus] = useState<ExportStatus>({});
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleVisit = () => {
    window.open("https://ecs.echa.europa.eu/cloud/home.html", "_blank");
  };

  const onFinish = (values: any) => {
    dispatch(
      updateScip({
        ...values,
      })
    ).then(() => {
      setIsModalOpen(false);
      setDownload(false);
      setExportStatus({});
      props.fetchData();
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDownload(false);
    setExportStatus({});
  };

  const handleDownload = async () => {
    for (const id of props.formData) {
      try {
        setExportStatus((prev) => ({
          ...prev,
          [id.DossierUuid]: "IN_PROGRESS",
        }));
        const exportResponse = await dispatch(
          readDownloadExport({ uuid: id.DossierUuid })
        );
        let downloadSucceeded = false;
        while (!downloadSucceeded) {
          const downloadStatusResponse = await dispatch(
            readDownloadStatus({
              uri: exportResponse.uri,
            })
          );

          if (downloadStatusResponse.status === "SUCCEEDED") {
            downloadSucceeded = true;
            const downloadResultResponse = await dispatch(
              readDownloadResult({
                uri: downloadStatusResponse.id,
              })
            );
            const a = document.createElement("a");
            document.body.appendChild(a);
            const blob = new Blob([downloadResultResponse], {
                type: "application/octet-stream",
              }),
              url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = id.DossierUuid + ".i6z";
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            setExportStatus((prev) => ({
              ...prev,
              [id.DossierUuid]: "DOWNLOADED",
            }));
          } else {
            await new Promise((resolve) => setTimeout(resolve, 2000));
          }
        }
      } catch (error) {
        console.error("Error processing export for ID:", id.DossierUuid, error);
        setExportStatus((prev) => ({ ...prev, [id.DossierUuid]: "FAILED" }));
      }
    }
    setDownload(true);
  };

  return (
    <>
      <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
        Submit to SCIP
      </Button>
      <Modal
        title=""
        open={isModalOpen}
        maskClosable={false}
        closable={false}
        centered
        width={500}
        footer={[
          download && (
            <>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" onClick={form.submit}>
                Save
              </Button>
            </>
          ),
        ]}
      >
        <Card
          bordered={false}
          style={{ overflow: "auto", height: "30vh" }}
          styles={{
            body: {
              padding: 0,
            },
          }}
        >
          {!download && (
            <Alert
              message="Click Export to start downloading the files"
              showIcon
              type="info"
              action={
                <Button size="small" type="primary" onClick={handleDownload}>
                  Export
                </Button>
              }
            />
          )}
          {!download ? (
            props.formData.map((e: any, i: any) => (
              <>
                <Card
                  key={i}
                  style={{
                    marginTop: "1rem",
                  }}
                  styles={{
                    body: {
                      padding: 5,
                    },
                  }}
                >
                  {/* <Col key={i}> */}
                  <Space
                    align="end"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text strong>{e.name} : </Text>
                    <Space>
                      {exportStatus[e.DossierUuid] === "IN_PROGRESS" && (
                        <Spin
                          size="small"
                          percent={
                            exportStatus[e.DossierUuid] === "DOWNLOADED"
                              ? 100
                              : 0
                          }
                          indicator={<LoadingOutlined spin />}
                        />
                      )}
                      <Text type="secondary">
                        {exportStatus[e.DossierUuid] || "PENDING"}
                      </Text>
                    </Space>
                  </Space>
                  {/* </Col> */}
                </Card>
              </>
            ))
          ) : (
            <>
              <Alert
                message="Go to ECHA Website"
                showIcon
                type="info"
                action={
                  <Button size="small" type="primary" onClick={handleVisit}>
                    ECHA
                  </Button>
                }
              />
              <Form
                form={form}
                // labelCol={{ span: 6 }}
                // wrapperCol={{ span: 16 }}
                style={{ maxWidth: 400, marginTop: "1rem" }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Row gutter={16}>
                  {props.formData.map((e: any) => (
                    <>
                      <Col key={`${e.DossierUuid}.${e.name}`} sm={24} md={24}>
                        <Form.Item label={e.name} name={[e.DossierUuid]}>
                          <Input placeholder="SCIP Number" />
                        </Form.Item>
                      </Col>
                    </>
                  ))}
                </Row>
              </Form>
            </>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default ScipNotification;
