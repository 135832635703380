import React, { useState, useEffect } from "react";
import { Modal, Select, Button } from "antd";
import { useAppDispatch } from "../../redux/hooks";
import { listCampaignsList } from "../../redux/actions/CampaignManager/listsAction";
import { updateLists } from "../../redux/actions/CampaignManager/listsAction"; // Make sure to import updateLists

interface AddToCampaignListModalProps {
  selectedItems: any[];
  onCancel: () => void;
  type: string;
  fetchData?: () => void;
}

const AddToCampaignListModal: React.FC<AddToCampaignListModalProps> = ({
  selectedItems,
  onCancel,
  type,
  fetchData,
}) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<string>();
  const [listOptions, setListOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const readlists = (search = "") => {
    setLoading(true);
    dispatch(
      listCampaignsList({
        limit: 10,
        page: 1,
        sortBy: ["createdAt"],
        sortDesc: false,
        softDelete: false,
        search: search,
      })
    ).then((response: any) => {
      setListOptions(
        response.lists?.map((e: any) => ({
          value: e.name,
          label: (
            <>
              <div>{e.name}</div>
            </>
          ),
          ...e,
        }))
      );
      setLoading(false);
    });
  };

  const handleSubmit = () => {
    setSaveLoading(true);
    const campaign = listOptions.find((opt) => opt.value === selectedCampaign);
    let formData = {};

    if (type === "productAdd") {
      formData = {
        id: [campaign?._id],
        updates: {
          components: selectedItems.map((item) => item._id),
          type: type,
          name: campaign?.name,
        },
      };
    } else if (type === "componentsAdd") {
      formData = {
        id: [campaign?._id],
        updates: {
          suppliers: selectedItems
            .map((item) => item.manufacturer?.name)
            .filter(Boolean),
          components: selectedItems.map((item) => item._id),
          type: type,
          name: campaign?.name,
        },
      };
    } else if (type === "suppliersAdd") {
      formData = {
        id: [campaign?._id],
        updates: {
          suppliers: selectedItems.map((item) => item.name),
          type: type,
          name: campaign?.name,
        },
      };
    }

    dispatch(
      updateLists({
        ...formData,
      })
    )
      .then(() => {
        handleCancel();
        fetchData && fetchData();
        setSaveLoading(false);
      })
      .catch((error) => {
        setSaveLoading(false);
      });
  };

  const handleSearch = (value: string) => {
    readlists(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedCampaign(undefined);
    onCancel();
  };

  useEffect(() => {
    if (isModalOpen) {
      readlists();
    }
  }, [isModalOpen]);

  if (!selectedItems?.length) {
    return null;
  }

  return (
    <>
      <Button
        onClick={showModal}
        style={{
          backgroundColor: "#f39237",
          color: "white",
          border: "none",
        }}
        className="campaign-btn"
      >
        Add To Campaign List
      </Button>

      <Modal
        title="Add products to campaign list"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        centered
      >
        <div style={{ marginBottom: "24px" }}>
          <Select
            placeholder="Select a campaign"
            style={{ width: "100%" }}
            onChange={(value) => setSelectedCampaign(value)}
            options={listOptions}
            loading={loading}
            showSearch
            onSearch={handleSearch}
            filterOption={false}
          />
        </div>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
        >
          <Button onClick={handleCancel}>Cancel</Button>
          <Button
            type="primary"
            onClick={handleSubmit}
            disabled={!selectedCampaign}
            loading={saveLoading}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddToCampaignListModal;
