import { publicPostApi } from "../../utils/apiHelper";

export const generateDeclaration = (payload: any) => () => {
  return new Promise<any>((resolve, reject) => {
    publicPostApi("/public/ipceditor/generatedeclaration", payload)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

