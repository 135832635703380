import React, { useEffect, useState } from "react";
import { Table, Space, Button, Tag, Typography, Tooltip } from "antd";
import {
  PlayCircleFilled,
} from "@ant-design/icons";
import type {
  TableProps,
  ColumnsType,
  TablePaginationConfig,
} from "antd/es/table";
import type {
  FilterValue,
  SorterResult,
  TableRowSelection,
} from "antd/es/table/interface";
import { useParams } from "react-router-dom";

import { Component } from "../../../utils/types/component";
import { useAppDispatch } from "../../../redux/hooks";
import {
  fetchComponents,
  fetchRegulation,
} from "../../../redux/actions/supplierPortalAction";
import ReadSupportingDocument from "../../../components/modals/ReadSupportingDocuments";
import FileUploader from "../../../components/layouts/FileUploader";
import MaterialForm from "../../../components/forms/MaterialForm";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title, Text } = Typography;

const CampaignMaterialDisclosure: React.FC = () => {
  const { uniqueName, linkCampaignId, linkTenantNumber, regulationNumber } =
    useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [regulation, setRegulation] = useState<any>(null);
  const [components, setComponents] = useState<any>([]);
  const [componentCount, setComponentCount] = useState<number>(0);
  const [tablePagination, setTablePagination] = useState<TablePaginationConfig>(
    {
      current: 1,
      pageSize: 10,
    }
  );
  const [tableSorter, setTableSorter] = useState<SorterResult<Component>>({});
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedComponents, setSelectedComponents] = useState<Component[]>([]);
  const [search, setSearch] = useState<any>();

  // FMD TOUR GUIDE
  const [show, setShow] = useState<boolean>(false);

  const fetchData = (search?: any) => {
    setLoading(true);
    dispatch(
      fetchComponents({
        name: uniqueName,
        regulationNumber: regulationNumber,
        campaignId: window.atob(linkCampaignId!),
        tenantNumber: window.atob(linkTenantNumber!),
        page: tablePagination.current,
        limit: tablePagination.pageSize,
      })
    )
      .then((response) => {
        setComponents(response.components);
        setComponentCount(response.componentsCount);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange: TableProps<Component>["onChange"] = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Component> | SorterResult<Component>[]
  ) => {
    setTablePagination(pagination);
    setTableFilters(filters);
    setTableSorter(sorter as SorterResult<Component>);

    if (pagination.pageSize !== tablePagination.pageSize) {
      // setComponents([]);
    }
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRows: Component[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedComponents(selectedRows);
  };
  const rowSelection: TableRowSelection<Component> = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  useEffect(() => {
    fetchData(JSON.stringify(search));
  }, [
    JSON.stringify(tablePagination),
    JSON.stringify(tableFilters),
    JSON.stringify(tableSorter),
    JSON.stringify(search),
  ]);

  useEffect(() => {
    dispatch(
      fetchRegulation({
        regulationNumber: regulationNumber,
        tenantNumber: window.atob(linkTenantNumber!),
      })
    ).then((response) => {
      setRegulation(response);
    });
    fetchData();
  }, [regulationNumber]);

  return (
    <>
      <ErrorBoundary>
      <Space
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1.5rem",
            borderBottom: "1px solid rgba(176, 176, 176, 0.45)",
            paddingBottom: "1rem"
          }}
        >
          <Button
            className="secondary-btn"
            icon={<PlayCircleFilled />}
            onClick={() => {
              setShow(true);
            }}
          >
            Guided Tutorial
          </Button>
        </Space>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Title level={5} style={{ marginBottom: 0 }}>
            Material Disclosure
          </Title>
          {selectedRowKeys.length ? "Bulk Update" : null}
        </Space>

        <Table
          loading={loading}
          dataSource={components}
          scroll={{
            x: 1000,
            y: "calc(100vh - 285px)",
          }}
          columns={[
            {
              title: "Action",
              key: "operation",
              width: 150,
              render: (_, record) => (
                <Space>
                  <MaterialForm
                    type="update"
                    fetchData={fetchData}
                    formData={record}
                    campaignId={window.atob(linkCampaignId!)}
                    showTourGuide={record._id === components[0]._id}
                    inSupplierPortal = {true}
                    show={show}
                    setShow={setShow}
                  />
                  {/* <FileUploader
                  postUrl="public/suppliers/document/upload"
                  tenantNumber={window.atob(linkTenantNumber!)}
                  additionalData={{
                    campaignId: window.atob(linkCampaignId!),
                    foldername: "campaign",
                    subfolder: "materials",
                  }}
                  setFormField={(value: string) =>
                    // call api to update the component
                    console.log(value)
                  }
                  type="icon"
                  formField={""}
                /> */}
                </Space>
              ),
            },
            {
              title: "Internal #",
              dataIndex: "internalItemNumber",
              key: "internalItemNumber",
              sorter: true,
              width: 150,
            },
            {
              title: "Manufacturer",
              dataIndex: "manufacturer",
              key: "manufacturer",
              sorter: true,
              width: 200,
              render: (manufacturer: any) => (
                <Text>
                  {manufacturer.itemNumber}
                  <br></br>
                  <small>{manufacturer.name}</small>
                </Text>
              ),
            },
            {
              title: "FMD Document",
              dataIndex: "fmdDocument",
              key: "fmdDocument",
              render: (text: string) => {
                return text ? (
                  <ReadSupportingDocument
                    postUrl="public/suppliers/document/download"
                    filename={text}
                    foldername="campaign"
                    subfolder="materials"
                    campaignId={window.atob(linkCampaignId!)}
                  />
                ) : null;
              },
            },
            {
              title: "Weight",
              dataIndex: "partWeight",
              key: "partWeight",
              width: 150,
              render: (text: string, record: any) => (
                <Text>
                  {text} <small>{record.partWeightUnit}</small>
                </Text>
              ),
            },
            {
              title: "Status",
              key: "status",
              dataIndex: "status",
              render: (text: any) =>
                text && (
                  <Tag
                    color={
                      text === ""
                        ? "blue"
                        : text === "pending"
                        ? "orange"
                        : "green"
                    }
                  >
                    {text === "" ? "INITIATED" : text.toUpperCase()}
                  </Tag>
                ),
            },
          ]}
          // rowSelection={rowSelection}
          rowKey="_id"
          pagination={{
            ...tablePagination,
            total: componentCount,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (totalCount) => `Total  ${totalCount}  items`,
          }}
          onChange={handleTableChange}
        />
      </ErrorBoundary>
    </>
  );
};

export default CampaignMaterialDisclosure;