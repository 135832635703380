import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import type { UploadProps } from "antd";

import { baseUrl } from "../../../utils/apiHelper";
import { getLocalStorage } from "../../../utils/localStore";

const FileUploader: React.FC<{
  postUrl: string;
  tenantNumber: string;
  setFormField?: any;
  maxCount?: number;
  disabled?: boolean;
  formField?: string;
  additionalData?: any;
  type?: string;
  fileValidations?: {
    acceptedTypes?: string[]; // Accepted file types, e.g., ["text/csv"]
    maxSizeMB: number; // Maximum file size in MB, e.g., 25
  };
  importType?: string;
  onUploadStatusChange?: (isUploading: boolean) => void;
}> = (props: any) => {
  const uploadProps: UploadProps = {
    name: "file",
    accept:
      props.importType === "xml"
        ? ".xml"
        : props.importType === "csv"
        ? ".csv"
        : "",
    maxCount: props.maxCount ? props.maxCount : 1,
    multiple: props.maxCount ? true : false,
    action: baseUrl + "/" + props.postUrl,
    headers: {
      Authorization: `Bearer ${getLocalStorage("accessToken")}`,
    },
    defaultFileList: props.formField
      ? [
          {
            uid: props.formField,
            name: props.formField,
          },
        ]
      : [],
    data: { tenantNumber: props.tenantNumber, ...props.additionalData },

    beforeUpload: (file: any) => {
      const { acceptedTypes, maxSizeMB } = props.fileValidations || {};
      const isAcceptedType = acceptedTypes
        ? acceptedTypes.includes(file.type)
        : true;
      const isSizeAccepted = maxSizeMB
        ? file.size / 1024 / 1024 < maxSizeMB
        : true;

      if (!isAcceptedType) {
        message.error(`You can only upload files of type: text/csv`);
      }

      if (!isSizeAccepted) {
        message.error(`File size must be less than ${maxSizeMB}MB!`);
      }

      return isAcceptedType && isSizeAccepted;
    },
    onChange(info: any) {
      if (info.file.status === "uploading") {
        props.onUploadStatusChange && props.onUploadStatusChange(true);
      } else {
        props.onUploadStatusChange && props.onUploadStatusChange(false);
      }
      if (info.file.status === "done") {
        console.log(info.file.response.data.filename);
        props.setFormField(info.file.response.data.filename);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload {...uploadProps}>
      <Button
        type={props.type === "icon" ? "text" : "default"}
        icon={<UploadOutlined />}
        disabled={props.disabled}
      >
        {props.type === "icon"
          ? null
          : "Click to Upload " +
            (props.maxCount ? "(Max. " + props.maxCount + ")" : "")}
      </Button>
    </Upload>
  );
};

export default FileUploader;
