import React, { useContext, useEffect } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { ProductRefContext } from "../index";

interface OutletContextType {
  search: string;
}

const ScipProducts: React.FC = () => {
  const { ref } = useContext(ProductRefContext);
  const { search } = useOutletContext<OutletContextType>() || {};

  useEffect(() => {
    ref;
  }, [ref]);

  return (
    <div>
      <Outlet context={{ search, ref }} />
    </div>
  );
};

export default ScipProducts;
