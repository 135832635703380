import React, { useEffect, useState, useRef } from "react";
import {
  UploadOutlined,
  FileExcelOutlined,
  PlayCircleFilled,
} from "@ant-design/icons";
import { Space, Typography, Button, Card } from "antd";

import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks";
import {
  fetchConflictMinerals,
  fetchConflictMineralsDocumentRead,
} from "../../../redux/actions/supplierPortalAction";
import ImportCMRT from "../../../components/modals/import-cmrt-modal";
import axios from "axios";
import { baseUrl } from "../../../utils/apiHelper";
import ErrorBoundary from "../../../utils/errorBoundary";

const { Title, Text, Paragraph } = Typography;

const CampaignConflictMinerals: React.FC = () => {
  const { uniqueName, linkCampaignId, linkTenantNumber, regulationNumber } =
    useParams();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [conflictMinerals, setConflictMinerals] = useState<any>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const importRef = useRef(null);

  const fetchData = (search?: any) => {
    setLoadingImport(true);
    dispatch(
      fetchConflictMinerals({
        supplierName: uniqueName,
        tenantNumber: window.atob(linkTenantNumber!),
        sendgridCampaignId: window.atob(linkCampaignId!),
      })
    )
      .then((response) => {
        if (Object.keys(response).length) {
          setConflictMinerals(response);
        }
        setLoadingImport(false);
      })
      .catch(() => {
        setLoadingImport(false);
      });
  };

  const onDownloadFile = async () => {
    if (conflictMinerals.filename.includes("xlsx")) {
      setLoading(true);
      try {
        const response = await axios.post(
          baseUrl + "/public/suppliers/document/download",
          {
            filename: conflictMinerals.filename,
            foldername: "conflict-minerals",
            tenantNumber: window.atob(linkTenantNumber!),
            sendgridCampaignId: window.atob(linkCampaignId!),
          },
          {
            responseType: "arraybuffer",
          }
        );

        if (response && response.status === 200 && response.data) {
          const blobPDF = new Blob([response.data], {
            type: "application/vnd.ms-excel",
          });
          const blobURL = window.URL.createObjectURL(blobPDF);
          const fileLink = document.createElement("a");
          fileLink.href = blobURL;
          fileLink.download = conflictMinerals.filename;
          fileLink.click();
          setLoading(false);
        } else {
          console.error("No response data received.");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching file data:", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [regulationNumber]);

  return (
    <>
      <ErrorBoundary>
        <Space
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "1.5rem",
            borderBottom: "1px solid rgba(176, 176, 176, 0.45)",
            paddingBottom: "1rem"
          }}
        >
          <Button
            className="secondary-btn"
            icon={<PlayCircleFilled />}
            onClick={() => {
              setShow(true);
            }}
          >
            Guided Tutorial
          </Button>
        </Space>
        <Space
          align="end"
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Title level={5} style={{ marginBottom: 0 }}>
            Conflict Minerals
          </Title>
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={() => setModalVisible(true)}
            loading={loadingImport}
            ref={importRef}
          >
            Import CMRT
          </Button>
        </Space>
        <div style={{ marginBottom: "1rem" }}>
          <Text type="secondary">
            Please upload the latest version of complete CMRT file.
          </Text>
        </div>

        {conflictMinerals && (
          <Card
            title={conflictMinerals.company.name}
            extra={[
              <Button
                key="1"
                type="text"
                icon={<FileExcelOutlined />}
                onClick={() => onDownloadFile()}
                loading={loading}
                style={{ color: "white" }}
              >
                Download {conflictMinerals.rawFileName}
              </Button>,
            ]}
          >
            <Paragraph>
              <strong>Authorizer : </strong>{" "}
              {conflictMinerals.company.authorizer.name} (
              {conflictMinerals.company.authorizer.emailAddress})
            </Paragraph>
            <Paragraph>
              <strong>Address : </strong> {conflictMinerals.company.address}
            </Paragraph>
            <Paragraph>
              <strong>Declaration Scope : </strong>{" "}
              {conflictMinerals.company.declarationScope}
            </Paragraph>
            <Paragraph>
              <strong>Effective Date : </strong>{" "}
              {new Date(
                conflictMinerals.company.authorizer.effectiveDate
              ).toLocaleString()}
            </Paragraph>
            <Paragraph>
              <strong>Submission Date : </strong>{" "}
              {new Date(conflictMinerals.updatedAt).toLocaleString()}
            </Paragraph>
          </Card>
        )}

        <ImportCMRT
          showModal={() => setModalVisible(true)}
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
          supplier={uniqueName ?? ""}
          importButtonRef={importRef}
          show={show}
          setShow={setShow}
        />
      </ErrorBoundary>
    </>
  );
};

export default CampaignConflictMinerals;
