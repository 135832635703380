import React, { useState } from "react";
import { Upload, Button, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { RcFile, UploadFile } from "antd/es/upload/interface";
import axios from "axios";
import { baseUrl } from "../../utils/apiHelper";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const FileUpload: React.FC = () => {
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleUpload = async (file: File) => {
    const uploadingMessage = message.loading(`Importing ${file.name}`, 0);
    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    try {
      const response = await axios.post(
        baseUrl + "/public/ipceditor/xmlupload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response && response.status === 200 && response.data) {
        if (
          response.data?.responseAPI === null ||
          response.data?.responseAPI === undefined
        ) {
          message.error("File import failed");
        } else {
          response.data.responseAPI.supplierInformation.responseDate = response
            .data?.responseAPI?.supplierInformation?.responseDate
            ? dayjs(
                response.data?.responseAPI?.supplierInformation?.responseDate
              )
            : null;
          if (response.data?.responseAPI?.formType === "Request/Reply") {
            response.data.responseAPI.requesterInformation.responseDate =
              response.data?.responseAPI?.requesterInformation?.responseDate
                ? dayjs(
                    response.data?.responseAPI?.requesterInformation
                      ?.responseDate
                  )
                : null;
            response.data.responseAPI.requesterInformation.respondByDate =
              response.data?.responseAPI?.requesterInformation?.respondByDate
                ? dayjs(
                    response.data?.responseAPI?.requesterInformation
                      ?.respondByDate
                  )
                : null;
          }
          response.data.responseAPI.supplierInformation.effectiveDate = response
            .data?.responseAPI?.supplierInformation?.effectiveDate
            ? dayjs(
                response.data?.responseAPI?.supplierInformation?.effectiveDate
              )
            : null;
          navigate("declaration", {
            state: response.data?.responseAPI,
          });
          uploadingMessage();
          message.success("File imported successfully");
        }
      }
      uploadingMessage();
      setLoading(false);
    } catch (error) {
      uploadingMessage();
      message.error("File import failed");
      console.error(error);
      setLoading(false);
    }

    return false;
  };

  const props = {
    beforeUpload: (file: File) => {
      handleUpload(file);
      return false;
    },
    fileList,
    onChange: (info: { fileList: UploadFile[] }) => {
      setFileList(info.fileList);
    },
  };

  return (
    <Upload {...props} maxCount={1} showUploadList={false}>
      <Button
        style={{
          backgroundColor: "#F7863E",
          color: "white",
          borderRadius: 0,
        }}
        size="large"
        loading={loading}
      >
        <UploadOutlined style={{ color: "white" }} />
        Upload XML Declaration
      </Button>
    </Upload>
  );
};

export default FileUpload;
