import React, { useEffect, useState } from "react";
import { FileTextOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  Switch,
} from "antd";
import { Editor } from "@tinymce/tinymce-react";

import { Regulation } from "../../utils/types/regulation";
import { useAppDispatch } from "../../redux/hooks";
import {
  createRegulation,
  updateRegulation,
} from "../../redux/actions/regulationsAction";
import { updateTenantRegulations } from "../../redux/actions/tenantsAction";
import { getLocalStorage } from "../../utils/localStore";

const DeclarationTemplateForm: React.FC<{
  type: string;
  fetchData: any;
  id?: string;
  formData?: Regulation;
}> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [hideAuthorizer, setHideAuthorizer] = useState(false);
  const [hideDeclaration, setHideDeclaration] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const permissions = getLocalStorage("role")
    ? getLocalStorage("role").permissions
    : null;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleEditorChange = (content: any, editor: any, formField: string) => {
    const values = form.getFieldValue("certification");
    values[formField] = content;
    form.setFieldValue("certification", values);
  };

  const onFormSubmit = (values: Regulation) => {
    setLoading(true);
    if (props.type === "create") {
      dispatch(createRegulation(values))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.id && props.formData) {
      dispatch(
        updateTenantRegulations(props.id, {
          currentLocationOrigin: window.location.origin,
          updates: {
            number: props.formData.regulationNumber,
            regulationUpdates: values,
          },
        })
      )
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    } else if (props.formData) {
      dispatch(updateRegulation({ updates: values }, props.formData._id))
        .then(() => {
          props.fetchData();
          form.resetFields();
          setLoading(false);
          setOpen(false);
        })
        .catch(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (props.formData) {
      setHideAuthorizer(
        props.formData.certification.signature ||
          props.formData.certification.authorisedBy ||
          props.formData.certification.designation
          ? true
          : false
      );
      setHideDeclaration(
        props.formData.certification.yesDescription ||
          props.formData.certification.noDescription
          ? true
          : false
      );
    }
  }, [props.formData]);

  return (
    <>
      {permissions[props.type === "create" ? "create" : "update"]?.includes(
        "regulations"
      ) && (
        <>
          <Button
            type={props.type === "create" ? "primary" : "text"}
            title={
              props.type === "create" ? "N/A" : "Edit Declaration Template"
            }
            onClick={showDrawer}
            icon={
              props.type === "create" ? (
                <FileTextOutlined />
              ) : (
                <FileTextOutlined />
              )
            }
          >
            {props.type === "create" ? "N/A" : null}
          </Button>
          <Drawer
            title={
              props.type === "create" ? "N/A" : "Edit Declaration Template"
            }
            width={720}
            onClose={onClose}
            open={open}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
            extra={
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={form.submit} type="primary" loading={loading}>
                  {props.type === "create" ? "Submit" : "Save"}
                </Button>
              </Space>
            }
          >
            <Form
              form={form}
              layout="vertical"
              initialValues={
                props.type === "create" ? {} : { ...props.formData }
              }
              onFinish={onFormSubmit}
            >
              <Form.List name="certification">
                {() => (
                  <>
                    <Row gutter={16}>
                      <Col span={24}>
                        <Form.Item
                          name="title"
                          label="Title"
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please enter declaration title",
                            },
                          ]}
                        >
                          <Input placeholder="Please enter declaration title" />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="commonDescription"
                          label="Common Declaration"
                          rules={[]}
                          hidden={true}
                        >
                          <Input.TextArea />
                        </Form.Item>
                        <label
                          style={{ display: "block", marginBottom: "0.5rem" }}
                        >
                          Common Declaration
                        </label>
                        <Editor
                          apiKey="eph2salzmox3uyanm02ujyd63j89lg4hp5o450729hkt5xf1"
                          initialValue={
                            props.type === "create"
                              ? ""
                              : props.formData?.certification.commonDescription
                          }
                          init={{
                            height: 300,
                            menubar: false,
                            convert_urls: true,
                            relative_urls: false,
                            remove_script_host: false,
                            plugins:
                              "advlist autolink lists link image charmap preview insertdatetime quickbars",
                            toolbar1:
                              // eslint-disable-next-line no-multi-str
                              "bold italic underline | \
              alignleft aligncenter alignright alignjustify | \
              bullist numlist outdent indent | link removeformat | preview",
                            quickbars_insert_toolbar:
                              "quickimage quicktable charmap hr insertdatetime",
                            quickbars_selection_toolbar:
                              "bold italic underline | h2 h3 h4 | alignleft aligncenter alignright | quicklink removeformat",
                          }}
                          onEditorChange={(content: any, editor: any) =>
                            handleEditorChange(
                              content,
                              editor,
                              "commonDescription"
                            )
                          }
                        />
                      </Col>
                    </Row>
                    <Divider plain orientation="left">
                      Seperate compliant and non-compliant declaration{" "}
                      <Switch
                        defaultValue={hideDeclaration}
                        size="small"
                        onChange={() => setHideDeclaration(!hideDeclaration)}
                      />
                    </Divider>
                    {hideDeclaration && (
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            name="yesDescription"
                            label="Compliant Declaration"
                            rules={[]}
                            hidden={true}
                          >
                            <Input.TextArea />
                          </Form.Item>
                          <label
                            style={{ display: "block", marginBottom: "0.5rem" }}
                          >
                            Compliant Declaration
                          </label>
                          <Editor
                            apiKey="eph2salzmox3uyanm02ujyd63j89lg4hp5o450729hkt5xf1"
                            initialValue={
                              props.type === "create"
                                ? ""
                                : props.formData?.certification.yesDescription
                            }
                            init={{
                              height: 300,
                              menubar: false,
                              convert_urls: true,
                              relative_urls: false,
                              remove_script_host: false,
                              plugins:
                                "advlist autolink lists link image charmap preview insertdatetime quickbars",
                              toolbar1:
                                // eslint-disable-next-line no-multi-str
                                "bold italic underline | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | link removeformat | preview",
                              quickbars_insert_toolbar:
                                "quickimage quicktable charmap hr insertdatetime",
                              quickbars_selection_toolbar:
                                "bold italic underline | h2 h3 h4 | alignleft aligncenter alignright | quicklink removeformat",
                            }}
                            onEditorChange={(content: any, editor: any) =>
                              handleEditorChange(
                                content,
                                editor,
                                "yesDescription"
                              )
                            }
                          />
                        </Col>
                        <Col span={24}>
                          <Form.Item
                            name="noDescription"
                            label="Non-Compliant Declaration"
                            rules={[]}
                            hidden={true}
                          >
                            <Input.TextArea />
                          </Form.Item>
                          <label
                            style={{
                              display: "block",
                              marginBottom: "0.5rem",
                              marginTop: "1rem",
                            }}
                          >
                            Non-Compliant Declaration
                          </label>
                          <Editor
                            apiKey="eph2salzmox3uyanm02ujyd63j89lg4hp5o450729hkt5xf1"
                            initialValue={
                              props.type === "create"
                                ? ""
                                : props.formData?.certification.noDescription
                            }
                            init={{
                              height: 300,
                              menubar: false,
                              convert_urls: true,
                              relative_urls: false,
                              remove_script_host: false,
                              plugins:
                                "advlist autolink lists link image charmap preview insertdatetime quickbars",
                              toolbar1:
                                // eslint-disable-next-line no-multi-str
                                "bold italic underline | \
                  alignleft aligncenter alignright alignjustify | \
                  bullist numlist outdent indent | link removeformat | preview",
                              quickbars_insert_toolbar:
                                "quickimage quicktable charmap hr insertdatetime",
                              quickbars_selection_toolbar:
                                "bold italic underline | h2 h3 h4 | alignleft aligncenter alignright | quicklink removeformat",
                            }}
                            onEditorChange={(content: any, editor: any) =>
                              handleEditorChange(
                                content,
                                editor,
                                "noDescription"
                              )
                            }
                          />
                        </Col>
                      </Row>
                    )}
                    <Divider plain orientation="left">
                      Customize the regulation declaration{" "}
                      <Switch
                        defaultValue={hideAuthorizer}
                        size="small"
                        onChange={() => setHideAuthorizer(!hideAuthorizer)}
                      />
                    </Divider>
                    {hideAuthorizer && (
                      <Row gutter={16}>
                        <Col span={4}>
                          <Form.Item name="signature" label="Signature">
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name="authorisedBy"
                            label="Authorised By"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please enter declaration title",
                              },
                            ]}
                          >
                            <Input placeholder="Please enter declaration title" />
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item
                            name="designation"
                            label="Designation"
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please enter company name",
                              },
                            ]}
                          >
                            <Input placeholder="Please enter company name" />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </>
                )}
              </Form.List>
            </Form>
          </Drawer>
        </>
      )}
    </>
  );
};

export default DeclarationTemplateForm;
