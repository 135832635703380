import React, { useState } from "react";
import { Card, Col, Row, Typography, Space } from "antd";
import { getLocalStorage } from "../../../utils/localStore";
import Mineral from "../../../assets/supplier portal/cmrt-supplier-portal-guide.png";
import ComplianceGuide from "../../../assets/supplier portal/compliance-guide.png";
import Technical from "../../../assets/supplier portal/technical-support-img.png";
import Communicate from "../../../assets/supplier portal/contact-support-img.png";
import FMD from "../../../assets/supplier portal/fmd-card-bg.png";
import InstructionModal from "./instructionModal";
import ContactSupport from "../../../components/forms/ContactSupport";
import { useParams } from "react-router-dom";
import TechnicalSupport from "../../../components/forms/TechnicalSupport";
import ErrorBoundary from "../../../utils/errorBoundary";
import Icon from "../../../Icon";

const { Title } = Typography;
const Support: React.FC = () => {
  const campaignData = getLocalStorage("campaignData");
  const { linkTenantNumber } = useParams();
  const [showTechnicalSupportModal, setShowTechnicalSupportModal] = useState(false);
  const [showContactSupportModal, setShowContactSupportModal] = useState(false);
  const [selected, setSelected] = useState("");

  return (
    <ErrorBoundary>
      {selected ?
        <InstructionModal selected={selected} setSelected={setSelected} campaignData={campaignData} /> :
        <Row gutter={[18, 18]}>
          <Col span={24}>
            <Title level={4}>Help & Support</Title>
          </Col>
          {campaignData?.regulations?.length && (
            <Col span={12}>
              <Card
                className="supplier-portal-card"
                style={{ background: `url(${ComplianceGuide})` }}
                onClick={()=>setSelected("regulations")}
              >
                <Space className="card-content">
                  <div>
                    <Title level={4}>Update Compliance Guide</Title>
                    <p>
                      How To Update the compliance status or Regulation
                      information ?
                    </p>
                  </div>
                  <Icon name="right-chevron" />
                </Space>
              </Card>
            </Col>
          )}
        {campaignData?.materials === "Yes" && (
            <Col span={12}>
              <Card
                className="supplier-portal-card"
                style={{ background: `url(${FMD})` }}
                onClick={()=>setSelected("materials")}
              >
                <Space className="card-content">
                  <div>
                    <Title level={4}>
                      Update Material Disclosure (FMD) Guide
                    </Title>
                    <p>
                      How To Update the Full Material Declaration in Supplier
                      Portal?
                    </p>
                  </div>
                  <Icon name="right-chevron" />
                </Space>
              </Card>
            </Col>
          )}
          {(campaignData?.conflict === "Yes" ||
            campaignData?.extended === "Yes") && (
            <Col span={12}>
              <Card
                className="supplier-portal-card"
                style={{ background: `url(${Mineral})` }}
                onClick={()=>setSelected("cmrt")}
              >
                <Space className="card-content">
                  <div>
                    <Title level={4}>
                      Update{" "}
                      {campaignData &&
                        campaignData.conflict === "Yes" &&
                        " Conflict Minerals (CMRT file) "}
                      {campaignData &&
                        campaignData.conflict === "Yes" &&
                        campaignData.extended === "Yes" &&
                        "and"}
                      {campaignData &&
                        campaignData.extended === "Yes" &&
                        " Extended Minerals (EMRT) "}
                      Guide
                    </Title>
                    <p>
                      How To Update
                      {campaignData &&
                        campaignData.conflict === "Yes" &&
                        " Conflict Minerals (CMRT file) "}
                      {campaignData &&
                        campaignData.conflict === "Yes" &&
                        campaignData.extended === "Yes" &&
                        "and"}
                      {campaignData &&
                        campaignData.extended === "Yes" &&
                        " Extended Minerals (EMRT) "}
                      in Supplier Portal{" "}
                    </p>
                  </div>
                  <Icon name="right-chevron" />
                </Space>
              </Card>
            </Col>
          )}
          <Col span={12}>
            <Card
              className="supplier-portal-card"
              style={{ background: `url(${Technical})` }}
              onClick={()=>setShowTechnicalSupportModal(!showTechnicalSupportModal)}
            >
              <Space className="card-content">
                <div>
                  <Title level={4}>Technical Support</Title>
                  <p>Facing issues related to technical ?</p>
                </div>
                <Icon name="right-chevron" />
              </Space>
            </Card>
            <TechnicalSupport showTechnicalSupportModal={showTechnicalSupportModal} setShowTechnicalSupportModal={setShowTechnicalSupportModal} />
          </Col>
          <Col span={12}>
            <Card
              className="supplier-portal-card"
              style={{ background: `url(${Communicate})` }}
              onClick={()=>setShowContactSupportModal(!showContactSupportModal)}

            >
              <Space className="card-content">
                <div>
                  <Title level={4}>Contact Support</Title>
                  <p>Cannot find what you are looking for ?</p>
                </div>
                <Icon name="right-chevron" />
              </Space>
            </Card>
            <ContactSupport
              tenantNumber={window.atob(linkTenantNumber!)}
              type="support"
              showContactSupportModal={showContactSupportModal} setShowContactSupportModal={setShowContactSupportModal}
            />
          </Col>
        </Row>
      }
    </ErrorBoundary>
  );
};

export default Support;
